import { Button, Spin, Input, Select, Tooltip } from 'antd';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import { memo, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { ADMIN_API_HOST } from '../../../../constants';
import { setupLeafletDefaults } from '../../../../utils/MapaUtils';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { BusinessTypes, EmployeeList } from '../../../admin/pages/Interfaces';

const { Option } = Select;
setupLeafletDefaults();

function getKategoriaByKod(kod) {
    const found = EmployeeList.find(item => item.kodPocetZamestnancov === kod);
    return found ? found.kategoria : 'Neznáma kategória';
}

function getPopisyKodu(kody) {
    const popisy = [];
    const nenajdeneKody = [];
    const nenajdeneKody2 = [];
    const nenajdeneKody3 = [];

    // Prvá fáza: Hľadanie kódov
    kody.forEach(kod => {
        const clearCode = kod.replace(' ', '');
        const popis = BusinessTypes[clearCode];
        if (popis) {
            popisy.push(popis + ', ');
        } else {
            nenajdeneKody.push(clearCode);
        }
    });

    // Druhá fáza: Hľadanie kódov po odstránení poslednej nuly
    nenajdeneKody.forEach(kod => {
        const upravenyKod = kod.replace(/0+$/, ''); // Odstránenie posledných núl
        const popis = BusinessTypes[upravenyKod];
        if (popis) {
            popisy.push(popis + ', ');
        } else {
            nenajdeneKody2.push(kod);
        }
    });

    // Tretia fáza: Hľadanie kódov po pridaní nuly
    nenajdeneKody2.forEach(kod => {
        const upravenyKod = '0' + kod; // Pridanie núl na začiatok
        const popis = BusinessTypes[upravenyKod];
        if (popis) {
            popisy.push(popis + ', ');
        } else {
            nenajdeneKody3.push(kod);
        }
    });

    // Štvrtá fáza: Hľadanie kódov po pridaní nuly na koniec
    nenajdeneKody3.forEach(kod => {
        const upravenyKod = kod + '0'; // Pridanie núl na koniec
        const popis = BusinessTypes[upravenyKod];
        if (popis) {
            popisy.push(popis + ', ');
        } else {
            console.log(`Popis pro kód ${kod} nenalezen, `);
        }
    });

    return popisy;
}

const TopBusinessesMutator = ({ name }) => {
    const values = useFormDataContext(({ data, setField }) => ({
        municipality: data.municipalities[0],
        value: data[name] || [],
        setField
    }));

    const [topBusinesses, setTopBusinesses] = useState([]);
    const [loading, setLoading] = useState(false);

    const [customRow, setCustomRow] = useState({
        obchodniJmeno: '',
        textovaAdresa: '',
        kategoriePoctuPracovniku: '',
        czNace: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomRow((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const addCustomRow = () => {
        if (customRow.obchodniJmeno.trim() !== '') {
            const newBusiness = {
                obchodniJmeno: customRow.obchodniJmeno,
                sidlo: { textovaAdresa: customRow.textovaAdresa },
                statistickeUdaje: {
                    // Tu sa priamo uloží hodnota bez validácie
                    kategoriePoctuPracovnikov: customRow.kategoriePoctuPracovniku
                },
                czNace: [customRow.czNace]
            };

            // Uloženie nového riadku do stateu
            setTopBusinesses((prev) => [...prev, { ...newBusiness, isCustom: true }]);

            // Aktualizácia vo formData konte
            values.setField({
                target: {
                    name,
                    value: {
                        topBusinesses: [...topBusinesses, { ...newBusiness, isCustom: true }]
                    }
                }
            });

            // Resetovanie formulára
            setCustomRow({ obchodniJmeno: '', textovaAdresa: '', kategoriePoctuPracovniku: '', czNace: '' });
        }
    };

    const removeBusiness = (indexToRemove) => {
        const updatedBusinesses = topBusinesses.filter((_, index) => index !== indexToRemove);
        setTopBusinesses(updatedBusinesses);

        values.setField({
            target: {
                name,
                value: {
                    topBusinesses: updatedBusinesses
                }
            }
        });
    };

    async function fetchTopBusinesses() {
        setLoading(true);
        try {
            const response = await axios.get(ADMIN_API_HOST + '/ares/municipality/topbusinesses/' + values.municipality.municipalityID);
            const businessesWithValidation = response.data.map(business => ({ ...business, isCustom: false }));
            setTopBusinesses(businessesWithValidation);
            values.setField({
                target: {
                    name,
                    value: {
                        topBusinesses: businessesWithValidation
                    }
                }
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    }

    const MemoizedMunicipalitiesTopBusinesses = memo(({ topBusinesses, removeBusiness }) => {
        return (
            <div className='municipalities-top-business-table'>
                {topBusinesses && (
                    <Table selectable>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Pořadí</TableHeaderCell>
                                <TableHeaderCell>Obchodní jméno</TableHeaderCell>
                                <TableHeaderCell>Adresa firmy</TableHeaderCell>
                                <TableHeaderCell>Kategórie počtu zaměstnanců</TableHeaderCell>
                                <TableHeaderCell>Zameranie podnikaní</TableHeaderCell>
                                <TableHeaderCell>Akcia</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {topBusinesses.map((item, index) => (
                                <TableRow key={item.obchodniJmeno + index}>
                                    <TableCell>{index + 1}.</TableCell>
                                    <TableCell>{item.obchodniJmeno}</TableCell>
                                    <TableCell>{item.sidlo.textovaAdresa}</TableCell>
                                    <TableCell>
                                        {item.isCustom ? getKategoriaByKod(item.statistickeUdaje.kategoriePoctuPracovnikov) : getKategoriaByKod(item.statistickeUdaje.kategoriePoctuPracovniku)}
                                    </TableCell>
                                    <TableCell>
                                        {item.isCustom ? item.czNace : getPopisyKodu(item.czNace)}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={"Vymazať"}>
                                            <Button className='removeButton' onClick={() => removeBusiness(index)}>X</Button>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
        );
    });

    return (
        <div className='municipalities-top-business-container'>
            <Button className='addButton' onClick={fetchTopBusinesses}>
                Stiahnúť údaje o firmách
            </Button>
            {loading && <Spin className='spin-loading-map' />}

            {/* Formulár na pridanie vlastného riadku */}
            <div className='add-custom-row twoColumnGrid'>
                <Input
                    name='obchodniJmeno'
                    value={customRow.obchodniJmeno}
                    onChange={handleInputChange}
                    placeholder='Obchodní jméno'
                />
                <Input
                    name='textovaAdresa'
                    value={customRow.textovaAdresa}
                    onChange={handleInputChange}
                    placeholder='Adresa firmy'
                />
                <Select
                    name='kategoriePoctuPracovniku'
                    value={customRow.kategoriePoctuPracovniku}
                    onChange={(value) => setCustomRow((prevState) => ({ ...prevState, kategoriePoctuPracovniku: value }))}
                    placeholder='Vyberte kategóriu počtu zamestnancov'
                    style={{ width: '100%' }}
                >
                    {EmployeeList.map(item => (
                        <Option key={item.kodPocetZamestnancov} value={item.kodPocetZamestnancov}>
                            {item.kategoria}
                        </Option>
                    ))}
                </Select>
                <Input name='czNace' value={customRow.czNace} onChange={handleInputChange} placeholder='Zameranie podnikania' />
                <Button className='addButton' onClick={addCustomRow}>Pridať vlastný riadok</Button>
            </div>

            <MemoizedMunicipalitiesTopBusinesses topBusinesses={topBusinesses} removeBusiness={removeBusiness} />
        </div>
    );
};

function TopBusinessesRenderer(values, key) {
    if (!values[key]) return <></>;

    return `
        <table>
            <thead>
                <tr>
                    <th>Pořadí</th>
                    <th>Obchodní jméno</th>
                    <th>Adresa firmy</th>
                    <th>Kategórie počtu zaměstnanců</th>
                    <th>Zameranie podnikaní</th>
                </tr>
            </thead>
            <tbody>
                ${values[key].topBusinesses.map(
        (item, index) => `
                        <tr key=${item.obchodniJmeno + index}>
                            <td>${index + 1}.</td>
                            <td>${item.obchodniJmeno}</td>
                            <td>${item.sidlo.textovaAdresa}</td>
                            <td>${item.isCustom ? getKategoriaByKod(item.statistickeUdaje.kategoriePoctuPracovnikov) : getKategoriaByKod(item.statistickeUdaje.kategoriePoctuPracovniku)}</td>
                            <td>${item.isCustom ? item.czNace : getPopisyKodu(item.czNace)}</td>
                        </tr>
                    `
    ).join('')}
            </tbody>
        </table>
    `;
}

function TopBusinessesValidator() {
    return true;
}

export default {
    Mutator: TopBusinessesMutator,
    HTMLRenderer: TopBusinessesRenderer,
    Validator: TopBusinessesValidator
};