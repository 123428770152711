// src/components/MapComponent.jsx
import { Button, Checkbox, Input, notification, Spin } from 'antd';
import domtoimage from 'dom-to-image';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Map, Polygon, TileLayer } from 'react-leaflet';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import { prepareLayer } from '../../../../../utils/GeometryUtils';
import Text from '../../../../../components/Text';
import { translations } from '../../../../../utils/LocalizedStrings';
import BuildingCard from './BuildingCard';

// Fix for default icon not displaying properly
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const BuildingCardMainMutator = ({ name }) => {

    const [activeIndexes, setActiveIndexes] = useState({});
    const [isActiveMap, setIsActiveMap] = useState(false);
    const [openCards, setOpenCards] = useState({});
    const [loading, setLoading] = useState(false);

    const setActiveIndex = useCallback((activeIndex, index) => {
        setActiveIndexes(precActiveIndexes => {
            return {
                ...precActiveIndexes,
                [index]: activeIndex
            };
        });
    }, [activeIndexes]);

    const setOpenCard = useCallback((openCard, index) => {
        setOpenCards(precActiveIndexes => {
            return {
                ...precActiveIndexes,
                [index]: openCard
            };
        });
    }, [openCards]);

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { position: [49.94297310, 17.03212700], zoom: 13 },
        municipality: data.municipalities[0],
        setField
    }));
    const [fieldValues, setFieldValues] = useState(values)

    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    }

    const mapRef = useRef();
    const containerRef = useRef();
    const mapUniqueId = "mapaBuildingCardMain" + name ? name : "";

    useEffect(() => {
        if (values.municipality.geoBoundingBox && mapRef.current) {
            const map = mapRef.current.leafletElement;
            map.fitBounds(values.municipality.geoBoundingBox);
        }
    }, []);

    useEffect(() => {
        const mapInstance = mapRef.current.leafletElement;
        if (mapInstance && !isActiveMap) {
            mapInstance.dragging.disable();
            mapInstance.touchZoom.disable();
            mapInstance.doubleClickZoom.disable();
            mapInstance.scrollWheelZoom.disable();
            mapInstance.boxZoom.disable();
            mapInstance.keyboard.disable();
        }
    }, [isActiveMap]);

    const enableMapInteractions = () => {
        const mapInstance = mapRef.current.leafletElement;
        if (!isActiveMap && mapInstance) {
            setIsActiveMap(true);
            mapInstance.dragging.enable();
            mapInstance.touchZoom.enable();
            mapInstance.doubleClickZoom.enable();
            mapInstance.scrollWheelZoom.enable();
            mapInstance.boxZoom.enable();
            mapInstance.keyboard.enable();
        }
    };

    const disableMapInteractions = (event) => {
        const mapElement = document.getElementById(mapUniqueId);
        if (!mapElement.contains(event.target)) {
            const mapInstance = mapRef.current.leafletElement;
            if (isActiveMap && mapInstance) {
                setIsActiveMap(false);
                mapInstance.dragging.disable();
                mapInstance.touchZoom.disable();
                mapInstance.doubleClickZoom.disable();
                mapInstance.scrollWheelZoom.disable();
                mapInstance.boxZoom.disable();
                mapInstance.keyboard.disable();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', disableMapInteractions);

        return () => {
            document.removeEventListener('click', disableMapInteractions);
        };
    }, [isActiveMap]);

    const captureImage = (save) => {
        if (containerRef.current) {
            domtoimage.toPng(containerRef.current)
                .then((dataUrl) => {
                    setFieldValues(prev => ({
                        ...prev,
                        value: {
                            ...prev.value,
                            image: dataUrl
                        }
                    }));
                    if (save) {
                        values.setField({
                            target: {
                                name,
                                value: {
                                    ...fieldValues.value,
                                    image: dataUrl
                                }
                            },
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error capturing map image:', error);
                });
        }
    };

    const handleCaptureMapImage = (save) => {
        const mapContainer = document.getElementById(mapUniqueId);
        if (mapContainer) {
            mapContainer.classList.add('full-screen');
        }

        setTimeout(() => {
            const mapInstance = mapRef.current?.leafletElement;
            if (mapInstance) {
                mapInstance.invalidateSize();
            }
        }, 1000);

        setTimeout(() => {
            if (containerRef.current) {
                domtoimage.toPng(containerRef.current)
                    .then((dataUrl) => {
                        setFieldValues(prev => ({
                            ...prev,
                            value: {
                                ...prev.value,
                                image: dataUrl
                            }
                        }));
                        if (save) {
                            values.setField({
                                target: {
                                    name,
                                    value: {
                                        ...fieldValues.value,
                                        image: dataUrl
                                    }
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        console.error('Error capturing map image:', error);
                    });
            }
        }, 2000);

        if (mapContainer) {
            setTimeout(() => {
                mapContainer.classList.remove('full-screen');
            }, 3000);
        }
    };

    const onMoveEnd = () => {
        const map = mapRef.current.leafletElement;
        const newCenter = map.getCenter();
        let tmpFieldValues = { ...fieldValues };
        tmpFieldValues.value.position = [newCenter.lat, newCenter.lng];
        tmpFieldValues.value.zoom = map.getZoom();

        captureImage()
    };

    const fetchLayer = async () => {
        setLoading(true)
        const geoLayer = await prepareLayer(values.municipality.geoJson) //geoBoundingBoxJson)
        if (geoLayer) {
            const tmpFieldValues = JSON.parse(JSON.stringify(fieldValues))
            tmpFieldValues.value.buildings = geoLayer.buildings
            tmpFieldValues.value.geoLayer = geoLayer.geoLayer
            if (containerRef.current) {
                domtoimage.toPng(containerRef.current)
                    .then((dataUrl) => {
                        tmpFieldValues.image = dataUrl
                        setFieldValues(tmpFieldValues)
                        values.setField({
                            target: {
                                name,
                                value: tmpFieldValues.value
                            },
                        });
                    })
                    .catch((error) => {
                        console.error('Error capturing map image:', error);
                    });
            }
        } else {
            notification.error({ message: "No data" });
        }
        setLoading(false)
    }

    const changeBuilding = useCallback((building, index) => {
        setFieldValues(prevFieldValues => {
            const updatedBuildings = [...prevFieldValues.value.buildings];
            updatedBuildings[index] = building
            values.setField({
                target: {
                    name,
                    value: {
                        ...prevFieldValues.value,
                        buildings: updatedBuildings
                    }
                },
            });
            return {
                ...prevFieldValues,
                value: {
                    ...prevFieldValues.value,
                    buildings: updatedBuildings
                }
            };
        });
    }, [fieldValues]);

    const MemoizedBuildingCardMutator = React.memo(BuildingCard.Mutator, (prevProps, nextProps) => {
        return prevProps.building === nextProps.building;
    });

    const MemoizedBuildingTableRow = React.memo(BuildingTableRow, (prevProps, nextProps) => {
        return prevProps.building === nextProps.building;
    });

    return (
        <div className='open-street-map building-card-main'>
            <div ref={containerRef} className={isActiveMap ? 'open-street-map is-active-map' : 'open-street-map'}>
                <div className='map-title-buttons-container'>
                    <h3>Karta budovy</h3>
                    <div style={{display: 'flex', gap: 15, flexWrap: 'wrap'}}>
                        <Button
                            className='addButton'
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                fetchLayer()
                            }}>{loading ? 'Načítavam' : 'Načítať seznam budov ve vlastníctvi obce'}</Button>
                        <Button
                            className='addButton'
                            onClick={handleCaptureMapImage}>Vložiť do náhľadu</Button>
                        {loading && <Spin className='spin-loading-map' />}
                    </div>
                </div>
                <Map
                    onblur={handleChange}
                    center={values?.municipality?.geoCenter}
                    zoom={fieldValues.value.zoom}
                    ref={mapRef}
                    onMoveEnd={onMoveEnd}
                    onZoomEnd={onMoveEnd}
                    id={mapUniqueId}
                    onClick={enableMapInteractions}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    >
                    </TileLayer>
                    <Polygon positions={[values?.municipality?.geoJson]} />
                    {fieldValues?.value?.geoLayer && (
                        fieldValues?.value?.geoLayer.map(layer => {
                            return (
                                <Polygon
                                    color='#ff0000'
                                    key={"524862865862466988"}
                                    positions={[[layer.geometry]]}
                                />
                            )
                        })
                    )}
                </Map>
            </div>
            {fieldValues?.value?.buildings &&
                <>
                    {fieldValues?.value?.buildings.map((building, index) => {
                        return (
                            <MemoizedBuildingTableRow index={index} handleChange={changeBuilding} building={building} />
                        )
                    })
                    }
                    {fieldValues?.value?.buildings.map((building, index) => {
                        return (
                            <React.Fragment key={index} >
                                
                                <MemoizedBuildingCardMutator buildingName={building.name} openCard={openCards[index]} setOpenCard={setOpenCard} activeIndex={activeIndexes[index]} setActiveIndex={setActiveIndex} index={index} handleChange={changeBuilding} building={building} />
                            </React.Fragment>
                        )
                    })}
                </>
            }
        </div>
    );
};

const BuildingTableRow = (props) => {
    const [building, setBuilding] = useState(props.building)

    const changeBuilding = useCallback((e) => {
        setBuilding(prevFieldValues => {
            const updatedBuilding = { ...prevFieldValues };
            if (e.target.type === "checkbox") {
                updatedBuilding.building_card = e.target.checked;
            } else {
                if (e.target.name === "address") {
                    updatedBuilding.address.display_name = e.target.value;
                } else {
                    updatedBuilding[e.target.name] = e.target.value;
                }
            }
            return {
                ...prevFieldValues,
                ...updatedBuilding
            };
        });
    }, [building]);

    const handleChange = () => {
        props.handleChange(building, props.index)
    }

    return (
        <section className='open-street-map-grid'>
            <Checkbox
                onChange={(e) => { changeBuilding(e) }}
                onBlur={handleChange}
                checked={building.building_card}
            ></Checkbox>
            <div>
                <Text strong>Budova</Text>
                <Input
                    className="ant-input"
                    autoComplete="chrome-off"
                    name={"name"}
                    placeholder={'Názov budovy'}
                    value={building.name ? building.name : ''}
                    onChange={(e) => { changeBuilding(e) }}
                    onBlur={handleChange}
                />
            </div>
            <div>
                <Text strong>Adresa</Text>
                <Input
                    className="ant-input"
                    autoComplete="chrome-off"
                    name={"address"}
                    placeholder={'Adresa budovy'}
                    value={building.address ? building.address.display_name : ''}
                    onChange={(e) => { changeBuilding(e) }}
                    onBlur={handleChange}
                />
            </div>
            <div>
                <Text strong>Popis</Text>
                <Input
                    className="ant-input"
                    autoComplete="chrome-off"
                    name={"description"}
                    placeholder={'Popis budovy'}
                    value={building.description ? building.description : ''}
                    onChange={(e) => { changeBuilding(e) }}
                    onBlur={handleChange}
                />
            </div>
        </section>
    )
}

function BuildingCardMainRenderer(values, key) {
    let returnValue = ""
    if (values[key]) {
        returnValue += `<img src="${values[key].image}" alt="Chart view" style="width: 100%; height: auto; object-fit: cover;"/>`
        if (values[key].buildings) {
            returnValue += `<table class="building-card-table">`;
            returnValue += `<tr>`
            returnValue += `<td width="150px" rowspan="1" colspan="1">Budova`
            returnValue += "</td>"
            returnValue += `<td width="270px" rowspan="1" colspan="1">Adresa`
            returnValue += "</td>"
            returnValue += `<td width="200px" rowspan="1" colspan="1">Popis budovy`
            returnValue += "</td>"
            returnValue += "</tr>"
            for (let building of values[key].buildings) {
                returnValue += `<tr>`
                returnValue += `<td width="150px" rowspan="1" colspan="1">${building.name ? building.name : ''}`
                returnValue += "</td>"
                returnValue += `<td width="270px" rowspan="1" colspan="1">${building.address.display_name}`
                returnValue += "</td>"
                returnValue += `<td width="200px" rowspan="1" colspan="1">${building.description ? building.description : ''}`
                returnValue += "</td>"
                returnValue += "</tr>"
            }
            returnValue += "</table>"
        }
    }

    return returnValue
}

function BuildingCardMainValidator() {
    return true;
}

export default {
    Mutator: BuildingCardMainMutator,
    HTMLRenderer: BuildingCardMainRenderer,
    Validator: BuildingCardMainValidator
};
