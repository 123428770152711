import { useState } from 'react';

const LegendCustom = ({ legendData, opacity }) => {
  // const [legendData, setLegendData] = useState([]);
  const [openLegend, setOpenLegend] = useState(false);

  const toogleLegend = () => {
    setOpenLegend(!openLegend);
  };


  return (legendData && (
    <div className="legend-container">
      <div className='title-icon-container'>
        {legendData && legendData.layers.length > 0 &&
          <span onClick={toogleLegend}>Legenda</span>
        }
      </div>

      {openLegend && legendData.layers.length > 0 &&
        <>
          <div className="legend-layer">
            <div className="legend-layer-title">{legendData.layerName}</div>
            {
              legendData.layers.map((layer, index) => (
                <div key={index} className="legend-item">
                  <div
                    className="legend-swatch"
                    style={{ backgroundColor: layer.color, opacity: opacity }}
                    alt={layer.label}
                  />
                  <div className="legend-label">{layer.label}</div>
                </div>
              ))}
          </div>
        </>
      }
    </div>
  ))
}

export default LegendCustom;