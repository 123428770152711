// utils/mapUtils.js
import { notification } from 'antd';
import domtoimage from 'dom-to-image';
import L from 'leaflet';

export const setupLeafletDefaults = () => {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
};

export const captureMapImage = (containerRef, setFieldValues, fieldValues, values, name, save) => {
    if (containerRef.current) {
        domtoimage.toPng(containerRef.current)
            .then((imageContent) => {
                setFieldValues(prev => ({
                    ...prev,
                    value: {
                        ...prev.value,
                        image: imageContent
                    }
                }));
                if (save) {
                    values.setField({
                        target: {
                            name,
                            value: {
                                ...fieldValues.value,
                                image: imageContent
                            }
                        },
                    });
                }
            })
            .catch((error) => {
                console.error('Error capturing map image:', error);
            });
    }
};

export const getMapBounds = (mapRef) => {
    const map = mapRef?.current?.leafletElement;
    if (map) {
        const bounds = map.getBounds();
        const southWest = bounds.getSouthWest();
        const northEast = bounds.getNorthEast();
        return [southWest.lng, southWest.lat, northEast.lng, northEast.lat];
    }
    return null;
};

export const getPolygonFromBounds = (bounds) => {
    // Extract bounding box values
    const minLng = bounds[1]; // Bottom-left longitude
    const minLat = bounds[0]; // Bottom-left latitude
    const maxLng = bounds[3]; // Top-right longitude
    const maxLat = bounds[2]; // Top-right latitude

    // Create polygon from bounding box
    const polygon = [
        [minLng, minLat],  // Bottom-left
        [maxLng, minLat],  // Bottom-right
        [maxLng, maxLat],  // Top-right
        [minLng, maxLat],  // Top-left
        [minLng, minLat]   // Closing the polygon (back to bottom-left)
    ];
    return [[polygon]];
}

export const getPolygonFromBoundsLatLng = (bounds) => {
    // Extract bounding box values
    const minLng = bounds[1]; // Bottom-left longitude
    const minLat = bounds[0]; // Bottom-left latitude
    const maxLng = bounds[3]; // Top-right longitude
    const maxLat = bounds[2]; // Top-right latitude

    // Create polygon from bounding box
    const polygon = [
        [minLat, minLng],  // Bottom-left
        [minLat, maxLng],  // Bottom-right
        [maxLat, maxLng],  // Top-right
        [maxLat, minLng],  // Top-left
        [minLat, minLng]   // Closing the polygon (back to bottom-left)
    ];
    return [[polygon]];
}
export const fetchMapLayer = async (prepareLayerFunction, mapGeoBox, setFieldValues, fieldValues, containerRef, values, name) => {
    const geoLayer = await prepareLayerFunction(mapGeoBox);
    if (geoLayer) {
        const tmpFieldValues = JSON.parse(JSON.stringify(fieldValues));

        tmpFieldValues.value.buildings = geoLayer.buildings;
        tmpFieldValues.value.geoLayer = geoLayer.geoLayer;
        tmpFieldValues.value.geoServer = geoLayer.geoServer;
        tmpFieldValues.value.legend = geoLayer.legend

        if (containerRef.current) {
            domtoimage.toPng(containerRef.current)
                .then((dataUrl) => {
                    tmpFieldValues.image = dataUrl;
                    setFieldValues(tmpFieldValues);
                    values.setField({
                        target: {
                            name,
                            value: tmpFieldValues.value
                        },
                    });
                })
                .catch((error) => {
                    console.error('Error capturing map image:', error);
                });
        }
    } else {
        notification.error({ message: "No data" });
    }
};

export const fetchMapPoints = async (prepareLayerFunction, mapGeoBox, setFieldValues, fieldValues, containerRef, values, name) => {
    console.log("fetchMapPoints",  mapGeoBox)
    const geoLayer = await prepareLayerFunction(mapGeoBox);
    if (geoLayer) {
        const tmpFieldValues = JSON.parse(JSON.stringify(fieldValues));

        tmpFieldValues.value.bioGasPlants = geoLayer;

        if (containerRef.current) {
            domtoimage.toPng(containerRef.current)
                .then((dataUrl) => {
                    tmpFieldValues.image = dataUrl;
                    setFieldValues(tmpFieldValues);
                    values.setField({
                        target: {
                            name,
                            value: tmpFieldValues.value
                        },
                    });
                })
                .catch((error) => {
                    console.error('Error capturing map image:', error);
                });
        }
    } else {
        notification.error({ message: "No data" });
    }
};