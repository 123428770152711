import { Button, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Map, Polygon, TileLayer } from 'react-leaflet';
import { prepareLayerPotencialniVyskytDruhuVegetace as preparePotencialniVyskytDruhuVegetaceLayer } from '../../../../utils/GeometryUtils';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import Legend from '../Legend';
import { setupLeafletDefaults } from '../../../../utils/MapaImageUtils';
import { captureMapImage, fetchMapLayer, getMapBounds, getPolygonFromBounds } from '../../../../utils/MapaUtils';

setupLeafletDefaults();

const MapaPotencialnyVyskytDruhuVegetaceMutator = ({ name }) => {
    const mapUniqueId = "mapaPotencionalnyVyskytDruhuVegetace" + (name ? name : "");

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { position: [49.94297310, 17.03212700], zoom: 13 },
        municipality: data.municipalities[0],
        setField
    }));

    const [fieldValues, setFieldValues] = useState(values);
    const [isActiveMap, setIsActiveMap] = useState(false);
    const [opacity, setOpacity] = useState(0.75);
    const [loading, setLoading] = useState(false);
    const mapRef = useRef();
    const containerRef = useRef();
    const [legendColorMap, setLegendColorMap] = useState({});
    const canvasRef = useRef(null);

    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    };

    const onMoveEnd = () => {
        const map = mapRef.current.leafletElement;
        const newCenter = map.getCenter();
        let tmpFieldValues = { ...fieldValues };
        tmpFieldValues.value.position = [newCenter.lat, newCenter.lng];
        tmpFieldValues.value.zoom = map.getZoom();
        captureMapImage(containerRef, setFieldValues, fieldValues, values, name, false);
    };

    useEffect(() => {
        if (values.municipality.geoBoundingBox && mapRef.current) {
            const map = mapRef.current.leafletElement;
            map.fitBounds(values.municipality.geoBoundingBox);
        }
    }, []);

    useEffect(() => {
        const mapInstance = mapRef.current?.leafletElement;
        if (mapInstance && !isActiveMap) {
            mapInstance.dragging.disable();
            mapInstance.touchZoom.disable();
            mapInstance.doubleClickZoom.disable();
            mapInstance.scrollWheelZoom.disable();
            mapInstance.boxZoom.disable();
            mapInstance.keyboard.disable();
        }
    }, [isActiveMap]);

    const enableMapInteractions = () => {
        const mapInstance = mapRef.current?.leafletElement;
        if (!isActiveMap && mapInstance) {
            setIsActiveMap(true);
            mapInstance.dragging.enable();
            mapInstance.touchZoom.enable();
            mapInstance.doubleClickZoom.enable();
            mapInstance.scrollWheelZoom.enable();
            mapInstance.boxZoom.enable();
            mapInstance.keyboard.enable();
        }
    };

    const disableMapInteractions = (event) => {
        const mapElement = document.getElementById(mapUniqueId);
        if (!mapElement.contains(event.target)) {
            const mapInstance = mapRef.current.leafletElement;
            if (isActiveMap && mapInstance) {
                setIsActiveMap(false);
                mapInstance.dragging.disable();
                mapInstance.touchZoom.disable();
                mapInstance.doubleClickZoom.disable();
                mapInstance.scrollWheelZoom.disable();
                mapInstance.boxZoom.disable();
                mapInstance.keyboard.disable();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', disableMapInteractions);
        return () => {
            document.removeEventListener('click', disableMapInteractions);
        };
    }, [isActiveMap]);

    const handleOpacityChange = (event) => {
        setOpacity(event.target.value);
    };

    const cropImage = (imageUrl) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageUrl;

            image.onload = () => {
                const canvas = canvasRef.current;
                const ctx = canvas.getContext('2d');

                // Set canvas size to original image size
                canvas.width = 20;
                canvas.height = 20;
                // Draw original image on canvas
                ctx.drawImage(image, 0, 0);

                // Crop the image (cutting out 3px from all sides)
                const croppedCanvas = document.createElement('canvas');
                const croppedCtx = croppedCanvas.getContext('2d');

                // Set new size (14x14 after cropping 3px on each side)
                croppedCanvas.width = 14;
                croppedCanvas.height = 14;

                // Draw the cropped area of the image (cropping 3px from each side)
                croppedCtx.drawImage(canvas, 3, 3, 14, 14, 0, 0, 14, 14);

                // Convert the canvas to a data URL (Base64 image)
                const croppedImageDataUrl = croppedCanvas.toDataURL('image/png');
                resolve(croppedImageDataUrl);
            };

            image.onerror = (error) => reject(error);
        });
    };


    // Function to create color map for each layer from the legend
    async function createValuesColorMap(layers) {
        const valuesColorMap = {};
        for (const item of layers.legend) {
            // Use a for...of loop to handle asynchronous tasks
            for (const value of item.values) {
                valuesColorMap[value] = await cropImage('data:image/png;base64,' + item.imageData); // Store imageData for each value
            }
        }
        return valuesColorMap;
    }

    useEffect(() => {
        async function fetchLegendColorMap() {
            if (fieldValues?.value?.legend) {
                const filteredLegend = fieldValues?.value?.legend.filter(
                    (l) => l.layerId === fieldValues?.value?.geoServer?.layerId
                )[0];

                if (filteredLegend) {
                    const legendColorMap = await createValuesColorMap(filteredLegend);
                    console.log("Legend Color Map", legendColorMap); // Add this log to check the base64 data
                    setLegendColorMap(legendColorMap); // Set the state with the resulting map
                }
            }
        }
        fetchLegendColorMap(); // Call the async function inside useEffect
    }, [fieldValues?.value?.legend]);

    // Apply the pattern to the polygons after they are rendered
    useEffect(() => {
        const applyPatternToPolygons = () => {
            const paths = document.querySelectorAll('path.leaflet-interactive');
            paths.forEach((path, index) => {
                const kodMj = fieldValues?.value?.geoLayer?.[index]?.attributes?.KOD_MJ;
                if (kodMj && path) {
                    path.setAttribute('fill', `url(#pattern-${kodMj})`);
                    path.setAttribute('fill-opacity', opacity);
                }
            });
        };

        applyPatternToPolygons();  // Apply patterns after rendering
    }, [fieldValues?.value?.geoLayer, opacity]);

    const handleCaptureMapImage = async () => {
        try {
            const mapContainer = document.getElementById(mapUniqueId);
            if (!mapContainer) {
                console.error("Map container not found");
                return;
            }
    
            mapContainer.classList.add('full-screen');
    
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const mapInstance = mapRef.current?.leafletElement;
            
            if (!mapInstance) {
                console.error("Map instance not found");
                mapContainer.classList.remove('full-screen');
                return;
            }
    
            mapInstance.invalidateSize();
    
            await new Promise((resolve) => setTimeout(resolve, 4000));
            captureMapImage(containerRef, setFieldValues, fieldValues, values, name, true);
    
            await new Promise((resolve) => setTimeout(resolve, 2000));
            mapContainer.classList.remove('full-screen');
        } catch (error) {
            console.error("Error during map capture:", error);
        }
    };

    return (
        <div className='open-street-map'>
            <div ref={containerRef} className={isActiveMap ? 'open-street-map is-active-map' : 'open-street-map'}>
                <div className='map-title-buttons-container'>
                    <h3>Mapa Potenciálny výskyt druhú vegetace</h3>
                    <div>
                        <Button
                            className='addButton'
                            style={{ marginRight: 20 }}
                            onClick={async () => {
                                setLoading(true);
                                try {
                                    await fetchMapLayer(preparePotencialniVyskytDruhuVegetaceLayer, getPolygonFromBounds(getMapBounds(mapRef)), setFieldValues, fieldValues, containerRef, values, name)
                                } catch (error) {
                                    console.error('Error fetching map layer:', error);
                                } finally {
                                    setLoading(false);
                                }
                            }}
                        >
                            {loading ? 'Načítavam údaje...' : 'Stiahnúť údaje'}
                        </Button>
                        <Button className='addButton' onClick={handleCaptureMapImage}>Vložiť do náhľadu</Button>
                        {loading && <Spin className='spin-loading-map' />}
                    </div>
                </div>
                <Map
                    onblur={handleChange}
                    center={values?.municipality?.geoCenter}
                    zoom={fieldValues.value.zoom}
                    ref={mapRef}
                    onMoveEnd={onMoveEnd}
                    onZoomEnd={onMoveEnd}
                    id={mapUniqueId}
                    onClick={enableMapInteractions}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Polygon key={mapUniqueId + "poly"} positions={values?.municipality?.geoJson} opacity={opacity} />                    {/* The SVG pattern definitions */}
                    <svg style={{ position: 'absolute', width: 0, height: 0 }}>
                        <defs>
                            {Object.keys(legendColorMap).map(kodMj => (
                                <pattern
                                    id={`pattern-${kodMj}`}   // Unique ID for each pattern
                                    patternUnits="userSpaceOnUse"
                                    width="20"
                                    height="20"
                                    key={kodMj}
                                >
                                    <image
                                        href={legendColorMap[kodMj]}  // Full base64-encoded image
                                        width="20"
                                        height="20"
                                        preserveAspectRatio="xMidYMid slice"
                                    />
                                </pattern>
                            ))}
                        </defs>
                    </svg>
                    {fieldValues?.value?.geoLayer?.map((layer, idx) => (
                        <Polygon
                            key={mapUniqueId + "poly" + idx}
                            positions={layer.geometry}
                            ref={(el) => {
                                const pathsDOM = [...document.querySelectorAll(`#${mapUniqueId} path.leaflet-interactive`)].slice(1)
                                if (el) {
                                    setTimeout(() => {
                                        const kodMj = layer.attributes.KOD_MJ;
                                        if (pathsDOM[idx] && kodMj) {
                                            pathsDOM[idx].setAttribute('fill', `url(#pattern-${kodMj})`);
                                            pathsDOM[idx].setAttribute('fill-opacity', opacity);
                                            pathsDOM[idx].setAttribute('stroke', false)
                                        }
                                    }, 0);
                                }
                            }}
                        />
                    ))}
                </Map>
                <Legend
                    legendData={fieldValues?.value?.legend}
                    layerId={fieldValues?.value?.geoServer?.layerId}
                    opacity={opacity}
                />
                <div className='opacity-slider-map'>
                    <span className='opacity-slider-controller'>
                        <label>Viditeľnosť vrstiev</label>
                        <input
                            type="range"
                            id="opacity"
                            name="opacity"
                            min="0"
                            max="1"
                            step="0.02"
                            value={opacity}
                            onChange={handleOpacityChange}
                        />
                    </span>
                    <span><strong>Zdroj:</strong> AOPK-vlastní zpracování</span>
                </div>
            </div>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
        </div >
    );
};

function MapaPotencialnyVyskytDruhuVegetaceRenderer(values, key) {
    let returnValue = ""
    if (values[key]) {
        returnValue += `<img src="${values[key].image}" alt="Map view" style="height: 100%; width: 100%; object-fit: cover;" />`;
    }

    return returnValue
}

function MapaPotencialnyVyskytDruhuVegetaceValidator() {
    return true;
}

export default {
    Mutator: MapaPotencialnyVyskytDruhuVegetaceMutator,
    HTMLRenderer: MapaPotencialnyVyskytDruhuVegetaceRenderer,
    Validator: MapaPotencialnyVyskytDruhuVegetaceValidator
};
