import { useRef, useState } from 'react';
import {
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip
} from 'recharts';
import { Tooltip as AntdTooltip } from 'antd';
import { Button, Input, Table } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { captureMapImage } from '../../../../utils/MapaUtils';
import { useFormDataContext } from '../../contexts/form-data.ctx';

const COLORS = ['#00b04e', '#c6e0b5', '#c65a10', '#aeabaa', '#4373c4', '#6f31a0', '#71ad47'];

const PieChartMutator = ({ name }) => {
    const uniqueId = useRef(uuidv4());
    const containerRef = useRef();
    const [data, setData] = useState([]);
    const [titleValue, setTitleValue] = useState('');
    const [nameValue, setNameValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [sourceValue, setSourceValue] = useState('');

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { position: [49.94297310, 17.03212700], zoom: 13 },
        municipality: data.municipalities[0],
        data,
        setField
    }));
    const [fieldValues, setFieldValues] = useState(values)

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleNameChange = (e) => {
        setNameValue(e.target.value);
    };

    const handleTitleChange = (e) => {
        setTitleValue(e.target.value);
    };

    const handleSourceChange = (e) => {
        setSourceValue(e.target.value);
    };

    const handleUpdateData = () => {
        const newValue = Number(inputValue);
        const newName = nameValue.trim();

        if (!isNaN(newValue) && newName) {
            const newEntry = { name: newName, value: newValue };

            setData([...data, newEntry]);

            setInputValue('');
            setNameValue('');
        }
    };

    const handleRemoveData = (name) => {
        const filteredData = data.filter((entry) => entry.name !== name);
        setData(filteredData);
    };

    const handleCaptureMapImage = async () => {
        const mapContainer = document.getElementById(uniqueId.current);

        if (mapContainer) {
            mapContainer.classList.add('full-screen');
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));

        await captureMapImage(containerRef, setFieldValues, fieldValues, values, name, true);

        if (mapContainer) {
            setTimeout(() => {
                mapContainer.classList.remove('full-screen');
            }, 1000);
        }
    };

    return (
        <ResponsiveContainer className="recharts-container pie-chart">
            <div className='twoColumnGrid'>
                <span>
                    <label>Názov grafu</label>
                    <Input
                        type="text"
                        value={titleValue}
                        onChange={handleTitleChange}
                    />
                </span>
                <span>
                    <label>Názov hodnoty</label>
                    <Input
                        type="text"
                        value={nameValue}
                        onChange={handleNameChange}
                    />
                </span>
                <span>
                    <label>Velkosť hodnoty</label>
                    <Input
                        type="number"
                        value={inputValue}
                        onChange={handleInputChange}
                    />
                </span>
                <span>
                    <label>Zdroj</label>
                    <Input
                        type="text"
                        value={sourceValue}
                        onChange={handleSourceChange}
                    />
                </span>
                <button className='addButton' onClick={handleUpdateData}>Pridať do grafu</button>
                {data.length > 0 &&
                    <button className='addButton' onClick={handleCaptureMapImage}>Vložiť do náhľadu</button>}
            </div>

            <div id={uniqueId.current} className='chart-capture' ref={containerRef}>
                {data.length > 0 &&
                    <>
                        <h3>{titleValue}</h3>
                        <div style={{ marginTop: '4rem', marginLeft: '-2rem' }}>
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChart>
                                    <Pie
                                        data={data}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={100}
                                        fill="#8884d8"
                                        dataKey="value"
                                        label
                                    >
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                            <p style={{ textAlign: 'right', padding: '20px 0' }}>Zdroj: {sourceValue}</p>
                        </div>
                    </>
                }
            </div>

            {data.length > 0 && (
                <Table celled style={{ marginTop: '2rem' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: '45%' }}>Meno</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '45%' }}>Hodnota</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '10%' }}>Akcia</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.map((entry, index) => (
                            <Table.Row key={index}>
                                <Table.Cell>{entry.name}</Table.Cell>
                                <Table.Cell>{entry.value}</Table.Cell>
                                <Table.Cell>
                                    <AntdTooltip title={"Vymazať"}>
                                        <Button className='removeButton' onClick={() => handleRemoveData(entry.name)}>X</Button>
                                    </AntdTooltip>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}
        </ResponsiveContainer>
    );
};

function ChartsRenderer(values, key) {
    if (values[key] && values[key].image) {
        let returnValue = ""
        returnValue += `<img src="${values[key].image}" alt="Chart view" style="width: 100%; height: auto; object-fit: cover;"/>`
        return returnValue;
    }
    return ""
}

function ChartsValidator() {
    return true;
}

export default {
    Mutator: PieChartMutator,
    HTMLRenderer: ChartsRenderer,
    Validator: ChartsValidator
};
