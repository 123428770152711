
import axios from "axios";
import { ADMIN_API_HOST } from "../constants";
import proj4 from 'proj4';

// Define Proj4 EPSG definitions once
proj4.defs('EPSG:4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs');
proj4.defs('EPSG:5514', '+proj=krovak +lat_0=49.5 +lon_0=24.8333333333333 +alpha=30.2881397527778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=589,76,480,0,0,0,0                               +units=m +no_defs +type=crs');
proj4.defs('EPSG:5513','+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +k=0.9999 +x_0=-4850048.089 +y_0=-5070073.041 +datum=WGS84 +units=m +no_defs')


export const fetchGeoJsonFromOverpass = async (city, county) => {
    try {
        const url = `https://nominatim.openstreetmap.org/search?city=${city}&county=${county}&format=json&polygon_geojson=1`;

        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0) {
            return data[0].geojson;
        }
    } catch (error) {
        console.error("Error fetching GeoJSON data:", error);
    }

    return null;
};

export const processOverpassData = (data) => {
    if (!data || !data.coordinates) return [];

    const convertCoordinates = (coordinates) =>
        coordinates.map(([lon, lat]) => [lat, lon]);

    if (data.type === 'MultiPolygon') {
        return data.coordinates.map(polygon => polygon.map(convertCoordinates));
    } else if (data.type === 'Polygon') {
        return [data.coordinates.map(convertCoordinates)];
    }

    return [[[]]];
};

export const convertCoordinates = (data, fromEPSG = 'EPSG:4326', toEPSG = 'EPSG:5514') => {
    return proj4(fromEPSG, toEPSG, data);
};

// Wrappers for specific conversions
export const convertCoordinates2Krovak = (data) => convertCoordinates(data, 'EPSG:4326', 'EPSG:5514');
export const convertCoordinates2Krovak5513 = (data) => convertCoordinates(data, 'EPSG:4326', 'EPSG:5513');
export const convertCoordinates2WG84 = (data) => convertCoordinates(data, 'EPSG:5514', 'EPSG:4326');

export const calculateGeographicalCenter = (data) => {
    let x = 0, y = 0, z = 0, totalPoints = 0;

    data.forEach(polygonGroup => {
        polygonGroup.forEach(polygon => {
            polygon.forEach(([lng, lat]) => {
                const latRad = toRadians(lat);
                const lngRad = toRadians(lng);

                x += Math.cos(latRad) * Math.cos(lngRad);
                y += Math.cos(latRad) * Math.sin(lngRad);
                z += Math.sin(latRad);

                totalPoints += 1;
            });
        });
    });

    x /= totalPoints;
    y /= totalPoints;
    z /= totalPoints;

    const lng = Math.atan2(y, x);
    const hyp = Math.sqrt(x * x + y * y);
    const lat = Math.atan2(z, hyp);

    return [toDegrees(lng), toDegrees(lat)];
};

export const calculateBoundingBox = (data) => {
    if (!data || !data.length) return null;

    let minLat = Infinity, minLng = Infinity, maxLat = -Infinity, maxLng = -Infinity;

    data.forEach(polygon => {
        polygon.forEach(ring => {
            ring.forEach(([lat, lng]) => {
                if (lat < minLat) minLat = lat;
                if (lat > maxLat) maxLat = lat;
                if (lng < minLng) minLng = lng;
                if (lng > maxLng) maxLng = lng;
            });
        });
    });

    return [[minLat, minLng], [maxLat, maxLng]];
};

const toRadians = (degrees) => degrees * Math.PI / 180;
const toDegrees = (radians) => radians * 180 / Math.PI;

const prepareLayerCommon = async (geometryData, endpoint) => {

    const params = {
        where: '1=1',
        outFields: '*',
        geometryData,   // obec polygon
        geometryType: 'esriGeometryPolygon',
        f: 'json',
    };

    try {
        let result = await axios
            .post(`${ADMIN_API_HOST}/gis/${endpoint}`, params)
            .then((response) => response.data);
        return result;
    } catch (e) {
        console.error("GIS ERROR", e);
    }
};

export const prepareLayer = (geometryData) => prepareLayerCommon(geometryData, 'list');
export const prepareLayerPotencialniVyskytDruhuVegetace = (geometryData) => prepareLayerCommon(geometryData, 'potencialniVyskytDruhuVegetace');
export const prepareLayerPokryvUzemiSvazku = (geometryData) => prepareLayerCommon(geometryData, 'krajinnyPokryvUzemiSvazku');
export const prepareLayerklimatickeOblastiNaUzemi = (geometryData) => prepareLayerCommon(geometryData, 'klimatickeOblastiNaUzemi');
export const prepareLayerGeoTeplota400m = (geometryData) => prepareLayerCommon(geometryData, 'geoTeplota400m');
export const prepareLayerBiogasPlants = async (polygon) => {
    
    try {
        let result = await axios
            .post(`${ADMIN_API_HOST}/biogas-plants/get-by-polygon`, polygon)
            .then((response) => response.data);
        return result;
    } catch (e) {
        console.error("BIO GAS PLANT ERROR", e);
    }
}
export const prepareSklonitostTerenuLegend = (geometryData) => prepareLayerCommon(geometryData, 'sklonitostTerenu');
export const prepareOrientaceSvahuNaSvetoveStranyLegend = (geometryData) => prepareLayerCommon(geometryData, 'orientaciaTerenu');
