// src/components/MapComponent.jsx
import { Button, Spin } from 'antd';
import 'leaflet/dist/leaflet.css';
import { useEffect, useRef, useState } from 'react';
import { Map, Polygon, TileLayer } from 'react-leaflet';
import { prepareLayerklimatickeOblastiNaUzemi } from '../../../../utils/GeometryUtils';
import { captureMapImage, fetchMapLayer, setupLeafletDefaults } from '../../../../utils/MapaUtils';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import Legend from '../Legend';
import { getPolygonFromBounds } from '../../../../utils/MapaUtils';
import { getMapBounds } from '../../../../utils/MapaUtils';

setupLeafletDefaults()

const MapaKlimatickeOblastiNaUzemiMutator = ({ name }) => {
    const mapUniqueId = "mapaKlimatickeOblastiNaUzemi" + name ? name : "";

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { position: [49.94297310, 17.03212700], zoom: 13 },
        municipality: data.municipalities[0],
        setField
    }));
    const [fieldValues, setFieldValues] = useState(values)
    const [isActiveMap, setIsActiveMap] = useState(false);
    const [loading, setLoading] = useState(false);
    const [opacity, setOpacity] = useState(0.75);
    const mapRef = useRef();
    const containerRef = useRef();
    const [legendColorMap, setLegendColorMap] = useState({});

    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    }

    const onMoveEnd = () => {
        const map = mapRef.current.leafletElement;
        const newCenter = map.getCenter();
        let tmpFieldValues = { ...fieldValues };
        tmpFieldValues.value.position = [newCenter.lat, newCenter.lng];
        tmpFieldValues.value.zoom = map.getZoom();
        captureMapImage(containerRef, setFieldValues, fieldValues, values, name, false);
    };

    useEffect(() => {
        if (values.municipality.geoBoundingBox && mapRef.current) {
            const map = mapRef.current.leafletElement;
            map.fitBounds(values.municipality.geoBoundingBox);
        }
    }, []);

    useEffect(() => {
        const mapInstance = mapRef.current.leafletElement;
        if (mapInstance && !isActiveMap) {
            mapInstance.dragging.disable();
            mapInstance.touchZoom.disable();
            mapInstance.doubleClickZoom.disable();
            mapInstance.scrollWheelZoom.disable();
            mapInstance.boxZoom.disable();
            mapInstance.keyboard.disable();
        }
    }, [isActiveMap]);

    const enableMapInteractions = () => {
        const mapInstance = mapRef.current.leafletElement;
        if (!isActiveMap && mapInstance) {
            setIsActiveMap(true);
            mapInstance.dragging.enable();
            mapInstance.touchZoom.enable();
            mapInstance.doubleClickZoom.enable();
            mapInstance.scrollWheelZoom.enable();
            mapInstance.boxZoom.enable();
            mapInstance.keyboard.enable();
        }
    };

    const disableMapInteractions = (event) => {
        const mapElement = document.getElementById(mapUniqueId);
        if (!mapElement.contains(event.target)) {
            const mapInstance = mapRef.current.leafletElement;
            if (isActiveMap && mapInstance) {
                setIsActiveMap(false);
                mapInstance.dragging.disable();
                mapInstance.touchZoom.disable();
                mapInstance.doubleClickZoom.disable();
                mapInstance.scrollWheelZoom.disable();
                mapInstance.boxZoom.disable();
                mapInstance.keyboard.disable();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', disableMapInteractions);
        return () => {
            document.removeEventListener('click', disableMapInteractions);
        };
    }, [isActiveMap]);

    const handleOpacityChange = (event) => {
        setOpacity(event.target.value);
    };

    //   Funkcia na získanie dominantnej farby z base64 obrázka
    function getDominantColorFromBase64(base64Image, callback) {
        const img = new Image();
        img.src = 'data:image/png;base64,' + base64Image;
        console.log("img.src", img.src);
        img.crossOrigin = "Anonymous"; // Ak máš obrázky z externých zdrojov
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0, img.width, img.height);

            const imageData = context.getImageData(0, 0, img.width, img.height).data;

            // Získame priemernú farbu
            let r = 0, g = 0, b = 0;
            const totalPixels = img.width * img.height;

            for (let i = 0; i < imageData.length; i += 4) {
                r += imageData[i];
                g += imageData[i + 1];
                b += imageData[i + 2];
            }

            // Priemer RGB hodnôt
            r = Math.floor(r / totalPixels);
            g = Math.floor(g / totalPixels);
            b = Math.floor(b / totalPixels);

            callback(`rgb(${r}, ${g}, ${b})`); // Vrátime farbu v tvare rgb
        };
    }

    // Hlavná funkcia na vytvorenie mapy
    async function createValuesColorMap(layers) {
        const valuesColorMap = {};

        for (const item of layers.legend) {
            await new Promise((resolve) => {
                getDominantColorFromBase64(item.imageData, (color) => {
                    // Pre každú hodnotu v "values" priradíme extrahovanú farbu
                    item.values.forEach(value => {
                        valuesColorMap[value] = color;
                    });
                    console.log("VALUES COLOR MAP", valuesColorMap);
                    resolve(); // Po extrakcii pokračujeme
                });
            });
        }

        return valuesColorMap;
    }

    useEffect(() => {
        async function fetchLegendColorMap() {
            if (fieldValues?.value?.legend) {
                const filteredLegend = fieldValues?.value?.legend.filter(
                    (l) => l.layerId === fieldValues?.value?.geoServer?.layerId
                )[0];

                if (filteredLegend) {
                    const legendColorMap = await createValuesColorMap(filteredLegend);
                    console.log('VALUES COLOR MAP ALL', legendColorMap);
                    setLegendColorMap(legendColorMap); // Set the state with the resulting map
                }
            }
        }
        fetchLegendColorMap(); // Call the async function inside useEffect
    }, [fieldValues?.value?.legend]);

    const handleCaptureMapImage = async () => {
        try {
            const mapContainer = document.getElementById(mapUniqueId);
            if (!mapContainer) {
                console.error("Map container not found");
                return;
            }
    
            mapContainer.classList.add('full-screen');
    
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const mapInstance = mapRef.current?.leafletElement;
            
            if (!mapInstance) {
                console.error("Map instance not found");
                mapContainer.classList.remove('full-screen');
                return;
            }
    
            mapInstance.invalidateSize();
    
            await new Promise((resolve) => setTimeout(resolve, 4000));
            captureMapImage(containerRef, setFieldValues, fieldValues, values, name, true);
    
            await new Promise((resolve) => setTimeout(resolve, 2000));
            mapContainer.classList.remove('full-screen');
        } catch (error) {
            console.error("Error during map capture:", error);
        }
    };

    return (
        <div className='open-street-map'>
            <div ref={containerRef} className={isActiveMap ? 'open-street-map is-active-map' : 'open-street-map'}>
                <div className='map-title-buttons-container'>
                    <h3>Mapa klimatické oblasti na území</h3>
                    <div>
                        <Button
                            className='addButton'
                            style={{ marginRight: 20 }}
                            onClick={async () => {
                                setLoading(true);
                                try {
                                    await fetchMapLayer(prepareLayerklimatickeOblastiNaUzemi, getPolygonFromBounds(getMapBounds(mapRef)), setFieldValues, fieldValues, containerRef, values, name);
                                } catch (error) {
                                    console.error('Error fetching map layer:', error);
                                } finally {
                                    setLoading(false);
                                }
                            }}
                        >
                            {loading ? 'Načítavam údaje...' : 'Stiahnúť údaje'}
                        </Button>
                        <Button className='addButton' onClick={handleCaptureMapImage}>Vložiť do náhľadu</Button>
                        {loading && <Spin className='spin-loading-map' />}
                    </div>
                </div>
                <Map
                    onblur={handleChange}
                    center={values?.municipality?.geoCenter}
                    zoom={fieldValues.value.zoom}
                    ref={mapRef}
                    onMoveEnd={onMoveEnd}
                    onZoomEnd={onMoveEnd}
                    id={mapUniqueId}
                    onClick={enableMapInteractions}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    >
                    </TileLayer>
                    <Polygon key={mapUniqueId + "poly"} positions={values?.municipality?.geoJson} opacity={opacity} />
                    {fieldValues?.value?.geoLayer && legendColorMap && (
                        fieldValues?.value?.geoLayer.map((layer, idx) => {
                            return (
                                <Polygon
                                    key={mapUniqueId + "poly" + idx}
                                    color={legendColorMap[String(layer.attributes.KOD_KLO)]}
                                    positions={[layer.geometry]}
                                    stroke={false} // Disable the border (stroke)
                                    fillOpacity={opacity} // Optional: control fill opacity if needed
                                />
                            )
                        })
                    )}
                </Map>
                <Legend
                    legendData={fieldValues?.value?.legend}
                    layerId={fieldValues?.value?.geoServer?.layerId}
                    opacity={opacity}
                />
                <div className='opacity-slider-map'>
                    <span className='opacity-slider-controller'>
                        <label>Viditeľnosť vrstiev</label>
                        <input
                            type="range"
                            id="opacity"
                            name="opacity"
                            min="0"
                            max="1"
                            step="0.02"
                            value={opacity}
                            onChange={handleOpacityChange}
                        />
                    </span>
                    <span><strong>Zdroj:</strong> AOPK-vlastní zpracování</span>
                </div>
            </div>
        </div>
    );
};

function MapaKlimatickeOblastiNaUzemiRenderer(values, key) {
    let returnValue = ""
    if (values[key]) {
        returnValue += `<img src="${values[key].image}" alt="Map view" style="height: 100%; width: 100%; object-fit: cover;" />`;
    }

    return returnValue
}

function MapaKlimatickeOblastiNaUzemiValidator() {
    return true;
}

export default {
    Mutator: MapaKlimatickeOblastiNaUzemiMutator,
    HTMLRenderer: MapaKlimatickeOblastiNaUzemiRenderer,
    Validator: MapaKlimatickeOblastiNaUzemiValidator
};
