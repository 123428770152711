import { Button } from 'antd';
import L from 'leaflet'; // Leaflet import
import 'proj4leaflet'; // Make sure proj4leaflet is imported after Leaflet
import { useEffect, useRef, useState } from 'react';
import { ImageOverlay, Map, Marker } from 'react-leaflet';
import pointImage from '../../../../images/point.png';
import { getRestepImagePotencialVeternejEnergieUrl, getRestepPodkladTileUrl, setupLeafletDefaults } from '../../../../utils/MapaImageUtils';
import { captureMapImage, getMapBounds } from '../../../../utils/MapaUtils';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import LegendCustom from '../LegendCustom';

setupLeafletDefaults();

const geoUrl = 'https://restep.vumop.cz/cgi-bin'

const legendData = {
    "layers": [
        {
            "label": "do 2 MW",
            "color": "#D9EEF4"
        },
        {
            "label": "2 - 4 MW",
            "color": "#A7C9E3"
        },
        {
            "label": "4 - 6 MW",
            "color": "#7DA4CB"
        },
        {
            "label": "6 - 12 MW",
            "color": "#6576A6"
        },
        {
            "label": "12 - 20 MW",
            "color": "#7D4C9D"
        },
        {
            "label": "20 - 50 MW",
            "color": "#69317C"
        },
        {
            "label": "nad 50 MW",
            "color": "#4F004F"
        }
    ]
};

const customIcon = new L.Icon({
    iconUrl: pointImage, // Add your image URL here
    iconSize: [40, 40], // size of the icon
    iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -40], // point from which the popup should open relative to the iconAnchor
});

// const krovakCRS = new L.Proj.CRS(
//     "EPSG:5514",
//     "+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=498.0,-36.0,575.0,0.0,0.0,0.0,0.0 +units=m +no_defs",
//     {
//         origin: [-950000, -1265000],
//         resolutions: [
//             8192, 4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5,
//         ],
//     }
// );

const MapaPotencialVeternejEnergieMutator = ({ name }) => {
    const mapUniqueId = "mapaPotencialVeternejEnergie" + name ? name : "";

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { position: [49.94297310, 17.03212700], zoom: 13 },
        municipality: data.municipalities[0],
        setField
    }));

    const [fieldValues, setFieldValues] = useState(values);
    const [isActiveMap, setIsActiveMap] = useState(false);
    const [opacity, setOpacity] = useState(0.75);
    const mapRef = useRef();
    const containerRef = useRef();
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const handleResize = () => {
            if (mapRef.current) {
                // Use getBoundingClientRect() to get the size of the container
                const mapContainer = mapRef.current.container; // Získanie priamo DOM elementu, kde je mapa
                const { width, height } = mapContainer.getBoundingClientRect(); // Získanie rozmerov mapy
                setContainerSize({ width: Math.ceil(width), height: Math.ceil(height) });

            }
        };

        window.addEventListener('resize', handleResize);
        handleResize()
        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onMoveEnd = () => {
        const map = mapRef.current.leafletElement;
        const newCenter = map.getCenter();
        let tmpFieldValues = { ...fieldValues };
        tmpFieldValues.value.position = [newCenter.lat, newCenter.lng];
        tmpFieldValues.value.zoom = map.getZoom();
        captureMapImage(containerRef, setFieldValues, fieldValues, values, name, false);
    };

    useEffect(() => {
        const mapInstance = mapRef.current.leafletElement;
        if (mapInstance && !isActiveMap) {
            mapInstance.dragging.disable();
            mapInstance.touchZoom.disable();
            mapInstance.doubleClickZoom.disable();
            mapInstance.scrollWheelZoom.disable();
            mapInstance.boxZoom.disable();
            mapInstance.keyboard.disable();
        }
    }, [isActiveMap]);

    const enableMapInteractions = () => {
        const mapInstance = mapRef.current.leafletElement;
        if (!isActiveMap && mapInstance) {
            setIsActiveMap(true);
            mapInstance.dragging.enable();
            mapInstance.touchZoom.enable();
            mapInstance.doubleClickZoom.enable();
            mapInstance.scrollWheelZoom.enable();
            mapInstance.boxZoom.enable();
            mapInstance.keyboard.enable();
        }
    };

    const disableMapInteractions = (event) => {
        const mapElement = document.getElementById(mapUniqueId);
        if (!mapElement.contains(event.target)) {
            const mapInstance = mapRef.current.leafletElement;
            if (isActiveMap && mapInstance) {
                setIsActiveMap(false);
                mapInstance.dragging.disable();
                mapInstance.touchZoom.disable();
                mapInstance.doubleClickZoom.disable();
                mapInstance.scrollWheelZoom.disable();
                mapInstance.boxZoom.disable();
                mapInstance.keyboard.disable();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', disableMapInteractions);

        return () => {
            document.removeEventListener('click', disableMapInteractions);
        };
    }, [isActiveMap]);

    const handleOpacityChange = (event) => {
        setOpacity(event.target.value);
    };

    const handleCaptureMapImage = async () => {
        try {
            const mapContainer = document.getElementById(mapUniqueId);
            if (!mapContainer) {
                console.error("Map container not found");
                return;
            }
    
            mapContainer.classList.add('full-screen');
    
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const mapInstance = mapRef.current?.leafletElement;
            
            if (!mapInstance) {
                console.error("Map instance not found");
                mapContainer.classList.remove('full-screen');
                return;
            }
    
            mapInstance.invalidateSize();
    
            await new Promise((resolve) => setTimeout(resolve, 4000));
            captureMapImage(containerRef, setFieldValues, fieldValues, values, name, true);
    
            await new Promise((resolve) => setTimeout(resolve, 2000));
            mapContainer.classList.remove('full-screen');
        } catch (error) {
            console.error("Error during map capture:", error);
        }
    };

    return (
        containerSize &&
        <div className='open-street-map'>
            <div ref={containerRef} className={isActiveMap ? 'open-street-map is-active-map' : 'open-street-map'}>
                <div className='map-title-buttons-container'>
                    <h3>Mapa Potenciál větrné energie podle ÚFA</h3>
                    <Button className='addButton' onClick={handleCaptureMapImage}>Vložiť do náhľadu</Button>
                </div>
                <Map
                    //  crs={krovakCRS}  
                    center={values?.municipality?.geoCenter}
                    zoom={fieldValues.value.zoom}
                    ref={mapRef}
                    onMoveEnd={onMoveEnd}
                    onZoomEnd={onMoveEnd}
                    id={mapUniqueId}
                    onClick={enableMapInteractions}
                >
                    <ImageOverlay
                        url={getRestepPodkladTileUrl(geoUrl, getMapBounds(mapRef), containerSize)}
                        bounds={mapRef.current ? mapRef.current.leafletElement.getBounds() : [[0, 0], [0, 0]]}
                    />

                    {/* <Polygon
                        key={mapUniqueId + "poly"}
                        positions={values?.municipality?.geoJson}
                        pathOptions={{
                            color: '#6666FF',  // Farba čiary
                            fill: true,       // Odstránenie výplne
                            fillOpacity: .3,    // Nastavenie priehľadnosti výplne na 0
                            opacity: 0.8,      // Priehľadnosť obrysu
                            weight: 2          // Hrúbka obrysu
                        }} /> */}
                    <ImageOverlay
                        url={getRestepImagePotencialVeternejEnergieUrl(geoUrl, getMapBounds(mapRef), containerSize)}
                        bounds={mapRef.current ? mapRef.current.leafletElement.getBounds() : [[0, 0], [0, 0]]}
                        opacity={opacity}
                    />
                    {values?.municipality?.geoCenter && (
                        <Marker position={values?.municipality?.geoCenter} icon={customIcon} />
                    )}
                </Map>
                {/* TODO: LEGENDA
                 https://restep.vumop.cz/?core=lokalizace
                 VITR -> Potencial vetrne energie podle UFA */}
                <LegendCustom opacity={opacity} legendData={legendData} />
                <div className='opacity-slider-map'>
                    <span className='opacity-slider-controller'>
                        <label>Viditeľnosť vrstiev</label>
                        <input
                            type="range"
                            id="opacity"
                            name="opacity"
                            min="0"
                            max="1"
                            step="0.02"
                            value={opacity}
                            onChange={handleOpacityChange}
                        />
                    </span>
                    {geoUrl &&
                        <span><strong>Zdroj:</strong> RESTEP-vlastní zpracování</span>
                    }
                </div>
            </div>
        </div>
    );
};

function MapaPotencialVeternejEnergieRenderer(values, key) {
    let returnValue = ""
    if (values[key]) {
        returnValue += `<img src="${values[key].image}" alt="Map view" style="height: 100%; width: 100%; object-fit: cover" />`;
    }
    return returnValue
}

function MapaPotencialVeternejEnergieValidator() {
    return true;
}

export default {
    Mutator: MapaPotencialVeternejEnergieMutator,
    HTMLRenderer: MapaPotencialVeternejEnergieRenderer,
    Validator: MapaPotencialVeternejEnergieValidator
};
