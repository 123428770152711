import { Button, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import 'react-phone-number-input/style.css';
import { connect } from 'react-redux';
import { Form, Popup, Icon } from 'semantic-ui-react';
import { validatePassword } from '../../../utils/string';
import { LoginFormState } from '../../admin/pages/Interfaces';
import PasswordStrengthBar from 'react-password-strength-bar';
import { translations } from '../../../utils/LocalizedStrings';

const NewPasswordForm = ({
  setFormState,
  setIsLoading,
  email,
  returnedUser,
  setReturnedUser,
  handleClose,
  setVerificationMethod
}) => {
  const [formData, setFormData] = useState({});

  const handleField = (e) => {
    e.persist();
    setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const validationError = validateData(formData);
      if (validationError) {
        message.error(validationError);
        setIsLoading(false);
        return;
      }

      const user = await Auth.completeNewPassword(returnedUser, formData.pass1);
      if (user.challengeName === "SMS_MFA") {
        setFormState(LoginFormState.SMS_MFA);
        setReturnedUser(user);
        message.info("Overovacia SMS bola odoslaná");
      } else if (user.challengeName === "SOFTWARE_TOKEN_MFA" || user.challengeName === "SELECT_MFA_TYPE") {
        setFormState(LoginFormState.SOFTWARE_TOKEN_MFA);
        setReturnedUser(user);
        let mfaType = "SOFTWARE_TOKEN_MFA";
        setVerificationMethod(mfaType);
        user.sendMFASelectionAnswer(mfaType, {
          onFailure: (err) => {
            console.error(err);
          },
          mfaRequired: (challengeName, parameters) => {
            console.log(challengeName);
          },
          totpRequired: (challengeName, parameters) => {
            console.log(challengeName);
          },
        });
      } else {
        setFormState(LoginFormState.LOGGED_IN);
        if (handleClose) {
          handleClose();
        }
      }
    } catch (error) {
      console.log(error);
      message.error("Nastavenie nového hesla zlyhalo.");
    }
    setIsLoading(false);
  };

  const validateData = (formData) => {
    let messages = '';

    if (!formData.pass1 || formData.pass1 !== formData.pass2) {
      messages += "Heslá sa nezhodujú. ";
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    if (!passwordRegex.test(formData.pass1)) {
      messages += "Heslo musí obsahovať aspoň jedno veľké a malé písmeno, jednu číslicu a jeden špeciálny znak, a musí mať minimálne 8 znakov. ";
    }

    return messages;
  };

  return (
    <main id="login" className="landingPage forgotPasswordForm">
      <div className="container">
        <div className="main-login-container">
          <span
            className="stepBackArrow"
            onClick={() => setFormState(LoginFormState.FORGOT_PASSWORD)}
          >
            <Popup
              className="stepBackArrowPopup"
              content="Krok späť"
              trigger={<Icon name="arrow left" />}
            />
          </span>
          <header className="loginHeader">
            {translations.artifacts.PasswordResetFormBox.resetPassword}
          </header>
          <Form onSubmit={handleSubmit} className="orderUiForm">
            <Form.Field className="contactField nameSurmanePhone">
              <Form.Input
                label={translations.artifacts.PasswordResetFormBox.password}
                name="pass1"
                value={formData.pass1}
                onChange={handleField}
                required
                type="password"
              />
              {formData.pass1 && formData.pass1.length > 0 && (
                <PasswordStrengthBar
                  scoreWords={[
                    translations.artifacts.UserRegistrationBox.passwordTooWeak,
                    translations.artifacts.UserRegistrationBox.passwordWeak,
                    translations.artifacts.UserRegistrationBox.passwordGood,
                    translations.artifacts.UserRegistrationBox.passwordStrong,
                    translations.artifacts.UserRegistrationBox.passwordTooStrong,
                  ]}
                  shortScoreWord={
                    translations.artifacts.UserRegistrationBox.passwordShort
                  }
                  className="passwordStrengthBar"
                  password={formData.pass1}
                />
              )}
              <Form.Input
                className="repeatPassword"
                label={translations.artifacts.PasswordResetFormBox.repeatPassword}
                name="pass2"
                value={formData.pass2}
                onChange={handleField}
                required
                type="password"
                error={
                  formData.pass1 === formData.pass2
                    ? false
                    : {
                        content:
                          translations.artifacts.PasswordResetFormBox
                            .passwordArentSame,
                      }
                }
              />
            </Form.Field>
            <Form.Field className="contactField nameSurmanePhone registerButton">
              <Button
                className="ui primary big button"
                type="submit"
                onClick={handleSubmit}
              >
                {translations.artifacts.PasswordResetFormBox.resetPassword}
              </Button>
            </Form.Field>
          </Form>
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = ({ appState }) => ({
  company: appState.company
});

export default connect(mapStateToProps)(NewPasswordForm);
