import { useEffect, useState } from 'react';

const Legend = ({ legendData, layerId, title, opacity }) => {
  const [openLegend, setOpenLegend] = useState(false);

  const toogleLegend = () => {
    setOpenLegend(!openLegend);
  };

  return (legendData && (
    <div className="legend-container">
      <div className='title-icon-container'>
        {legendData && legendData.length > 0 &&
          <span onClick={toogleLegend}>Legenda</span>
        }
      </div>

      {openLegend &&
        <>
          {
            legendData.filter(layer => layer.layerId === layerId).map((layer, index) => (
              <div key={layer.layerId} className="legend-layer">
                <div className="legend-layer-title">{layer.layerName}</div>
                {layer.legend.map((item, index) => (
                  <div key={index} className="legend-item">
                    <img
                      className="legend-swatch"
                      src={`data:image/png;base64,${item.imageData}`}
                      alt={item.label}
                      style={{ opacity: opacity }}
                    />
                    <div className="legend-label">{item.label}</div>
                  </div>
                ))}
              </div>
            ))
          }
        </>
      }
    </div>
  ) || <></>)
}

export default Legend;