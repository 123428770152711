
export interface Filter {
    name: string,
    type: FilterType,
    translation: string,
    value: any,
    values?: any[],
    selected: boolean
}

export enum FilterType {
    DATE_TIME = "DATE_TIME",
    STRING = "STRING",
    SELECT = "SELECT"
}

export enum UploadsStatus {
    CONFIRMED = "CONFIRMED",
    REJECTED = "REJECTED",
    NULL = "NULL"
}

// do not forget to updte OrderStateSteper and OrderStateTextSteper
export enum OrderState {
    VYTVORENE = "VYTVORENE",
    DORUCENE = "DORUCENE",
    PODANE_URAD = "PODANE_URAD",
    URGOVANE_URAD = "URGOVANE_URAD",
    PODANE_SUD = "PODANE_SUD",
    URGOVANE_SUD = "URGOVANE_SUD",
    ZAPISANE_URAD = "ZAPISANE_URAD",
    ZAPISANE_SUD = "ZAPISANE_SUD"
}

// here we get index to stepper text for given OrderState
export enum OrderStateSteper {
    VYTVORENE = "0",
    DORUCENE = "1",
    PODANE_URAD = "2",
    URGOVANE_URAD = "2",
    PODANE_SUD = "4",
    URGOVANE_SUD = "4",
    ZAPISANE_URAD = "3",
    ZAPISANE_SUD = "5"
}
export const OrderStateText = {
    VYTVORENE: "Vytvorená",
    DORUCENE: "Doručená",
    PODANE_URAD: "Podaná úrad",
    URGOVANE_URAD: "Urgovaná úrad",
    PODANE_SUD: "Podaná súd",
    URGOVANE_SUD: "Urgovaná súd",
    ZAPISANE_URAD: "Zapísaná úrad",
    ZAPISANE_SUD: "Zapísaná súd"
}
export const OrderStateTextSteper = [
    "Vytvorená",
    "Doručená",
    "Podaná úrad",
    "Zapísaná úrad",
    "Podaná súd",
    "Zapísaná súd"
]

export enum RegistroveSudy {
    BANSKA_BYSTRICA = "BANSKA_BYSTRICA",
    BRATISLAVA_I = "BRATISLAVA_I",
    KOSICE_I = "KOSICE_I",
    NITRA = "NITRA",
    PRESOV = "PRESOV",
    TRENCIN = "TRENCIN",
    TRNAVA = "TRNAVA",
    ZILINA = "ZILINA"
}

export const RegistroveSudyText = {
    BANSKA_BYSTRICA: "Okresný súd Banská Bystrica",
    BRATISLAVA_I: "Mestský súd Bratislava III",
    KOSICE_I: "Mestský súd Košice",
    NITRA: "Okresný súd Nitra",
    PRESOV: "Okresný súd Prešov",
    TRENCIN: "Okresný súd Trenčín",
    TRNAVA: "Okresný súd Trnava",
    ZILINA: "Okresný súd Žilina"
}

export enum PodatelneSudy {
    PODATELNA_BANSKA_BYSTRICA = "podatelnaOSBB@justice.sk1",
    PODATELNA_BRATISLAVA_I = "podatelnaosba1@justice.sk1",
    PODATELNA_KOSICE_I = "podatelnaOSKE1@justice.sk1",
    PODATELNA_NITRA = "podatelnaosnr@justice.sk1",
    PODATELNA_PRESOV = "podatelnaospo@justice.sk1",
    PODATELNA_TRENCIN = "PodatelnaOSTN@justice.sk1",
    PODATELNA_TRNAVA = "podatelnaOSTT@justice.sk1",
    PODATELNA_ZILINA = "podatelnaOSZA@justice.sk1"
}

export enum FakePodatelneSudy {
    PODATELNA_BANSKA_BYSTRICA = "orsr-test@maildrop.cc",
    PODATELNA_BRATISLAVA_I = "orsr-test@maildrop.cc",
    PODATELNA_KOSICE_I = "orsr-test@maildrop.cc",
    PODATELNA_NITRA = "orsr-test@maildrop.cc",
    PODATELNA_PRESOV = "orsr-test@maildrop.cc",
    PODATELNA_TRENCIN = "orsr-test@maildrop.cc",
    PODATELNA_TRNAVA = "orsr-test@maildrop.cc",
    PODATELNA_ZILINA = "orsr-test@maildrop.cc"
}

export const prepareSud2Podatelna = () => {
    const tmpMap = new Map();
    tmpMap.set(RegistroveSudy.BANSKA_BYSTRICA, PodatelneSudy.PODATELNA_BANSKA_BYSTRICA);
    tmpMap.set(RegistroveSudy.BRATISLAVA_I, PodatelneSudy.PODATELNA_BRATISLAVA_I);
    tmpMap.set(RegistroveSudy.KOSICE_I, PodatelneSudy.PODATELNA_KOSICE_I);
    tmpMap.set(RegistroveSudy.NITRA, PodatelneSudy.PODATELNA_NITRA);
    tmpMap.set(RegistroveSudy.PRESOV, PodatelneSudy.PODATELNA_PRESOV);
    tmpMap.set(RegistroveSudy.TRENCIN, PodatelneSudy.PODATELNA_TRENCIN);
    tmpMap.set(RegistroveSudy.TRNAVA, PodatelneSudy.PODATELNA_TRNAVA);
    tmpMap.set(RegistroveSudy.ZILINA, PodatelneSudy.PODATELNA_ZILINA);
    return tmpMap;
}

export const prepareFakeSud2Podatelna = () => {
    const tmpMap = new Map();
    tmpMap.set(RegistroveSudy.BANSKA_BYSTRICA, FakePodatelneSudy.PODATELNA_BANSKA_BYSTRICA);
    tmpMap.set(RegistroveSudy.BRATISLAVA_I, FakePodatelneSudy.PODATELNA_BRATISLAVA_I);
    tmpMap.set(RegistroveSudy.KOSICE_I, FakePodatelneSudy.PODATELNA_KOSICE_I);
    tmpMap.set(RegistroveSudy.NITRA, FakePodatelneSudy.PODATELNA_NITRA);
    tmpMap.set(RegistroveSudy.PRESOV, FakePodatelneSudy.PODATELNA_PRESOV);
    tmpMap.set(RegistroveSudy.TRENCIN, FakePodatelneSudy.PODATELNA_TRENCIN);
    tmpMap.set(RegistroveSudy.TRNAVA, FakePodatelneSudy.PODATELNA_TRNAVA);
    tmpMap.set(RegistroveSudy.ZILINA, FakePodatelneSudy.PODATELNA_ZILINA);
    return tmpMap;
}

export enum ZivnostenskeUrady {
    BRATISLAVA_BRATISLAVA = "BRATISLAVA_BRATISLAVA",
    BRATISLAVA_MALACKY = "BRATISLAVA_MALACKY",
    BRATISLAVA_PEZINOK = "BRATISLAVA_PEZINOK",
    BRATISLAVA_SENEC = "BRATISLAVA_SENEC",
    TRNAVA_DUNAJSKA_STREDA = "TRNAVA_DUNAJSKA_STREDA",
    TRNAVA_GALANTA = "TRNAVA_GALANTA",
    TRNAVA_PIESTANY = "TRNAVA_PIESTANY",
    TRNAVA_SENICA = "TRNAVA_SENICA",
    TRNAVA_TRNAVA = "TRNAVA_TRNAVA",
    TRENCIN_BANOVCE_NAD_BEBRAVOU = "TRENCIN_BANOVCE_NAD_BEBRAVOU",
    TRENCIN_NOVE_MESTO_NAD_VAHOM = "TRENCIN_NOVE_MESTO_NAD_VAHOM",
    TRENCIN_POVAZSKA_BYSTRICA = "TRENCIN_POVAZSKA_BYSTRICA",
    TRENCIN_PRIEVIDZA = "TRENCIN_PRIEVIDZA",
    TRENCIN_TRENCIN = "TRENCIN_TRENCIN",
    NITRA_KOMARNO = "NITRA_KOMARNO",
    NITRA_LEVICE = "NITRA_LEVICE",
    NITRA_NITRA = "NITRA_NITRA",
    NITRA_NOVE_ZAMKY = "NITRA_NOVE_ZAMKY",
    NITRA_SALA = "NITRA_SALA",
    NITRA_TOPOLCANY = "NITRA_TOPOLCANY",
    ZILINA_CADCA = "ZILINA_CADCA",
    ZILINA_DOLNY_KUBIN = "ZILINA_DOLNY_KUBIN",
    ZILINA_LIPTOVSKY_MIKULAS = "ZILINA_LIPTOVSKY_MIKULAS",
    ZILINA_MARTIN = "ZILINA_MARTIN",
    ZILINA_NAMESTOVO = "ZILINA_NAMESTOVO",
    ZILINA_RUZOMBEROK = "ZILINA_RUZOMBEROK",
    ZILINA_ZILINA = "ZILINA_ZILINA",
    BANSKA_BYSTRICA_BANSKA_BYSTRICA = "BANSKA_BYSTRICA_BANSKA_BYSTRICA",
    BANSKA_BYSTRICA_BREZNO = "BANSKA_BYSTRICA_BREZNO",
    BANSKA_BYSTRICA_LUCENEC = "BANSKA_BYSTRICA_LUCENEC",
    BANSKA_BYSTRICA_RIMAVSKA_SOBOTA = "BANSKA_BYSTRICA_RIMAVSKA_SOBOTA",
    BANSKA_BYSTRICA_VELKY_KRTIS = "BANSKA_BYSTRICA_VELKY_KRTIS",
    BANSKA_BYSTRICA_ZVOLEN = "BANSKA_BYSTRICA_ZVOLEN",
    BANSKA_BYSTRICA_ZIAR_NAD_HRONOM = "BANSKA_BYSTRICA_ZIAR_NAD_HRONOM",
    PRESOV_BARDEJOV = "PRESOV_BARDEJOV",
    PRESOV_HUMENNE = "PRESOV_HUMENNE",
    PRESOV_KEZMAROK = "PRESOV_KEZMAROK",
    PRESOV_POPRAD = "PRESOV_POPRAD",
    PRESOV_PRESOV = "PRESOV_PRESOV",
    PRESOV_STARA_LUBOVNA = "PRESOV_STARA_LUBOVNA",
    PRESOV_STROPKOV = "PRESOV_STROPKOV",
    PRESOV_SVIDNIK = "PRESOV_SVIDNIK",
    PRESOV_VRANOV_NAD_TOPLOU = "PRESOV_VRANOV_NAD_TOPLOU",
    KOSICE_KOSICE = "KOSICE_KOSICE",
    KOSICE_KOSICE_OKOLIE = "KOSICE_KOSICE_OKOLIE",
    KOSICE_MICHALOVCE = "KOSICE_MICHALOVCE",
    KOSICE_ROZNAVA = "KOSICE_ROZNAVA",
    KOSICE_SPISSKA_NOVA_VES = "KOSICE_SPISSKA_NOVA_VES",
    KOSICE_TREBISOV = "KOSICE_TREBISOV"
}

export const ZivnostenskeUradyText = {
    BRATISLAVA_BRATISLAVA: "Okresný úrad Bratislava, odbor živnostenského podnikania",
    BRATISLAVA_MALACKY: "Okresný úrad Malacky, odbor živnostenského podnikania",
    BRATISLAVA_PEZINOK: "Okresný úrad Pezinok, odbor živnostenského podnikania",
    BRATISLAVA_SENEC: "Okresný úrad Senec, odbor živnostenského podnikania",
    TRNAVA_DUNAJSKA_STREDA: "Okresný úrad Dunajská Streda, odbor živnostenského podnikania",
    TRNAVA_GALANTA: "Okresný úrad Galanta, odbor živnostenského podnikania",
    TRNAVA_PIESTANY: "Okresný úrad Piešťany, odbor živnostenského podnikania",
    TRNAVA_SENICA: "Okresný úrad Senica, odbor živnostenského podnikania",
    TRNAVA_TRNAVA: "Okresný úrad Trnava, odbor živnostenského podnikania",
    TRENCIN_BANOVCE_NAD_BEBRAVOU: "Okresný úrad Bánovce nad Bebravou, odbor živnostenského podnikania",
    TRENCIN_NOVE_MESTO_NAD_VAHOM: "Okresný úrad Nové Mesto nad Váhom, odbor živnostenského podnikania",
    TRENCIN_POVAZSKA_BYSTRICA: "Okresný úrad Považská Bystrica, odbor živnostenského podnikania",
    TRENCIN_PRIEVIDZA: "Okresný úrad Prievidza, odbor živnostenského podnikania",
    TRENCIN_TRENCIN: "Okresný úrad Trenčín, odbor živnostenského podnikania",
    NITRA_KOMARNO: "Okresný úrad Komárno, odbor živnostenského podnikania",
    NITRA_LEVICE: "Okresný úrad Levice, odbor živnostenského podnikania",
    NITRA_NITRA: "Okresný úrad Nitra, odbor živnostenského podnikania",
    NITRA_NOVE_ZAMKY: "Okresný úrad Nové Zámky, odbor živnostenského podnikania",
    NITRA_SALA: "Okresný úrad Šaľa, odbor živnostenského podnikania",
    NITRA_TOPOLCANY: "Okresný úrad Topoľčany, odbor živnostenského podnikania",
    ZILINA_CADCA: "Okresný úrad Čadca, odbor živnostenského podnikania",
    ZILINA_DOLNY_KUBIN: "Okresný úrad Dolný Kubín, odbor živnostenského podnikania",
    ZILINA_LIPTOVSKY_MIKULAS: "Okresný úrad Liptovský Mikuláš, odbor živnostenského podnikania",
    ZILINA_MARTIN: "Okresný úrad Martin, odbor živnostenského podnikania",
    ZILINA_NAMESTOVO: "Okresný úrad Námestovo, odbor živnostenského podnikania",
    ZILINA_RUZOMBEROK: "Okresný úrad Ružomberok, odbor živnostenského podnikania",
    ZILINA_ZILINA: "Okresný úrad Žilina, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_BANSKA_BYSTRICA: "Okresný úrad Banská Bystrica, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_BREZNO: "Okresný úrad Brezno, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_LUCENEC: "Okresný úrad Lučenec, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_RIMAVSKA_SOBOTA: "Okresný úrad Rimavská Sobota, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_VELKY_KRTIS: "Okresný úrad Veľký Krtíš, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_ZVOLEN: "Okresný úrad Zvolen, odbor živnostenského podnikania",
    BANSKA_BYSTRICA_ZIAR_NAD_HRONOM: "Okresný úrad Žiar nad Hronom, odbor živnostenského podnikania",
    PRESOV_BARDEJOV: "Okresný úrad Bardejov, odbor živnostenského podnikania",
    PRESOV_HUMENNE: "Okresný úrad Humenné, odbor živnostenského podnikania",
    PRESOV_KEZMAROK: "Okresný úrad Kežmarok, odbor živnostenského podnikania",
    PRESOV_POPRAD: "Okresný úrad Poprad, odbor živnostenského podnikania",
    PRESOV_PRESOV: "Okresný úrad Prešov, odbor živnostenského podnikania",
    PRESOV_STARA_LUBOVNA: "Okresný úrad Stará Ľubovňa, odbor živnostenského podnikania",
    PRESOV_STROPKOV: "Okresný úrad Stropkov, odbor živnostenského podnikania",
    PRESOV_SVIDNIK: "Okresný úrad Svidník, odbor živnostenského podnikania",
    PRESOV_VRANOV_NAD_TOPLOU: "Okresný úrad Vranov nad Topľou, odbor živnostenského podnikania",
    KOSICE_KOSICE: "Okresný úrad Košice, odbor živnostenského podnikania",
    KOSICE_KOSICE_OKOLIE: "Okresný úrad Košice-okolie, odbor živnostenského podnikania",
    KOSICE_MICHALOVCE: "Okresný úrad Michalovce, odbor živnostenského podnikania",
    KOSICE_ROZNAVA: "Okresný úrad Rožňava, odbor živnostenského podnikania",
    KOSICE_SPISSKA_NOVA_VES: "Okresný úrad Spišská Nová Ves, odbor živnostenského podnikania",
    KOSICE_TREBISOV: "Okresný úrad Trebišov, odbor živnostenského podnikania"
}


export enum Codes {
    Stat = "Stat",
    PravnaForma = "PravnaForma",
    FunkciaClenaStatutarnehoOrganu = "FunkciaClenaStatutarnehoOrganu",
    TypInyIdentifikator = "TypInyIdentifikator",
    Mena = "Mena",
    TypVkladu = "TypVkladu",
    Obec = "Obec",
    ObchodnyRegister = "ObchodnyRegister"
}

export const CodesText = {
    Stat: "Stat",
    PravnaForma: "Pravna forma",
    FunkciaClenaStatutarnehoOrganu: "FunkciaClenaStatutarnehoOrganu",
    TypInyIdentifikator: "TypInyIdentifikator",
    Mena: "Mena",
    TypVkladu: "TypVkladu",
    Obec: "Obec",
    ObchodnyRegister: "ObchodnyRegister"
}

export interface StatCode {
    codeId: number;
    value: string;
}

export interface PravnaFormaCode {
    codeId: number;
    value: string;
}

export interface FunkciaClenaStatutarnehoOrganuCode {
    codeId: number;
    value: string;
}

export interface TypInyIdentifikatorCode {
    codeId: number;
    value: string;
    znacka: string;
}

export interface MenaCode {
    codeId: number;
    value: string;
    znacka: string;
}

export interface TypVkladuCode {
    codeId: number;
    value: string;
}

export interface ObecCode {
    codeId: number;
    value: string;
    statId: number;
    obce: string[];

}

export interface ObchodnyRegisterCode {
    // codeId is id in FUZS xml
    codeId: number;
    okresnySud: string;
    ulica: string;
    cislo: string;
    obec: string;
    psc: string;
    kod: string;
}

export enum UserRoles {
    ADMIN = 'admin',
    USER = 'user',
    EDITOR = 'editor'
}

export enum LoginFormState {
    LOGIN,
    REGISTER,
    USER_NOT_VERIFIED,
    SOFTWARE_TOKEN_MFA,
    SMS_MFA,
    LOGGED_IN,
    FORGOT_PASSWORD,
    PASSWORD_RESET,
    NEW_PASSWORD_REQUIRED
}

export enum PartnerType {
    PARTNER_LAWYER = "PARTNER_LAWYER",
    PARTNER_ACCOUNTANT = "PARTNER_ACCOUNTANT",
    PARTNER_NOTARY = "PARTNER_NOTARY"
}

// person name

export type SeparatedName = {
    titlesBefore: string,
    name: string,
    surname: string
    titlesAfter: string
}


export enum TypInyIdentifikatorEnum {
    RODNE_CISLO = "rodné číslo",
    PREUKAZ_TOTOZNOSTI = "preukaz totožnosti",
    CESTOVNY_DOKLAD = "cestovný doklad",
    DOKLAD_O_POBYTE_CUDZINCA = "doklad o pobyte cudzinca",
    INY_IDENTIFIKACNY_UDAJ = "iný identifikačný údaj"
}

export enum TypInyIdentifikatorTabsEnum {
    RODNE_CISLO = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;",
    PREUKAZ_TOTOZNOSTI = "&#9;&#9;&#9;&#9;&#9;",
    CESTOVNY_DOKLAD = "&#9;&#9;&#9;&#9;&#9;&#9;",
    DOKLAD_O_POBYTE_CUDZINCA = "&#9;&#9;",
    INY_IDENTIFIKACNY_UDAJ = "&#9;&#9;&#9;&#9;"
}

export enum TypInyIdentifikatorEstablishmentTabsEnum {
    RODNE_CISLO = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;",
    PREUKAZ_TOTOZNOSTI = "&#9;&#9;&#9;&#9;&#9;",
    CESTOVNY_DOKLAD = "&#9;&#9;&#9;&#9;&#9;&#9;",
    DOKLAD_O_POBYTE_CUDZINCA = "&#9;&#9;",
    INY_IDENTIFIKACNY_UDAJ = "&#9;&#9;&#9;&#9;"
}



export enum PaidStatus {
    'paid' = 'Vygenerované',
    'unpaid' = 'Rozpracovaná',
}

// while updating this enum, update also ORSRFilePrefixes in generation api
export enum ORSRFileTypes {
    FUZS = 'fuzs',
    FUPS = 'fups',

    // default value
    ORSR = 'orsr'
}


// Cognito user types
export enum UserType {
    'uctovnik' = 'UCTOVNIK',
    'advokat' = 'ADVOKAT',
    'notar' = 'NOTAR',
}

export const BusinessTypes = {
    "A" :"Zemědělství, lesnictví, rybářství",
    "B": "Těžba a dobývání",
    "C": "Zpracovatelský průmysl",
    "D": "Výroba a rozvod elektřiny, plynu, tepla a klimatizovaného vzduchu",
    "E": "Zásobování vodou; činnosti související s odpadními vodami, odpady a sanacemi",
    "F": "Stavebnictví",
    "G": "Velkoobchod a maloobchod; opravy a údržba motorových vozidel.",
    "H": "Doprava a skladování",
    "I": "Ubytování, stravování a pohostinství",
    "J": "Informační a komunikační činnosti",
    "K": "Peněžnictví a pojišťovnictví",
    "L": "Činnosti v oblasti nemovitostí",
    "M": "Profesní, vědecké a technické činnosti",
    "N": "Administrativní a podpůrné činnosti",
    "O": "Veřejná správa a obrana; povinné sociální zabezpečení",
    "P": "Vzdělávání",
    "Q": "Zdravotní a sociální péče",
    "R": "Kulturní, zábavní a rekreační činnosti",
    "S":"Ostatní činnosti",
    "T": "Činnosti domácností jako zaměstnavatelů; činnosti domácností produkujících blíže neurčené výrobky a služby pro vlastní potřebu",
    "U": "Činnosti exteritoriálních organizací a orgánů",
    "00": "Výroba, obchod a služby neuvedené v přílohách 1 až 3 živnostenského zákona",
    "10": "Rostlinná a živočišná výroba, myslivost a související činnosti",
    "011": "Pěstování plodin jiných než trvalých",
    "0111": "Pěstování obilovin (kromě rýže), luštěnin a olejnatých semen",
    "0112": "Pěstování rýže",
    "0113": "Pěstování zeleniny a melounů, kořenů a hlíz",
    "0114": "Pěstování cukrové třtiny",
    "0115": "Pěstování tabáku",
    "0116": "Pěstování přadných rostlin ",
    "0119": "Pěstování ostatních plodin jiných než trvalých",
    "012": "Pěstování trvalých plodin ",
    "0121": "Pěstování vinných hroznů",
    "0122": "Pěstování tropického a subtropického ovoce",
    "0123": "Pěstování citrusových plodů ",
    "0124": "Pěstování jádrového a peckového ovoce",
    "0125": "Pěstování ostatního stromového a keřového ovoce a ořechů ",
    "0126": "Pěstování olejnatých plodů ",
    "0127": "Pěstování rostlin pro výrobu nápojů ",
    "0128": "Pěstování koření, aromatických, léčivých a farmaceutických rostlin ",
    "0129": "Pěstování ostatních trvalých plodin ",
    "013": "Množení rostlin ",
    "0130": "Množení rostlin ",
    "014": "Živočišná výroba",
    "0141": "Chov mléčného skotu ",
    "0142": "Chov jiného skotu",
    "0143": "Chov koní a jiných koňovitých",
    "0144": "Chov velbloudů a velbloudovitých",
    "0145": "Chov ovcí a koz",
    "0146": "Chov prasat",
    "0147": "Chov drůbeže",
    "0149": "Chov ostatních zvířat",
    "01491": "Chov drobných hospodářských zvířat",
    "01492": "Chov kožešinových zvířat",
    "01493": "Chov zvířat pro zájmový chov",
    "01499": "Chov ostatních zvířat j. n.",
    "015": "Smíšené hospodářství",
    "0150": "Smíšené hospodářství",
    "016": "Podpůrné činnosti pro zemědělství a posklizňové činnosti ",
    "0161": "Podpůrné činnosti pro rostlinnou výrobu",
    "0162": "Podpůrné činnosti pro živočišnou výrobu",
    "0163": "Posklizňové činnosti",
    "0164": "Zpracování osiva pro účely množení",
    "017": "Lov a odchyt divokých zvířat a související činnosti ",
    "0170": "Lov a odchyt divokých zvířat a související činnosti ",
    "20": "Lesnictví a těžba dřeva",
    "021": "Lesní hospodářství a jiné činnosti v oblasti lesnictví",
    "0210": "Lesní hospodářství a jiné činnosti v oblasti lesnictví",
    "022": "Těžba dřeva",
    "0220": "Těžba dřeva",
    "023": "Sběr a získávání volně rostoucích plodů a materiálů, kromě dřeva",
    "0230": "Sběr a získávání volně rostoucích plodů a materiálů, kromě dřeva",
    "024": "Podpůrné činnosti pro lesnictví",
    "0240": "Podpůrné činnosti pro lesnictví",
    "30": "Rybolov a akvakultura ",
    "031": "Rybolov",
    "0311": "Mořský rybolov",
    "0312": "Sladkovodní rybolov",
    "032": "Akvakultura",
    "0321": "Mořská akvakultura",
    "0322": "Sladkovodní akvakultura",
    "50": "Těžba a úprava černého a hnědého uhlí ",
    "051": "Těžba a úprava černého uhlí",
    "0510": "Těžba a úprava černého uhlí",
    "05101": "Těžba černého uhlí",
    "05102": "Úprava černého uhlí",
    "052": "Těžba a úprava hnědého uhlí",
    "0520": "Těžba a úprava hnědého uhlí",
    "05201": "Těžba hnědého uhlí, kromě lignitu",
    "05202": "Úprava hnědého uhlí, kromě lignitu",
    "05203": "Těžba lignitu",
    "05204": "Úprava lignitu",
    "60": "Těžba ropy a zemního plynu",
    "061": "Těžba ropy",
    "0610": "Těžba ropy",
    "062": "Těžba zemního plynu",
    "0620": "Těžba zemního plynu",
    "70": "Těžba a úprava rud",
    "071": "Těžba a úprava železných rud",
    "0710": "Těžba a úprava železných rud",
    "07101": "Těžba železných rud",
    "07102": "Úprava železných rud",
    "072": "Těžba a úprava neželezných rud ",
    "0721": "Těžba a úprava uranových a thoriových rud",
    "07211": "Těžba uranových a thoriových rud",
    "07212": "Úprava uranových a thoriových rud",
    "0729": "Těžba a úprava ostatních neželezných rud ",
    "07291": "Těžba ostatních neželezných rud",
    "07292": "Úprava ostatních neželezných rud",
    "80": "Ostatní těžba a dobývání",
    "081": "Dobývání kamene, písků a jílů ",
    "0811": "Dobývání kamene pro výtvarné nebo stavební účely, vápence, sádrovce, křídy a břidlice",
    "0812": "Provoz pískoven a štěrkopískoven; těžba jílů a kaolinu",
    "089": "Těžba a dobývání j. n.",
    "0891": "Těžba chemických minerálů a minerálů pro výrobu hnojiv",
    "0892": "Těžba rašeliny ",
    "0893": "Těžba soli",
    "0899": "Ostatní těžba a dobývání j. n.",
    "90": "Podpůrné činnosti při těžbě",
    "091": "Podpůrné činnosti při těžbě ropy a zemního plynu",
    "0910": "Podpůrné činnosti při těžbě ropy a zemního plynu",
    "099": "Podpůrné činnosti při ostatní těžbě a dobývání",
    "0990": "Podpůrné činnosti při ostatní těžbě a dobývání",
    "100": "Výroba potravinářských výrobků",
    "101": "Zpracování a konzervování masa a výroba masných výrobků",
    "1011": "Zpracování a konzervování masa, kromě drůbežího",
    "1012": "Zpracování a konzervování drůbežího masa",
    "1013": "Výroba masných výrobků a výrobků z drůbežího masa",
    "102": "Zpracování a konzervování ryb, korýšů a měkkýšů",
    "1020": "Zpracování a konzervování ryb, korýšů a měkkýšů",
    "103": "Zpracování a konzervování ovoce a zeleniny ",
    "1031": "Zpracování a konzervování brambor",
    "1032": "Výroba ovocných a zeleninových šťáv",
    "1039": "Ostatní zpracování a konzervování ovoce a zeleniny ",
    "104": "Výroba rostlinných a živočišných olejů a tuků",
    "1041": "Výroba olejů a tuků",
    "1042": "Výroba margarínu a podobných jedlých tuků",
    "105": "Výroba mléčných výrobků ",
    "1051": "Zpracování mléka, výroba mléčných výrobků a sýrů",
    "1052": "Výroba zmrzliny",
    "106": "Výroba mlýnských a škrobárenských výrobků",
    "1061": "Výroba mlýnských výrobků",
    "1062": "Výroba škrobárenských výrobků",
    "107": "Výroba pekařských, cukrářských a jiných moučných výrobků",
    "1071": "Výroba pekařských a cukrářských výrobků, kromě trvanlivých",
    "1072": "Výroba sucharů a sušenek; výroba trvanlivých cukrářských výrobků ",
    "1073": "Výroba makaronů, nudlí, kuskusu a podobných moučných výrobků",
    "108": "Výroba ostatních potravinářských výrobků",
    "1081": "Výroba cukru",
    "1082": "Výroba kakaa, čokolády a cukrovinek",
    "1083": "Zpracování čaje a kávy",
    "1084": "Výroba koření a aromatických výtažků",
    "1085": "Výroba hotových pokrmů",
    "1086": "Výroba homogenizovaných potravinářských přípravků a dietních potravin",
    "1089": "Výroba ostatních potravinářských výrobků j. n.",
    "109": "Výroba průmyslových krmiv",
    "1091": "Výroba průmyslových krmiv pro hospodářská zvířata",
    "1092": "Výroba průmyslových krmiv pro zvířata v zájmovém chovu",
    "110": "Výroba nápojů",
    "1101": "Destilace, rektifikace a míchání lihovin",
    "1102": "Výroba vína z vinných hroznů ",
    "1103": "Výroba jablečného vína a jiných ovocných vín",
    "1104": "Výroba ostatních nedestilovaných kvašených nápojů",
    "1105": "Výroba piva ",
    "1106": "Výroba sladu",
    "1107": "Výroba nealkoholických nápojů; stáčení minerálních a ostatních vod do lahví",
    "120": "Výroba tabákových výrobků",
    "1200": "Výroba tabákových výrobků",
    "130": "Výroba textilií ",
    "131": "Úprava a spřádání textilních vláken a příze",
    "1310": "Úprava a spřádání textilních vláken a příze",
    "132": "Tkaní textilií ",
    "1320": "Tkaní textilií ",
    "133": "Konečná úprava textilií",
    "1330": "Konečná úprava textilií",
    "139": "Výroba ostatních textilií",
    "1391": "Výroba pletených a háčkovaných materiálů",
    "1392": "Výroba konfekčních textilních výrobků, kromě oděvů",
    "1393": "Výroba koberců a kobercových předložek",
    "1394": "Výroba lan, provazů a síťovaných výrobků",
    "1395": "Výroba netkaných textilií a výrobků z nich, kromě oděvů",
    "1396": "Výroba ostatních technických a průmyslových textilií",
    "1399": "Výroba ostatních textilií j. n.",
    "140": "Výroba oděvů",
    "141": "Výroba oděvů, kromě kožešinových výrobků ",
    "1411": "Výroba kožených oděvů ",
    "1412": "Výroba pracovních oděvů",
    "1413": "Výroba ostatních svrchních oděvů",
    "1414": "Výroba osobního prádla",
    "1419": "Výroba ostatních oděvů a oděvních doplňků ",
    "142": "Výroba kožešinových výrobků",
    "1420": "Výroba kožešinových výrobků",
    "143": "Výroba pletených a háčkovaných oděvů",
    "1431": "Výroba pletených a háčkovaných punčochových výrobků",
    "1439": "Výroba ostatních pletených a háčkovaných oděvů",
    "150": "Výroba usní a souvisejících výrobků ",
    "151": "Činění a úprava usní (vyčiněných kůží); zpracování a barvení kožešin; výroba brašnářských, sedlářských a podobných výrobků",
    "1511": "Činění a úprava usní (vyčiněných kůží); zpracování a barvení kožešin ",
    "1512": "Výroba brašnářských, sedlářských a podobných výrobků",
    "152": "Výroba obuvi ",
    "1520": "Výroba obuvi ",
    "15201": "Výroba obuvi s usňovým svrškem",
    "15209": "Výroba obuvi z ostatních materiálů",
    "160": "Zpracování dřeva, výroba dřevěných, korkových, proutěných a slaměných výrobků, kromě nábytku",
    "161": "Výroba pilařská a impregnace dřeva",
    "1610": "Výroba pilařská a impregnace dřeva",
    "162": "Výroba dřevěných, korkových, proutěných a slaměných výrobků, kromě nábytku",
    "1621": "Výroba dýh a desek na bázi dřeva ",
    "1622": "Výroba sestavených parketových podlah",
    "1623": "Výroba ostatních výrobků stavebního truhlářství a tesařství",
    "1624": "Výroba dřevěných obalů",
    "1629": "Výroba ostatních dřevěných, korkových, proutěných a slaměných výrobků, kromě nábytku",
    "170": "Výroba papíru a výrobků z papíru",
    "171": "Výroba buničiny, papíru a lepenky",
    "1711": "Výroba buničiny",
    "17111": "Výroba chemických buničin",
    "17112": "Výroba mechanických vláknin",
    "17113": "Výroba ostatních papírenských vláknin",
    "1712": "Výroba papíru a lepenky",
    "172": "Výroba výrobků z papíru a lepenky",
    "1721": "Výroba vlnitého papíru a lepenky, papírových a lepenkových obalů",
    "1722": "Výroba domácích potřeb, hygienických a toaletních výrobků z papíru",
    "1723": "Výroba kancelářských potřeb z papíru",
    "1724": "Výroba tapet",
    "1729": "Výroba ostatních výrobků z papíru a lepenky",
    "180": "Tisk a rozmnožování nahraných nosičů",
    "181": "Tisk a činnosti související s tiskem",
    "1811": "Tisk novin",
    "1812": "Tisk ostatní, kromě novin",
    "1813": "Příprava tisku a digitálních dat",
    "1814": "Vázání a související činnosti",
    "182": "Rozmnožování nahraných nosičů ",
    "1820": "Rozmnožování nahraných nosičů ",
    "190": "Výroba koksu a rafinovaných ropných produktů ",
    "191": "Výroba koksárenských produktů",
    "1910": "Výroba koksárenských produktů",
    "192": "Výroba rafinovaných ropných produktů ",
    "1920": "Výroba rafinovaných ropných produktů ",
    "200": "Výroba chemických látek a chemických přípravků",
    "201": "Výroba základních chemických látek, hnojiv a dusíkatých sloučenin, plastů a syntetického kaučuku v primárních formách",
    "2011": "Výroba technických plynů",
    "2012": "Výroba barviv a pigmentů",
    "2013": "Výroba jiných základních anorganických chemických látek",
    "2014": "Výroba jiných základních organických chemických látek",
    "20141": "Výroba bioetanolu (biolihu) pro pohon motorů a pro výrobu směsí a komponent paliv pro pohon motorů",
    "20149": "Výroba ostatních základních organických chemických látek",
    "2015": "Výroba hnojiv a dusíkatých sloučenin",
    "2016": "Výroba plastů v primárních formách",
    "2017": "Výroba syntetického kaučuku v primárních formách",
    "202": "Výroba pesticidů a jiných agrochemických přípravků",
    "2020": "Výroba pesticidů a jiných agrochemických přípravků",
    "203": "Výroba nátěrových barev, laků a jiných nátěrových materiálů, tiskařských barev a tmelů",
    "2030": "Výroba nátěrových barev, laků a jiných nátěrových materiálů, tiskařských barev a tmelů",
    "204": "Výroba mýdel a detergentů, čisticích a lešticích prostředků, parfémů a toaletních přípravků",
    "2041": "Výroba mýdel a detergentů, čisticích a lešticích prostředků",
    "2042": "Výroba parfémů a toaletních přípravků",
    "205": "Výroba ostatních chemických výrobků",
    "2051": "Výroba výbušnin",
    "2052": "Výroba klihů ",
    "2053": "Výroba vonných silic ",
    "2059": "Výroba ostatních chemických výrobků j. n.",
    "20591": "Výroba metylesterů a etylesterů mastných kyselin pro pohon motorů a pro výrobu směsí paliv pro pohon motorů",
    "20599": "Výroba jiných chemických výrobků j. n.",
    "206": "Výroba chemických vláken",
    "2060": "Výroba chemických vláken",
    "210": "Výroba základních farmaceutických výrobků a farmaceutických přípravků",
    "211": "Výroba základních farmaceutických výrobků",
    "2110": "Výroba základních farmaceutických výrobků",
    "212": "Výroba farmaceutických přípravků ",
    "2120": "Výroba farmaceutických přípravků ",
    "220": "Výroba pryžových a plastových výrobků",
    "221": "Výroba pryžových výrobků",
    "2211": "Výroba pryžových plášťů a duší; protektorování pneumatik",
    "2219": "Výroba ostatních pryžových výrobků",
    "222": "Výroba plastových výrobků",
    "2221": "Výroba plastových desek, fólií, hadic, trubek a profilů",
    "2222": "Výroba plastových obalů",
    "2223": "Výroba plastových výrobků pro stavebnictví",
    "2229": "Výroba ostatních plastových výrobků",
    "230": "Výroba ostatních nekovových minerálních výrobků",
    "231": "Výroba skla a skleněných výrobků",
    "2311": "Výroba plochého skla",
    "2312": "Tvarování a zpracování plochého skla",
    "2313": "Výroba dutého skla",
    "2314": "Výroba skleněných vláken",
    "2319": "Výroba a zpracování ostatního skla vč. technického",
    "232": "Výroba žáruvzdorných výrobků",
    "2320": "Výroba žáruvzdorných výrobků",
    "233": "Výroba stavebních výrobků z jílovitých materiálů ",
    "2331": "Výroba keramických obkládaček a dlaždic",
    "2332": "Výroba pálených zdicích materiálů, tašek, dlaždic a podobných výrobků",
    "234": "Výroba ostatních porcelánových a keramických výrobků ",
    "2341": "Výroba keramických a porcelánových výrobků převážně pro domácnost a ozdobných předmětů",
    "2342": "Výroba keramických sanitárních výrobků",
    "2343": "Výroba keramických izolátorů a izolačního příslušenství",
    "2344": "Výroba ostatních technických keramických výrobků",
    "2349": "Výroba ostatních keramických výrobků",
    "235": "Výroba cementu, vápna a sádry",
    "2351": "Výroba cementu",
    "2352": "Výroba vápna a sádry",
    "236": "Výroba betonových, cementových a sádrových výrobků",
    "2361": "Výroba betonových výrobků pro stavební účely",
    "2362": "Výroba sádrových výrobků pro stavební účely",
    "2363": "Výroba betonu připraveného k lití",
    "2364": "Výroba malt ",
    "2365": "Výroba vláknitých cementů",
    "2369": "Výroba ostatních betonových, cementových a sádrových výrobků ",
    "237": "Řezání, tvarování a konečná úprava kamenů ",
    "2370": "Řezání, tvarování a konečná úprava kamenů ",
    "239": "Výroba brusiv a ostatních nekovových minerálních výrobků j. n.",
    "2391": "Výroba brusiv",
    "2399": "Výroba ostatních nekovových minerálních výrobků j. n.",
    "240": "Výroba základních kovů, hutní zpracování kovů; slévárenství",
    "241": "Výroba surového železa, oceli a feroslitin, plochých výrobků (kromě pásky za studena), tváření výrobků za tepla  ",
    "2410": "Výroba surového železa, oceli a feroslitin, plochých výrobků (kromě pásky za studena), tváření výrobků za tepla  ",
    "24101": "Výroba surového železa, oceli a feroslitin",
    "24102": "Výroba plochých výrobků (kromě pásky za studena)",
    "24103": "Tváření výrobků za tepla  ",
    "242": "Výroba ocelových trub, trubek, dutých profilů a souvisejících potrubních tvarovek",
    "2420": "Výroba ocelových trub, trubek, dutých profilů a souvisejících potrubních tvarovek",
    "243": "Výroba ostatních výrobků získaných jednostupňovým zpracováním oceli",
    "2431": "Tažení tyčí za studena",
    "2432": "Válcování ocelových úzkých pásů za studena",
    "2433": "Tváření ocelových profilů za studena ",
    "2434": "Tažení ocelového drátu za studena",
    "244": "Výroba a hutní zpracování drahých a neželezných kovů",
    "2441": "Výroba a hutní zpracování drahých kovů",
    "2442": "Výroba a hutní zpracování hliníku",
    "2443": "Výroba a hutní zpracování olova, zinku a cínu",
    "2444": "Výroba a hutní zpracování mědi",
    "2445": "Výroba a hutní zpracování ostatních neželezných kovů",
    "2446": "Zpracování jaderného paliva",
    "245": "Slévárenství",
    "2451": "Výroba odlitků z litiny",
    "24511": "Výroba odlitků z litiny s lupínkovým grafitem",
    "24512": "Výroba odlitků z litiny s kuličkovým grafitem",
    "24519": "Výroba ostatních odlitků z litiny",
    "2452": "Výroba odlitků z oceli",
    "24521": "Výroba odlitků z uhlíkatých ocelí",
    "24522": "Výroba odlitků z legovaných ocelí",
    "2453": "Výroba odlitků z lehkých neželezných kovů",
    "2454": "Výroba odlitků z ostatních neželezných kovů",
    "250": "Výroba kovových konstrukcí a kovodělných výrobků, kromě strojů a zařízení",
    "251": "Výroba konstrukčních kovových výrobků",
    "2511": "Výroba kovových konstrukcí a jejich dílů",
    "2512": "Výroba kovových dveří a oken",
    "252": "Výroba radiátorů a kotlů k ústřednímu topení, kovových nádrží a zásobníků ",
    "2521": "Výroba radiátorů a kotlů k ústřednímu topení",
    "2529": "Výroba kovových nádrží a zásobníků",
    "253": "Výroba parních kotlů, kromě kotlů pro ústřední topení",
    "2530": "Výroba parních kotlů, kromě kotlů pro ústřední topení",
    "254": "Výroba zbraní a střeliva",
    "2540": "Výroba zbraní a střeliva",
    "255": "Kování, lisování, ražení, válcování a protlačování kovů; prášková metalurgie",
    "2550": "Kování, lisování, ražení, válcování a protlačování kovů; prášková metalurgie",
    "256": "Povrchová úprava a zušlechťování kovů; obrábění",
    "2561": "Povrchová úprava a zušlechťování kovů",
    "2562": "Obrábění",
    "257": "Výroba nožířských výrobků, nástrojů a železářských výrobků ",
    "2571": "Výroba nožířských výrobků",
    "2572": "Výroba zámků a kování",
    "2573": "Výroba nástrojů a nářadí",
    "259": "Výroba ostatních kovodělných výrobků",
    "2591": "Výroba ocelových sudů a podobných nádob",
    "2592": "Výroba drobných kovových obalů",
    "2593": "Výroba drátěných výrobků, řetězů a pružin",
    "2594": "Výroba spojovacích materiálů a spojovacích výrobků se závity",
    "2599": "Výroba ostatních kovodělných výrobků j. n.",
    "260": "Výroba počítačů, elektronických a optických přístrojů a zařízení",
    "261": "Výroba elektronických součástek a desek",
    "2611": "Výroba elektronických součástek ",
    "2612": "Výroba osazených elektronických desek",
    "262": "Výroba počítačů a periferních zařízení",
    "2620": "Výroba počítačů a periferních zařízení",
    "263": "Výroba komunikačních zařízení",
    "2630": "Výroba komunikačních zařízení",
    "264": "Výroba spotřební elektroniky ",
    "2640": "Výroba spotřební elektroniky ",
    "265": "Výroba měřicích, zkušebních a navigačních přístrojů; výroba časoměrných přístrojů",
    "2651": "Výroba měřicích, zkušebních a navigačních přístrojů ",
    "2652": "Výroba časoměrných přístrojů",
    "266": "Výroba ozařovacích, elektroléčebných a elektroterapeutických přístrojů",
    "2660": "Výroba ozařovacích, elektroléčebných a elektroterapeutických přístrojů",
    "267": "Výroba optických a fotografických přístrojů a zařízení",
    "2670": "Výroba optických a fotografických přístrojů a zařízení",
    "268": "Výroba magnetických a optických médií",
    "2680": "Výroba magnetických a optických médií",
    "270": "Výroba elektrických zařízení",
    "271": "Výroba elektrických motorů, generátorů, transformátorů a elektrických rozvodných a kontrolních zařízení",
    "2711": "Výroba elektrických motorů, generátorů a transformátorů",
    "2712": "Výroba elektrických rozvodných a kontrolních zařízení",
    "272": "Výroba baterií a akumulátorů ",
    "2720": "Výroba baterií a akumulátorů ",
    "273": "Výroba optických a elektrických kabelů, elektrických vodičů a elektroinstalačních zařízení ",
    "2731": "Výroba optických kabelů ",
    "2732": "Výroba elektrických vodičů a kabelů j. n.",
    "2733": "Výroba elektroinstalačních zařízení",
    "274": "Výroba elektrických osvětlovacích zařízení ",
    "2740": "Výroba elektrických osvětlovacích zařízení ",
    "275": "Výroba spotřebičů převážně pro domácnost",
    "2751": "Výroba elektrických spotřebičů převážně pro domácnost",
    "2752": "Výroba neelektrických spotřebičů převážně pro domácnost",
    "279": "Výroba ostatních elektrických zařízení",
    "2790": "Výroba ostatních elektrických zařízení",
    "280": "Výroba strojů a zařízení j. n.",
    "281": "Výroba strojů a zařízení pro všeobecné účely",
    "2811": "Výroba motorů a turbín, kromě motorů pro letadla, automobily a motocykly",
    "2812": "Výroba hydraulických a pneumatických zařízení ",
    "2813": "Výroba ostatních čerpadel a kompresorů",
    "2814": "Výroba ostatních potrubních armatur",
    "2815": "Výroba ložisek, ozubených kol, převodů a hnacích prvků",
    "282": "Výroba ostatních strojů a zařízení pro všeobecné účely",
    "2821": "Výroba pecí a hořáků pro topeniště",
    "2822": "Výroba zdvihacích a manipulačních zařízení",
    "2823": "Výroba kancelářských strojů a zařízení, kromě počítačů a periferních zařízení",
    "2824": "Výroba ručních mechanizovaných nástrojů",
    "2825": "Výroba průmyslových chladicích a klimatizačních zařízení",
    "2829": "Výroba ostatních strojů a zařízení pro všeobecné účely j. n.",
    "283": "Výroba zemědělských a lesnických strojů",
    "2830": "Výroba zemědělských a lesnických strojů",
    "284": "Výroba kovoobráběcích a ostatních obráběcích strojů ",
    "2841": "Výroba kovoobráběcích strojů ",
    "2849": "Výroba ostatních obráběcích strojů",
    "289": "Výroba ostatních strojů pro speciální účely",
    "2891": "Výroba strojů pro metalurgii",
    "2892": "Výroba strojů pro těžbu, dobývání a stavebnictví",
    "2893": "Výroba strojů na výrobu potravin, nápojů a zpracování tabáku",
    "2894": "Výroba strojů na výrobu textilu, oděvních výrobků a výrobků z usní",
    "2895": "Výroba strojů a přístrojů na výrobu papíru a lepenky",
    "2896": "Výroba strojů na výrobu plastů a pryže",
    "2899": "Výroba ostatních strojů pro speciální účely j. n.",
    "290": "Výroba motorových vozidel (kromě motocyklů), přívěsů a návěsů",
    "291": "Výroba motorových vozidel a jejich motorů",
    "2910": "Výroba motorových vozidel a jejich motorů",
    "292": "Výroba karoserií motorových vozidel; výroba přívěsů a návěsů",
    "2920": "Výroba karoserií motorových vozidel; výroba přívěsů a návěsů",
    "293": "Výroba dílů a příslušenství pro motorová vozidla a jejich motory",
    "2931": "Výroba elektrického a elektronického zařízení pro motorová vozidla",
    "2932": "Výroba ostatních dílů a příslušenství pro motorová vozidla",
    "300": "Výroba ostatních dopravních prostředků a zařízení",
    "301": "Stavba lodí a člunů",
    "3011": "Stavba lodí a plavidel",
    "3012": "Stavba rekreačních a sportovních člunů",
    "302": "Výroba železničních lokomotiv a vozového parku",
    "3020": "Výroba železničních lokomotiv a vozového parku",
    "303": "Výroba letadel a jejich motorů, kosmických lodí a souvisejících zařízení",
    "3030": "Výroba letadel a jejich motorů, kosmických lodí a souvisejících zařízení",
    "304": "Výroba vojenských bojových vozidel",
    "3040": "Výroba vojenských bojových vozidel",
    "309": "Výroba dopravních prostředků a zařízení j. n.",
    "3091": "Výroba motocyklů",
    "3092": "Výroba jízdních kol a vozíků pro invalidy",
    "3099": "Výroba ostatních dopravních prostředků a zařízení j. n.",
    "310": "Výroba nábytku",
    "3101": "Výroba kancelářského nábytku a zařízení obchodů",
    "3102": "Výroba kuchyňského nábytku",
    "3103": "Výroba matrací",
    "3109": "Výroba ostatního nábytku",
    "320": "Ostatní zpracovatelský průmysl",
    "321": "Výroba klenotů, bižuterie a příbuzných výrobků",
    "3211": "Ražení mincí",
    "3212": "Výroba klenotů a příbuzných výrobků",
    "3213": "Výroba bižuterie a příbuzných výrobků",
    "322": "Výroba hudebních nástrojů",
    "3220": "Výroba hudebních nástrojů",
    "323": "Výroba sportovních potřeb",
    "3230": "Výroba sportovních potřeb",
    "324": "Výroba her a hraček",
    "3240": "Výroba her a hraček",
    "325": "Výroba lékařských a dentálních nástrojů a potřeb",
    "3250": "Výroba lékařských a dentálních nástrojů a potřeb",
    "329": "Zpracovatelský průmysl j. n.",
    "3291": "Výroba košťat a kartáčnických výrobků",
    "3299": "Ostatní zpracovatelský průmysl j. n. ",
    "330": "Opravy a instalace strojů a zařízení",
    "331": "Opravy kovodělných výrobků, strojů a zařízení",
    "3311": "Opravy kovodělných výrobků",
    "3312": "Opravy strojů",
    "3313": "Opravy elektronických a optických přístrojů a zařízení",
    "3314": "Opravy elektrických zařízení",
    "3315": "Opravy a údržba lodí a člunů",
    "3316": "Opravy a údržba letadel a kosmických lodí",
    "3317": "Opravy a údržba ostatních dopravních prostředků a zařízení j. n. ",
    "33171": "Opravy a údržba kolejových vozidel",
    "33179": "Opravy a údržba ostatních dopravních prostředků a zařízení j. n. kromě kolejových vozidel",
    "3319": "Opravy ostatních zařízení",
    "332": "Instalace průmyslových strojů a zařízení",
    "3320": "Instalace průmyslových strojů a zařízení",
    "350": "Výroba a rozvod elektřiny, plynu, tepla a klimatizovaného vzduchu",
    "351": "Výroba, přenos a rozvod elektřiny",
    "3511": "Výroba elektřiny ",
    "3512": "Přenos elektřiny ",
    "3513": "Rozvod elektřiny ",
    "3514": "Obchod s elektřinou ",
    "352": "Výroba plynu; rozvod plynných paliv prostřednictvím sítí",
    "3521": "Výroba plynu",
    "3522": "Rozvod plynných paliv prostřednictvím sítí",
    "3523": "Obchod s plynem prostřednictvím sítí",
    "353": "Výroba a rozvod tepla a klimatizovaného vzduchu, výroba ledu ",
    "3530": "Výroba a rozvod tepla a klimatizovaného vzduchu, výroba ledu",
    "35301": "Výroba tepla",
    "35302": "Rozvod tepla",
    "35303": "Výroba klimatizovaného vzduchu",
    "35304": "Rozvod klimatizovaného vzduchu",
    "35305": "Výroba chladicí vody",
    "35306": "Rozvod chladicí vody",
    "35307": "Výroba ledu",
    "360": "Shromažďování, úprava a rozvod vody ",
    "3600": "Shromažďování, úprava a rozvod vody ",
    "36000": "Shromažďování, úprava a rozvod vody ",
    "370": "Činnosti související s odpadními vodami",
    "3700": "Činnosti související s odpadními vodami",
    "380": "Shromažďování, sběr a odstraňování odpadů, úprava odpadů k dalšímu využití",
    "381": "Shromažďování a sběr odpadů",
    "3811": "Shromažďování a sběr odpadů, kromě nebezpečných",
    "3812": "Shromažďování a sběr nebezpečných odpadů",
    "382": "Odstraňování odpadů",
    "3821": "Odstraňování odpadů, kromě nebezpečných",
    "3822": "Odstraňování nebezpečných odpadů",
    "383": "Úprava odpadů k dalšímu využití",
    "3831": "Demontáž vraků a vyřazených strojů a zařízení pro účely recyklace",
    "3832": "Úprava odpadů k dalšímu využití, kromě demontáže vraků, strojů a zařízení",
    "390": "Sanace a jiné činnosti související s odpady",
    "3900": "Sanace a jiné činnosti související s odpady",
    "410": "Výstavba budov",
    "411": "Developerská činnost ",
    "4110": "Developerská činnost",
    "412": "Výstavba bytových a nebytových budov",
    "4120": "Výstavba bytových a nebytových budov",
    "41201": "Výstavba bytových budov",
    "41202": "Výstavba nebytových budov",
    "420": "Inženýrské stavitelství",
    "421": "Výstavba silnic a železnic",
    "4211": "Výstavba silnic a dálnic",
    "4212": "Výstavba železnic a podzemních drah",
    "4213": "Výstavba mostů a tunelů",
    "422": "Výstavba inženýrských sítí",
    "4221": "Výstavba inženýrských sítí pro kapaliny a plyny",
    "42211": "Výstavba inženýrských sítí pro kapaliny",
    "42212": "Výstavba inženýrských sítí pro plyny",
    "4222": "Výstavba inženýrských sítí pro elektřinu a telekomunikace",
    "429": "Výstavba ostatních staveb",
    "4291": "Výstavba vodních děl",
    "4299": "Výstavba ostatních staveb j. n.",
    "430": "Specializované stavební činnosti ",
    "431": "Demolice a příprava staveniště",
    "4311": "Demolice",
    "4312": "Příprava staveniště",
    "4313": "Průzkumné vrtné práce",
    "432": "Elektroinstalační, instalatérské a ostatní stavebně instalační práce",
    "4321": "Elektrické instalace",
    "4322": "Instalace vody, odpadu, plynu, topení a klimatizace",
    "4329": "Ostatní stavební instalace",
    "433": "Kompletační a dokončovací práce",
    "4331": "Omítkářské práce",
    "4332": "Truhlářské práce",
    "4333": "Obkládání stěn a pokládání podlahových krytin",
    "4334": "Sklenářské, malířské a natěračské práce",
    "43341": "Sklenářské práce",
    "43342": "Malířské a natěračské práce",
    "4339": "Ostatní kompletační a dokončovací práce",
    "439": "Ostatní specializované stavební činnosti ",
    "4391": "Pokrývačské práce",
    "4399": "Ostatní specializované stavební činnosti j. n.",
    "43991": "Montáž a demontáž lešení a bednění",
    "43999": "Jiné specializované stavební činnosti j. n.",
    "450": "Velkoobchod, maloobchod a opravy motorových vozidel ",
    "451": "Obchod s motorovými vozidly, kromě motocyklů",
    "4511": "Obchod s automobily a jinými lehkými motorovými vozidly",
    "4519": "Obchod s ostatními motorovými vozidly, kromě motocyklů",
    "452": "Opravy a údržba motorových vozidel, kromě motocyklů",
    "4520": "Opravy a údržba motorových vozidel, kromě motocyklů",
    "453": "Obchod s díly a příslušenstvím pro motorová vozidla, kromě motocyklů",
    "4531": "Velkoobchod s díly a příslušenstvím pro motorová vozidla, kromě motocyklů",
    "4532": "Maloobchod s díly a příslušenstvím pro motorová vozidla, kromě motocyklů",
    "454": "Obchod, opravy a údržba motocyklů, jejich dílů a příslušenství",
    "4540": "Obchod, opravy a údržba motocyklů, jejich dílů a příslušenství",
    "460": "Velkoobchod, kromě motorových vozidel ",
    "461": "Zprostředkování velkoobchodu a velkoobchod v zastoupení ",
    "4611": "Zprostředkování velkoobchodu a velkoobchod v zastoupení se základními zemědělskými produkty, živými zvířaty, textilními surovinami a polotovary ",
    "4612": "Zprostředkování velkoobchodu a velkoobchod v zastoupení s palivy, rudami, kovy a průmyslovými chemikáliemi ",
    "4613": "Zprostředkování velkoobchodu a velkoobchod v zastoupení se dřevem a stavebními materiály",
    "4614": "Zprostředkování velkoobchodu a velkoobchod v zastoupení se stroji, průmyslovým zařízením, loděmi a letadly",
    "4615": "Zprostředkování velkoobchodu a velkoobchod v zastoupení s nábytkem, železářským zbožím a potřebami převážně pro domácnost",
    "4616": "Zprostředkování velkoobchodu a velkoobchod v zastoupení s textilem, oděvy, kožešinami, obuví a koženými výrobky",
    "4617": "Zprostředkování velkoobchodu a velkoobchod v zastoupení s potravinami, nápoji, tabákem a tabákovými výrobky",
    "4618": "Zprostředkování specializovaného velkoobchodu a specializovaný velkoobchod v zastoupení s ostatními výrobky",
    "46181": "Zprostředkování velkoobchodu a velkoobchod v zastoupení s papírenskými výrobky",
    "46189": "Zprostředkování specializovaného velkoobchodu a velkoobchod v zastoupení s ostatními výrobky j. n.",
    "4619": "Zprostředkování nespecializovaného velkoobchodu a nespecializovaný velkoobchod v zastoupení",
    "462": "Velkoobchod se základními zemědělskými produkty a živými zvířaty",
    "4621": "Velkoobchod s obilím, surovým tabákem, osivy a krmivy",
    "4622": "Velkoobchod s květinami a jinými rostlinami",
    "4623": "Velkoobchod s živými zvířaty",
    "4624": "Velkoobchod se surovými kůžemi, kožešinami a usněmi",
    "463": "Velkoobchod s potravinami, nápoji a tabákovými výrobky",
    "4631": "Velkoobchod s ovocem a zeleninou ",
    "4632": "Velkoobchod s masem a masnými výrobky",
    "4633": "Velkoobchod s mléčnými výrobky, vejci, jedlými oleji a tuky",
    "4634": "Velkoobchod s nápoji",
    "4635": "Velkoobchod s tabákovými výrobky",
    "4636": "Velkoobchod s cukrem, čokoládou a cukrovinkami",
    "4637": "Velkoobchod s kávou, čajem, kakaem a kořením",
    "4638": "Specializovaný velkoobchod s jinými potravinami, včetně ryb, korýšů a měkkýšů",
    "4639": "Nespecializovaný velkoobchod s potravinami, nápoji a tabákovými výrobky",
    "464": "Velkoobchod s výrobky převážně pro domácnost",
    "4641": "Velkoobchod s textilem",
    "4642": "Velkoobchod s oděvy a obuví",
    "46421": "Velkoobchod s oděvy",
    "46422": "Velkoobchod s obuví",
    "4643": "Velkoobchod s elektrospotřebiči a elektronikou ",
    "4644": "Velkoobchod s porcelánovými, keramickými a skleněnými výrobky a čisticími prostředky",
    "46441": "Velkoobchod s porcelánovými, keramickými a skleněnými výrobky",
    "46442": "Velkoobchod s pracími a čisticími prostředky",
    "4645": "Velkoobchod s kosmetickými výrobky",
    "4646": "Velkoobchod s farmaceutickými výrobky",
    "4647": "Velkoobchod s nábytkem, koberci a svítidly",
    "4648": "Velkoobchod s hodinami, hodinkami a klenoty",
    "4649": "Velkoobchod s ostatními výrobky převážně pro domácnost",
    "465": "Velkoobchod s počítačovým a komunikačním zařízením",
    "4651": "Velkoobchod s počítači, počítačovým periferním zařízením a softwarem",
    "4652": "Velkoobchod s elektronickým a telekomunikačním zařízením a jeho díly",
    "466": "Velkoobchod s ostatními stroji, strojním zařízením a příslušenstvím",
    "4661": "Velkoobchod se zemědělskými stroji, strojním zařízením a příslušenstvím",
    "4662": "Velkoobchod s obráběcími stroji",
    "4663": "Velkoobchod s těžebními a stavebními stroji a zařízením",
    "4664": "Velkoobchod se strojním zařízením pro textilní průmysl, šicími a pletacími stroji",
    "4665": "Velkoobchod s kancelářským nábytkem",
    "4666": "Velkoobchod s ostatními kancelářskými stroji a zařízením",
    "4669": "Velkoobchod s ostatními stroji a zařízením",
    "467": "Ostatní specializovaný velkoobchod",
    "4671": "Velkoobchod s pevnými, kapalnými a plynnými palivy a příbuznými výrobky",
    "46711": "Velkoobchod s pevnými palivy a příbuznými výrobky",
    "46712": "Velkoobchod s kapalnými palivy a příbuznými výrobky",
    "46713": "Velkoobchod s plynnými palivy a příbuznými výrobky",
    "4672": "Velkoobchod s rudami, kovy a hutními výrobky",
    "4673": "Velkoobchod se dřevem, stavebními materiály a sanitárním vybavením",
    "4674": "Velkoobchod s železářským zbožím, instalatérskými a topenářskými potřebami",
    "4675": "Velkoobchod s chemickými výrobky",
    "4676": "Velkoobchod s ostatními meziprodukty",
    "46761": "Velkoobchod s papírenskými meziprodukty",
    "46769": "Velkoobchod s ostatními meziprodukty j. n.",
    "4677": "Velkoobchod s odpadem a šrotem",
    "469": "Nespecializovaný velkoobchod",
    "4690": "Nespecializovaný velkoobchod",
    "470": "Maloobchod, kromě motorových vozidel ",
    "471": "Maloobchod v nespecializovaných prodejnách",
    "4711": "Maloobchod s převahou potravin, nápojů a tabákových výrobků v nespecializovaných prodejnách",
    "4719": "Ostatní maloobchod v nespecializovaných prodejnách",
    "472": "Maloobchod s potravinami, nápoji a tabákovými výrobky ve specializovaných prodejnách",
    "4721": "Maloobchod s ovocem a zeleninou ",
    "4722": "Maloobchod s masem a masnými výrobky ",
    "4723": "Maloobchod s rybami, korýši a měkkýši ",
    "4724": "Maloobchod s chlebem, pečivem, cukrářskými výrobky a cukrovinkami ",
    "4725": "Maloobchod s nápoji ",
    "4726": "Maloobchod s tabákovými výrobky ",
    "4729": "Ostatní maloobchod s potravinami ve specializovaných prodejnách",
    "473": "Maloobchod s pohonnými hmotami ve specializovaných prodejnách",
    "4730": "Maloobchod s pohonnými hmotami ve specializovaných prodejnách",
    "474": "Maloobchod s počítačovým a komunikačním zařízením ve specializovaných prodejnách",
    "4741": "Maloobchod s počítači, počítačovým periferním zařízením a softwarem ",
    "4742": "Maloobchod s telekomunikačním zařízením ",
    "4743": "Maloobchod s audio- a videozařízením ",
    "475": "Maloobchod s ostatními výrobky převážně pro domácnost ve specializovaných prodejnách",
    "4751": "Maloobchod s textilem ",
    "4752": "Maloobchod s železářským zbožím, barvami, sklem a potřebami pro kutily ",
    "4753": "Maloobchod s koberci, podlahovými krytinami a nástěnnými obklady ",
    "4754": "Maloobchod s elektrospotřebiči a elektronikou ",
    "4759": "Maloobchod s nábytkem, svítidly a ostatními výrobky převážně pro domácnost ve specializovaných prodejnách",
    "476": "Maloobchod s výrobky pro kulturní rozhled a rekreaci ve specializovaných prodejnách",
    "4761": "Maloobchod s knihami ",
    "4762": "Maloobchod s novinami, časopisy a papírnickým zbožím ",
    "4763": "Maloobchod s audio- a videozáznamy ",
    "4764": "Maloobchod se sportovním vybavením ",
    "4765": "Maloobchod s hrami a hračkami ",
    "477": "Maloobchod s ostatním zbožím ve specializovaných prodejnách",
    "4771": "Maloobchod s oděvy ",
    "4772": "Maloobchod s obuví a koženými výrobky ",
    "4773": "Maloobchod s farmaceutickými přípravky ",
    "4774": "Maloobchod se zdravotnickými a ortopedickými výrobky ",
    "4775": "Maloobchod s kosmetickými a toaletními výrobky ",
    "4776": "Maloobchod s květinami, rostlinami, osivy, hnojivy, zvířaty pro zájmový chov a krmivy pro ně ",
    "4777": "Maloobchod s hodinami, hodinkami a klenoty ",
    "4778": "Ostatní maloobchod s novým zbožím ve specializovaných prodejnách",
    "47781": "Maloobchod s fotografickým a optickým zařízením a potřebami",
    "47782": "Maloobchod s pevnými palivy",
    "47783": "Maloobchod s kapalnými palivy (kromě pohonných hmot)",
    "47784": "Maloobchod s plynnými palivy (kromě pohonných hmot)",
    "47789": "Ostatní maloobchod s novým zbožím ve specializovaných prodejnách j. n.",
    "4779": "Maloobchod s použitým zbožím v prodejnách",
    "478": "Maloobchod ve stáncích a na trzích",
    "4781": "Maloobchod s potravinami, nápoji a tabákovými výrobky ve stáncích a na trzích",
    "4782": "Maloobchod s textilem, oděvy a obuví ve stáncích a na trzích ",
    "4789": "Maloobchod s ostatním zbožím ve stáncích a na trzích ",
    "479": "Maloobchod mimo prodejny, stánky a trhy",
    "4791": "Maloobchod prostřednictvím internetu nebo zásilkové služby ",
    "47911": "Maloobchod prostřednictvím internetu",
    "47912": "Maloobchod prostřednictvím zásilkové služby (jiný než prostřednictvím internetu)",
    "4799": "Ostatní maloobchod mimo prodejny, stánky a trhy",
    "490": "Pozemní a potrubní doprava",
    "491": "Železniční osobní doprava meziměstská",
    "4910": "Železniční osobní doprava meziměstská",
    "492": "Železniční nákladní doprava",
    "4920": "Železniční nákladní doprava",
    "493": "Ostatní pozemní osobní doprava ",
    "4931": "Městská a příměstská pozemní osobní doprava",
    "4932": "Taxislužba a pronájem osobních vozů s řidičem",
    "4939": "Ostatní pozemní osobní doprava j. n.",
    "49391": "Meziměstská pravidelná pozemní osobní doprava",
    "49392": "Osobní doprava lanovkou nebo vlekem",
    "49393": "Nepravidelná pozemní osobní doprava",
    "49399": "Jiná pozemní osobní doprava j. n.",
    "494": "Silniční nákladní doprava a stěhovací služby",
    "4941": "Silniční nákladní doprava",
    "4942": "Stěhovací služby",
    "495": "Potrubní doprava",
    "4950": "Potrubní doprava",
    "49501": "Potrubní doprava ropovodem",
    "49502": "Potrubní doprava plynovodem",
    "49509": "Potrubní doprava ostatní",
    "500": "Vodní doprava",
    "501": "Námořní a pobřežní osobní doprava",
    "5010": "Námořní a pobřežní osobní doprava",
    "502": "Námořní a pobřežní nákladní doprava",
    "5020": "Námořní a pobřežní nákladní doprava",
    "503": "Vnitrozemská vodní osobní doprava",
    "5030": "Vnitrozemská vodní osobní doprava",
    "504": "Vnitrozemská vodní nákladní doprava",
    "5040": "Vnitrozemská vodní nákladní doprava",
    "510": "Letecká doprava",
    "511": "Letecká osobní doprava",
    "5110": "Letecká osobní doprava",
    "51101": "Vnitrostátní pravidelná letecká osobní doprava",
    "51102": "Vnitrostátní nepravidelná letecká osobní doprava",
    "51103": "Mezinárodní pravidelná letecká osobní doprava",
    "51104": "Mezinárodní nepravidelná letecká osobní doprava",
    "51109": "Ostatní letecká osobní doprava",
    "512": "Letecká nákladní doprava a kosmická doprava",
    "5121": "Letecká nákladní doprava",
    "5122": "Kosmická doprava",
    "520": "Skladování a vedlejší činnosti v dopravě",
    "521": "Skladování",
    "5210": "Skladování",
    "522": "Vedlejší činnosti v dopravě",
    "5221": "Činnosti související s pozemní dopravou ",
    "5222": "Činnosti související s vodní dopravou ",
    "5223": "Činnosti související s leteckou dopravou ",
    "5224": "Manipulace s nákladem",
    "5229": "Ostatní vedlejší činnosti v dopravě",
    "530": "Poštovní a kurýrní činnosti",
    "531": "Základní poštovní služby poskytované na základě poštovní licence",
    "5310": "Základní poštovní služby poskytované na základě poštovní licence",
    "532": "Ostatní poštovní a kurýrní činnosti",
    "5320": "Ostatní poštovní a kurýrní činnosti",
    "550": "Ubytování",
    "551": "Ubytování v hotelích a podobných ubytovacích zařízeních",
    "5510": "Ubytování v hotelích a podobných ubytovacích zařízeních",
    "55101": "Hotely",
    "55102": "Motely, botely",
    "55109": "Ostatní podobná ubytovací zařízení",
    "552": "Rekreační a ostatní krátkodobé ubytování",
    "5520": "Rekreační a ostatní krátkodobé ubytování",
    "553": "Kempy a tábořiště ",
    "5530": "Kempy a tábořiště ",
    "559": "Ostatní ubytování",
    "5590": "Ostatní ubytování",
    "55901": "Ubytování v zařízených pronájmech ",
    "55902": "Ubytování ve vysokoškolských kolejích, domovech mládeže",
    "55909": "Ostatní ubytování j. n. ",
    "560": "Stravování a pohostinství",
    "561": "Stravování v restauracích, u stánků a v mobilních zařízeních",
    "5610": "Stravování v restauracích, u stánků a v mobilních zařízeních",
    "562": "Poskytování cateringových a ostatních stravovacích služeb",
    "5621": "Poskytování cateringových služeb",
    "5629": "Poskytování ostatních stravovacích služeb",
    "56291": "Stravování v závodních kuchyních",
    "56292": "Stravování ve školních zařízeních, menzách",
    "56299": "Poskytování jiných stravovacích služeb j. n.",
    "563": "Pohostinství",
    "5630": "Pohostinství",
    "580": "Vydavatelské činnosti",
    "581": "Vydávání knih, periodických publikací a ostatní vydavatelské činnosti",
    "5811": "Vydávání knih",
    "5812": "Vydávání adresářů a jiných seznamů ",
    "5813": "Vydávání novin",
    "5814": "Vydávání časopisů a ostatních periodických publikací",
    "5819": "Ostatní vydavatelské činnosti",
    "582": "Vydávání softwaru",
    "5821": "Vydávání počítačových her",
    "5829": "Ostatní vydávání softwaru",
    "590": "Činnosti v oblasti filmů, videozáznamů a televizních programů, pořizování zvukových nahrávek a hudební vydavatelské činnosti",
    "591": "Činnosti v oblasti filmů, videozáznamů a televizních programů ",
    "5911": "Produkce filmů, videozáznamů a televizních programů",
    "5912": "Postprodukce filmů, videozáznamů a televizních programů",
    "5913": "Distribuce filmů, videozáznamů a televizních programů",
    "5914": "Promítání filmů ",
    "592": "Pořizování zvukových nahrávek a hudební vydavatelské činnosti",
    "5920": "Pořizování zvukových nahrávek a hudební vydavatelské činnosti",
    "600": "Tvorba programů a vysílání",
    "601": "Rozhlasové vysílání",
    "6010": "Rozhlasové vysílání",
    "602": "Tvorba televizních programů a televizní vysílání",
    "6020": "Tvorba televizních programů a televizní vysílání",
    "610": "Telekomunikační činnosti",
    "611": "Činnosti související s pevnou telekomunikační sítí",
    "6110": "Činnosti související s pevnou telekomunikační sítí",
    "61101": "Poskytování hlasových služeb přes pevnou telekomunikační síť",
    "61102": "Pronájem pevné telekomunikační sítě",
    "61103": "Přenos dat přes pevnou telekomunikační síť",
    "61104": "Poskytování přístupu k internetu přes pevnou telekomunikační síť",
    "61109": "Ostatní činnosti související s pevnou telekomunikační sítí",
    "612": "Činnosti související s bezdrátovou telekomunikační sítí",
    "6120": "Činnosti související s bezdrátovou telekomunikační sítí",
    "61201": "Poskytování hlasových služeb přes bezdrátovou telekomunikační síť",
    "61202": "Pronájem bezdrátové telekomunikační sítě",
    "61203": "Přenos dat přes bezdrátovou telekomunikační síť",
    "61204": "Poskytování přístupu k internetu přes bezdrátovou telekomunikační síť",
    "61209": "Ostatní činnosti související s bezdrátovou telekomunikační sítí",
    "613": "Činnosti související se satelitní telekomunikační sítí",
    "6130": "Činnosti související se satelitní telekomunikační sítí",
    "619": "Ostatní telekomunikační činnosti",
    "6190": "Ostatní telekomunikační činnosti",
    "620": "Činnosti v oblasti informačních technologií",
    "6201": "Programování",
    "6202": "Poradenství v oblasti informačních technologií",
    "6203": "Správa počítačového vybavení",
    "6209": "Ostatní činnosti v oblasti informačních technologií",
    "630": "Informační činnosti",
    "631": "Činnosti související se zpracováním dat a hostingem; činnosti související s webovými portály",
    "6311": "Činnosti související se zpracováním dat a hostingem",
    "6312": "Činnosti související s webovými portály",
    "639": "Ostatní informační činnosti ",
    "6391": "Činnosti zpravodajských tiskových kanceláří a agentur",
    "6399": "Ostatní informační činnosti j. n.",
    "640": "Finanční zprostředkování, kromě pojišťovnictví a penzijního financování",
    "641": "Peněžní zprostředkování",
    "6411": "Centrální bankovnictví",
    "6419": "Ostatní peněžní zprostředkování",
    "642": "Činnosti holdingových společností",
    "6420": "Činnosti holdingových společností",
    "643": "Činnosti trustů, fondů a podobných finančních subjektů",
    "6430": "Činnosti trustů, fondů a podobných finančních subjektů",
    "649": "Ostatní finanční zprostředkování",
    "6491": "Finanční leasing",
    "6492": "Ostatní poskytování úvěrů",
    "64921": "Poskytování úvěrů společnostmi, které nepřijímají vklady",
    "64922": "Poskytování obchodních úvěrů",
    "64923": "Činnosti zastaváren",
    "64929": "Ostatní poskytování úvěrů j. n.",
    "6499": "Ostatní finanční zprostředkování j. n.",
    "64991": "Faktoringové činnosti",
    "64992": "Obchodování s cennými papíry na vlastní účet",
    "64999": "Jiné finanční zprostředkování j. n.",
    "650": "Pojištění, zajištění a penzijní financování, kromě povinného sociálního zabezpečení",
    "651": "Pojištění",
    "6511": "Životní pojištění",
    "6512": "Neživotní pojištění",
    "652": "Zajištění",
    "6520": "Zajištění",
    "653": "Penzijní financování",
    "6530": "Penzijní financování",
    "660": "Ostatní finanční činnosti",
    "661": "Pomocné činnosti související s finančním zprostředkováním, kromě pojišťovnictví a penzijního financování",
    "6611": "Řízení a správa finančních trhů",
    "6612": "Obchodování s cennými papíry a komoditami na burzách",
    "6619": "Ostatní pomocné činnosti související s finančním zprostředkováním",
    "662": "Pomocné činnosti související s pojišťovnictvím a penzijním financováním",
    "6621": "Vyhodnocování rizik a škod",
    "6622": "Činnosti zástupců pojišťovny a makléřů",
    "6629": "Ostatní pomocné činnosti související s pojišťovnictvím a penzijním financováním",
    "663": "Správa fondů",
    "6630": "Správa fondů",
    "680": "Činnosti v oblasti nemovitostí",
    "681": "Nákup a následný prodej vlastních nemovitostí",
    "6810": "Nákup a následný prodej vlastních nemovitostí",
    "682": "Pronájem a správa vlastních nebo pronajatých nemovitostí",
    "6820": "Pronájem a správa vlastních nebo pronajatých nemovitostí",
    "68201": "Pronájem vlastních nebo pronajatých nemovitostí s bytovými prostory",
    "68202": "Pronájem vlastních nebo pronajatých nemovitostí s nebytovými prostory",
    "68203": "Správa vlastních nebo pronajatých nemovitostí s bytovými prostory ",
    "68204": "Správa vlastních nebo pronajatých nemovitostí s nebytovými prostory",
    "683": "Činnosti v oblasti nemovitostí na základě smlouvy nebo dohody",
    "6831": "Zprostředkovatelské činnosti realitních agentur",
    "6832": "Správa nemovitostí na základě smlouvy",
    "690": "Právní a účetnické činnosti",
    "691": "Právní činnosti",
    "6910": "Právní činnosti",
    "692": "Účetnické a auditorské činnosti; daňové poradenství",
    "6920": "Účetnické a auditorské činnosti; daňové poradenství",
    "700": "Činnosti vedení podniků; poradenství v oblasti řízení",
    "701": "Činnosti vedení podniků",
    "7010": "Činnosti vedení podniků",
    "702": "Poradenství v oblasti řízení",
    "7021": "Poradenství v oblasti vztahů s veřejností a komunikace",
    "7022": "Ostatní poradenství v oblasti podnikání a řízení",
    "710": "Architektonické a inženýrské činnosti; technické zkoušky a analýzy",
    "711": "Architektonické a inženýrské činnosti a související technické poradenství",
    "7111": "Architektonické činnosti",
    "7112": "Inženýrské činnosti a související technické poradenství",
    "71121": "Geologický průzkum",
    "71122": "Zeměměřické a kartografické činnosti",
    "71123": "Hydrometeorologické a meteorologické činnosti",
    "71129": "Ostatní inženýrské činnosti a související technické poradenství j. n.",
    "712": "Technické zkoušky a analýzy",
    "7120": "Technické zkoušky a analýzy",
    "71201": "Zkoušky a analýzy vyhrazených technických zařízení",
    "71209": "Ostatní technické zkoušky a analýzy",
    "720": "Výzkum a vývoj",
    "721": "Výzkum a vývoj v oblasti přírodních a technických věd",
    "7211": "Výzkum a vývoj v oblasti biotechnologie",
    "7219": "Ostatní výzkum a vývoj v oblasti přírodních a technických věd",
    "72191": "Výzkum a vývoj v oblasti lékařských věd ",
    "72192": "Výzkum a vývoj v oblasti technických věd",
    "72199": "Výzkum a vývoj v oblasti jiných přírodních věd",
    "722": "Výzkum a vývoj v oblasti společenských a humanitních věd",
    "7220": "Výzkum a vývoj v oblasti společenských a humanitních věd",
    "730": "Reklama a průzkum trhu",
    "731": "Reklamní činnosti",
    "7311": "Činnosti reklamních agentur",
    "7312": "Zastupování médií při prodeji reklamního času a prostoru",
    "732": "Průzkum trhu a veřejného mínění",
    "7320": "Průzkum trhu a veřejného mínění",
    "740": "Ostatní profesní, vědecké a technické činnosti",
    "741": "Specializované návrhářské činnosti ",
    "7410": "Specializované návrhářské činnosti ",
    "742": "Fotografické činnosti",
    "7420": "Fotografické činnosti",
    "743": "Překladatelské a tlumočnické činnosti",
    "7430": "Překladatelské a tlumočnické činnosti",
    "749": "Ostatní profesní, vědecké a technické činnosti j. n.",
    "7490": "Ostatní profesní, vědecké a technické činnosti j. n.",
    "74901": "Poradenství v oblasti bezpečnosti a ochrany zdraví při práci",
    "74902": "Poradenství v oblasti požární ochrany",
    "74909": "Jiné profesní, vědecké a technické činnosti j. n.",
    "750": "Veterinární činnosti",
    "7500": "Veterinární činnosti",
    "770": "Činnosti v oblasti pronájmu a operativního leasingu",
    "771": "Pronájem a leasing motorových vozidel, kromě motocyklů",
    "7711": "Pronájem a leasing automobilů a jiných lehkých motorových vozidel, kromě motocyklů",
    "7712": "Pronájem a leasing nákladních automobilů",
    "772": "Pronájem a leasing výrobků pro osobní potřebu a převážně pro domácnost",
    "7721": "Pronájem a leasing rekreačních a sportovních potřeb",
    "7722": "Pronájem videokazet a disků",
    "7729": "Pronájem a leasing ostatních výrobků pro osobní potřebu a převážně pro domácnost",
    "773": "Pronájem a leasing ostatních strojů, zařízení a výrobků ",
    "7731": "Pronájem a leasing zemědělských strojů a zařízení",
    "7732": "Pronájem a leasing stavebních strojů a zařízení ",
    "7733": "Pronájem a leasing kancelářských strojů a zařízení, včetně počítačů",
    "7734": "Pronájem a leasing vodních dopravních prostředků",
    "7735": "Pronájem a leasing leteckých dopravních prostředků",
    "7739": "Pronájem a leasing ostatních strojů, zařízení a výrobků j. n.",
    "774": "Leasing duševního vlastnictví a podobných produktů, kromě děl chráněných autorským právem",
    "7740": "Leasing duševního vlastnictví a podobných produktů, kromě děl chráněných autorským právem",
    "780": "Činnosti související se zaměstnáním",
    "781": "Činnosti agentur zprostředkujících zaměstnání",
    "7810": "Činnosti agentur zprostředkujících zaměstnání",
    "782": "Činnosti agentur zprostředkujících práci na přechodnou dobu",
    "7820": "Činnosti agentur zprostředkujících práci na přechodnou dobu",
    "783": "Ostatní poskytování lidských zdrojů ",
    "7830": "Ostatní poskytování lidských zdrojů",
    "790": "Činnosti cestovních agentur, kanceláří a jiné rezervační a související činnosti",
    "791": "Činnosti cestovních agentur a cestovních kanceláří",
    "7911": "Činnosti cestovních agentur ",
    "7912": "Činnosti cestovních kanceláří",
    "799": "Ostatní rezervační a související činnosti",
    "7990": "Ostatní rezervační a související činnosti",
    "79901": "Průvodcovské činnosti",
    "79909": "Ostatní rezervační a související činnosti j. n.",
    "800": "Bezpečnostní a pátrací činnosti",
    "801": "Činnosti soukromých bezpečnostních agentur",
    "8010": "Činnosti soukromých bezpečnostních agentur",
    "802": "Činnosti související s provozem bezpečnostních systémů",
    "8020": "Činnosti související s provozem bezpečnostních systémů",
    "803": "Pátrací činnosti",
    "8030": "Pátrací činnosti ",
    "810": "Činnosti související se stavbami a úpravou krajiny ",
    "811": "Kombinované pomocné činnosti ",
    "8110": "Kombinované pomocné činnosti ",
    "812": "Úklidové činnosti",
    "8121": "Všeobecný úklid budov",
    "8122": "Specializované čištění a úklid budov a průmyslových zařízení",
    "8129": "Ostatní úklidové činnosti",
    "813": "Činnosti související s úpravou krajiny ",
    "8130": "Činnosti související s úpravou krajiny",
    "820": "Administrativní, kancelářské a jiné podpůrné činnosti pro podnikání",
    "821": "Administrativní a kancelářské činnosti",
    "8211": "Univerzální administrativní činnosti ",
    "8219": "Kopírování, příprava dokumentů a ostatní specializované kancelářské podpůrné činnosti ",
    "822": "Činnosti zprostředkovatelských středisek",
    "8220": "Činnosti zprostředkovatelských středisek po telefonu",
    "823": "Pořádání konferencí a hospodářských výstav",
    "8230": "Pořádání konferencí a hospodářských výstav",
    "829": "Podpůrné činnosti pro podnikání j. n.",
    "8291": "Inkasní činnosti, ověřování solventnosti zákazníka",
    "8292": "Balicí činnosti",
    "8299": "Ostatní podpůrné činnosti pro podnikání j. n.",
    "840": "Veřejná správa a obrana; povinné sociální zabezpečení",
    "841": "Veřejná správa a hospodářská a sociální politika",
    "8411": "Všeobecné činnosti veřejné správy",
    "8412": "Regulace činností souvisejících s poskytováním zdravotní péče, vzděláváním, kulturou a sociální péčí, kromě sociálního zabezpečení",
    "8413": "Regulace a podpora podnikatelského prostředí",
    "842": "Činnosti pro společnost jako celek",
    "8421": "Činnosti v oblasti zahraničních věcí",
    "84211": "Pomoc cizím zemím při katastrofách nebo v nouzových situacích přímo nebo prostřednictvím mezinárodních organizací",
    "84212": "Rozvíjení vzájemného přátelství a porozumění mezi národy",
    "84219": "Ostatní činnosti v oblasti zahraničních věcí",
    "8422": "Činnosti v oblasti obrany",
    "8423": "Činnosti v oblasti spravedlnosti a soudnictví",
    "8424": "Činnosti v oblasti veřejného pořádku a bezpečnosti ",
    "8425": "Činnosti v oblasti protipožární ochrany",
    "843": "Činnosti v oblasti povinného sociálního zabezpečení",
    "8430": "Činnosti v oblasti povinného sociálního zabezpečení",
    "850": "Vzdělávání",
    "851": "Předškolní vzdělávání",
    "8510": "Předškolní vzdělávání",
    "852": "Primární vzdělávání",
    "8520": "Primární vzdělávání",
    "853": "Sekundární vzdělávání",
    "8531": "Sekundární všeobecné vzdělávání",
    "85311": "Základní vzdělávání na druhém stupni základních škol",
    "85312": "Střední všeobecné vzdělávání",
    "8532": "Sekundární odborné vzdělávání",
    "85321": "Střední odborné vzdělávání na učilištích",
    "85322": "Střední odborné vzdělávání na středních odborných školách",
    "854": "Postsekundární vzdělávání",
    "8541": "Postsekundární nikoli terciární vzdělávání",
    "8542": "Terciární vzdělávání",
    "855": "Ostatní vzdělávání",
    "8551": "Sportovní a rekreační vzdělávání",
    "8552": "Umělecké vzdělávání",
    "8553": "Činnosti autoškol a jiných škol řízení",
    "85531": "Činnosti autoškol",
    "85532": "Činnosti leteckých škol",
    "85539": "Činnosti ostatních škol řízení",
    "8559": "Ostatní vzdělávání j. n.",
    "85591": "Vzdělávání v jazykových školách",
    "85592": "Environmentální vzdělávání",
    "85593": "Inovační vzdělávání",
    "85599": "Jiné vzdělávání j. n.",
    "856": "Podpůrné činnosti ve vzdělávání",
    "8560": "Podpůrné činnosti ve vzdělávání",
    "860": "Zdravotní péče",
    "861": "Ústavní zdravotní péče",
    "8610": "Ústavní zdravotní péče",
    "862": "Ambulantní a zubní zdravotní péče",
    "8621": "Všeobecná ambulantní zdravotní péče",
    "8622": "Specializovaná ambulantní zdravotní péče",
    "8623": "Zubní péče",
    "869": "Ostatní činnosti související se zdravotní péčí",
    "8690": "Ostatní činnosti související se zdravotní péčí",
    "86901": "Činnosti související s ochranou veřejného zdraví",
    "86909": "Ostatní činnosti související se zdravotní péčí j. n.",
    "870": "Pobytové služby sociální péče",
    "871": "Sociální péče ve zdravotnických zařízeních ústavní péče",
    "8710": "Sociální péče ve zdravotnických zařízeních ústavní péče",
    "872": "Sociální péče v zařízeních pro osoby s chronickým duševním onemocněním a osoby závislé na návykových látkách",
    "8720": "Sociální péče v zařízeních pro osoby s chronickým duševním onemocněním a osoby závislé na návykových látkách",
    "87201": "Sociální péče v zařízeních pro osoby s chronickým duševním onemocněním",
    "87202": "Sociální péče v zařízeních pro osoby závislé na návykových látkách",
    "873": "Sociální péče v domovech pro seniory a osoby se zdravotním postižením",
    "8730": "Sociální péče v domovech pro seniory a osoby se zdravotním postižením",
    "87301": "Sociální péče v domovech pro seniory",
    "87302": "Sociální péče v domovech pro osoby se zdravotním postižením",
    "879": "Ostatní pobytové služby sociální péče",
    "8790": "Ostatní pobytové služby sociální péče",
    "880": "Ambulantní nebo terénní sociální služby",
    "881": "Ambulantní nebo terénní sociální služby pro seniory a osoby se zdravotním postižením",
    "8810": "Ambulantní nebo terénní sociální služby pro seniory a osoby se zdravotním postižením",
    "88101": "Ambulantní nebo terénní sociální služby pro seniory",
    "88102": "Ambulantní nebo terénní sociální služby pro osoby se zdravotním postižením",
    "889": "Ostatní ambulantní nebo terénní sociální služby",
    "8891": "Sociální služby poskytované dětem",
    "8899": "Ostatní ambulantní nebo terénní sociální služby j. n.",
    "88991": "Sociální služby pro uprchlíky, oběti katastrof",
    "88992": "Sociální prevence",
    "88993": "Sociální rehabilitace",
    "88999": "Jiné ambulantní nebo terénní sociální služby j. n.",
    "900": "Tvůrčí, umělecké a zábavní činnosti",
    "9001": "Scénická umění",
    "9002": "Podpůrné činnosti pro scénická umění",
    "9003": "Umělecká tvorba",
    "9004": "Provozování kulturních zařízení",
    "910": "Činnosti knihoven, archivů, muzeí a jiných kulturních zařízení",
    "9101": "Činnosti knihoven a archivů",
    "9102": "Činnosti muzeí",
    "9103": "Provozování kulturních památek, historických staveb a obdobných turistických zajímavostí",
    "9104": "Činnosti botanických a zoologických zahrad, přírodních rezervací a národních parků",
    "91041": "Činnosti botanických a zoologických zahrad",
    "91042": "Činnosti přírodních rezervací a národních parků",
    "920": "Činnosti heren, kasin a sázkových kanceláří",
    "9200": "Činnosti heren, kasin a sázkových kanceláří",
    "930": "Sportovní, zábavní a rekreační činnosti ",
    "931": "Sportovní činnosti",
    "9311": "Provozování sportovních zařízení",
    "9312": "Činnosti sportovních klubů",
    "9313": "Činnosti fitcenter",
    "9319": "Ostatní sportovní činnosti",
    "932": "Ostatní zábavní a rekreační činnosti",
    "9321": "Činnosti lunaparků a zábavních parků",
    "9329": "Ostatní zábavní a rekreační činnosti j. n.",
    "940": "Činnosti organizací sdružujících osoby za účelem prosazování společných zájmů",
    "941": "Činnosti podnikatelských, zaměstnavatelských a profesních organizací",
    "9411": "Činnosti podnikatelských a zaměstnavatelských organizací",
    "9412": "Činnosti profesních organizací",
    "942": "Činnosti odborových svazů",
    "9420": "Činnosti odborových svazů",
    "949": "Činnosti ostatních organizací sdružujících osoby za účelem prosazování společných zájmů",
    "9491": "Činnosti náboženských organizací",
    "9492": "Činnosti politických stran a organizací",
    "9499": "Činnosti ostatních organizací sdružujících osoby za účelem prosazování společných zájmů j. n.",
    "94991": "Činnosti organizací dětí a mládeže ",
    "94992": "Činnosti organizací na podporu kulturní činnosti",
    "94993": "Činnosti organizací na podporu rekreační a zájmové činnosti",
    "94994": "Činnosti spotřebitelských organizací",
    "94995": "Činnosti environmentálních a ekologických hnutí",
    "94996": "Činnosti organizací na ochranu a zlepšení postavení etnických, menšinových a jiných speciálních skupin",
    "94997": "Činnosti občanských iniciativ, protestních hnutí",
    "94999": "Činnosti ostatních organizací j. n.",
    "950": "Opravy počítačů a výrobků pro osobní potřebu a převážně pro domácnost",
    "951": "Opravy počítačů a komunikačních zařízení",
    "9511": "Opravy počítačů a periferních zařízení",
    "9512": "Opravy komunikačních zařízení",
    "952": "Opravy výrobků pro osobní potřebu a převážně pro domácnost",
    "9521": "Opravy spotřební elektroniky ",
    "9522": "Opravy přístrojů a zařízení převážně pro domácnost, dům a zahradu",
    "9523": "Opravy obuvi a kožených výrobků",
    "9524": "Opravy nábytku a bytového zařízení",
    "9525": "Opravy hodin, hodinek a klenotnických výrobků",
    "9529": "Opravy ostatních výrobků pro osobní potřebu a převážně pro domácnost",
    "960": "Poskytování ostatních osobních služeb",
    "9601": "Praní a chemické čištění textilních a kožešinových výrobků",
    "9602": "Kadeřnické, kosmetické a podobné činnosti ",
    "9603": "Pohřební a související činnosti ",
    "9604": "Činnosti pro osobní a fyzickou pohodu",
    "9609": "Poskytování ostatních osobních služeb j. n.",
    "970": "Činnosti domácností jako zaměstnavatelů domácího personálu ",
    "9700": "Činnosti domácností jako zaměstnavatelů domácího personálu ",
    "980": "Činnosti domácností produkujících blíže neurčené výrobky a služby pro vlastní potřebu",
    "981": "Činnosti domácností produkujících blíže neurčené výrobky pro vlastní potřebu",
    "9810": "Činnosti domácností produkujících blíže neurčené výrobky pro vlastní potřebu",
    "982": "Činnosti domácností poskytujících blíže neurčené služby pro vlastní potřebu",
    "9820": "Činnosti domácností poskytujících blíže neurčené služby pro vlastní potřebu",
    "990": "Činnosti exteritoriálních organizací a orgánů",
    "9900": "Činnosti exteritoriálních organizací a orgánů"
}

export const EmployeeList = [
    { kodPocetZamestnancov: '0', kategoria: 'Neuvedeno' },
    { kodPocetZamestnancov: '110', kategoria: 'Bez zaměstnanců' },
    { kodPocetZamestnancov: '120', kategoria: '1 - 5 zaměstnanců' },
    { kodPocetZamestnancov: '130', kategoria: '6 - 9 zaměstnanců' },
    { kodPocetZamestnancov: '210', kategoria: '10 - 19 zaměstnanců' },
    { kodPocetZamestnancov: '220', kategoria: '20 - 24 zaměstnanci' },
    { kodPocetZamestnancov: '230', kategoria: '25 - 49 zaměstnanců' },
    { kodPocetZamestnancov: '240', kategoria: '50 - 99 zaměstnanců' },
    { kodPocetZamestnancov: '310', kategoria: '100 - 199 zaměstnanců' },
    { kodPocetZamestnancov: '320', kategoria: '200 - 249 zaměstnanců' },
    { kodPocetZamestnancov: '330', kategoria: '250 - 499 zaměstnanců' },
    { kodPocetZamestnancov: '340', kategoria: '500 - 999 zaměstnanců' },
    { kodPocetZamestnancov: '410', kategoria: '1000 - 1499 zaměstnanců' },
    { kodPocetZamestnancov: '420', kategoria: '1500 - 1999 zaměstnanců' },
    { kodPocetZamestnancov: '430', kategoria: '2000 - 2499 zaměstnanců' },
    { kodPocetZamestnancov: '440', kategoria: '2500 - 2999 zaměstnanců' },
    { kodPocetZamestnancov: '450', kategoria: '3000 - 3999 zaměstnanců' },
    { kodPocetZamestnancov: '460', kategoria: '4000 - 4999 zaměstnanců' },
    { kodPocetZamestnancov: '470', kategoria: '5000 - 9999 zaměstnanců' },
    { kodPocetZamestnancov: '510', kategoria: '10 000 a více zaměstnanců' }
];