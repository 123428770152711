import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Accordion,
    Dimmer,
    Dropdown,
    Form,
    Icon,
    Input,
    Loader,
    Table,
    TextArea
} from 'semantic-ui-react';
import { ConfigProvider, DatePicker } from "antd";
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from "moment";
import { applianceOptions, buildingLoadOptions, constructionOptions, constructionYearOptions, energyClassOptions, facadeInsulationOptions, glassLayersOptions, glazingOptions, heatingSourceOptions, insulationTypeOptions1, insulationTypeOptions2, lightingSourceOptions, purposeOptions, roofInsulationOptions, usersCountOptions, waterHeatingOptions, windowTypeOptions } from './Options';

const BuildingCardMutator = (props) => {
    const [activeIndex, setActiveIndex] = useState(props.activeIndex);
    const [listenerAdded, setListenerAdded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openCard, setOpenCard] = useState(props.openCard);
    const handleClick = (index) => {
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };
    const containerRef = useRef(null);

    useEffect(async () => {
        if (props.setActiveIndex && activeIndex !== props.activeIndex) {
            await handleChange()
            props.setActiveIndex(activeIndex, props.index)
        }
    }, [activeIndex])

    useEffect(async () => {
        if (props.setOpenCard && openCard !== props.openCard) {
            await handleChange()
            props.setOpenCard(openCard, props.index)
        }
    }, [openCard])

    const toogleCard = () => {
        setOpenCard(!openCard);
    };

    const [building, setBuilding] = useState(props.building)

    const changeBuilding = useCallback((e) => {
        const { type, name, checked, value } = e.target;
    
        setBuilding((prevBuilding) => {
            const updatedBuilding = { ...prevBuilding };
            const path = name.split(".");
    
            if (type === "checkbox") {
                if (path.length === 2) {
                    updatedBuilding[path[0]] = {
                        ...updatedBuilding[path[0]],
                        [path[1]]: checked,
                    };
                } else {
                    updatedBuilding[name] = checked;
                }
            } else {
                if (path.length === 2) {
                    updatedBuilding[path[0]] = {
                        ...updatedBuilding[path[0]],
                        [path[1]]: value,
                    };
                } else {
                    updatedBuilding[name] = value;
                }
            }
    
            return updatedBuilding;
        });
    }, [building]);

    const changeDropdownValue = useCallback((e, data) => {
        if (e.type === 'click') {
            setBuilding(prevFieldValues => {
                const updatedBuilding = { ...prevFieldValues };
                const path = data.name.split(".");

                if (path.length === 2) {
                    updatedBuilding[path[0]] = {
                        ...updatedBuilding[path[0]],
                        [path[1]]: data.value
                    };
                } else {
                    updatedBuilding[data.name] = data.value;
                }
                // props.handleChange(updatedBuilding, props.index)
                return updatedBuilding;
            });
        }
    }, [building]);

    useEffect(() => {
        let isMounted = true; // Flag to track if the component is still mounted

        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                if (isMounted) {
                    handleChange();
                    document.removeEventListener('mousedown', handleClickOutside);
                    setListenerAdded(false);
                }
            }
        };

        if (listenerAdded) {
            document.addEventListener('mousedown', handleClickOutside);
            console.log("listener added")
        }

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            isMounted = false;
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [listenerAdded, building]);

    const handleContainerClick = () => {
        console.log(listenerAdded)
        if (!listenerAdded) {
            setListenerAdded(true);
        }
    };

    const handleChange = async () => {
        if (props.handleChange) {
            console.log("handle change started")
            setTimeout(async () => {
                setLoading(true);
                // Simulate a delay
                await new Promise((resolve) => setTimeout(resolve, 10));

                await props.handleChange(building, props.index);

                setLoading(false);
                console.log("loading state set to false");
                console.log("handle change ended");
            }, 0);
        }
    }

    function sum(...numbers) {
        return numbers.reduce((acc, curr) => {
            const num = (curr === undefined || isNaN(curr)) ? 0 : Number(curr);
            return acc + num;
        }, 0);
    }

    const heatingConsumption1 = building?.heatingWaterHeating?.heatingSourcePower1 && building?.heatingWaterHeating?.heatingRunning1 ? (parseFloat(building.heatingWaterHeating?.heatingSourcePower1) * parseFloat(building?.heatingWaterHeating?.heatingRunning1)).toFixed(2) : '';
    const heatingConsumption2 = building?.heatingWaterHeating?.heatingSourcePower2 && building?.heatingWaterHeating?.heatingRunning2 ? (parseFloat(building.heatingWaterHeating?.heatingSourcePower2) * parseFloat(building?.heatingWaterHeating?.heatingRunning2)).toFixed(2) : '';
    const waterHeatingConsumption1 = building?.heatingWaterHeating?.waterHeatingSourcePower1 && building?.heatingWaterHeating?.waterHeatingRunning1 ? (parseFloat(building.heatingWaterHeating?.waterHeatingSourcePower1) * parseFloat(building?.heatingWaterHeating?.waterHeatingRunning1)).toFixed(2) : '';
    const waterHeatingConsumption2 = building?.heatingWaterHeating?.waterHeatingSourcePower2 && building?.heatingWaterHeating?.waterHeatingRunning2 ? (parseFloat(building.heatingWaterHeating?.waterHeatingSourcePower2) * parseFloat(building?.heatingWaterHeating?.waterHeatingRunning2)).toFixed(2) : '';
    const heatingExpenses1 = building?.heatingWaterHeating?.heatingRunning1 && building?.heatingWaterHeating?.heatingPrice1 ? (parseFloat(building.heatingWaterHeating?.heatingRunning1) * parseFloat(building?.heatingWaterHeating?.heatingPrice1)).toFixed(2) : '';
    const heatingExpenses2 = building?.heatingWaterHeating?.heatingRunning2 && building?.heatingWaterHeating?.heatingPrice2 ? (parseFloat(building.heatingWaterHeating?.heatingRunning2) * parseFloat(building?.heatingWaterHeating?.heatingPrice2)).toFixed(2) : '';
    const waterHeatingExpenses1 = building?.heatingWaterHeating?.waterHeatingRunning1 && building?.heatingWaterHeating?.waterHeatingPrice1 ? (parseFloat(building.heatingWaterHeating?.heatingRunning1) * parseFloat(building?.heatingWaterHeating?.waterHeatingPrice1)).toFixed(2) : '';
    const waterHeatingExpenses2 = building?.heatingWaterHeating?.waterHeatingRunning2 && building?.heatingWaterHeating?.waterHeatingPrice2 ? (parseFloat(building.heatingWaterHeating?.heatingRunning2) * parseFloat(building?.heatingWaterHeating?.waterHeatingPrice2)).toFixed(2) : '';

    const lightingSource1Costs = building?.interiorLightingAppliances?.lightingSource1PowerConsumption && building?.interiorLightingAppliances?.lightingSource1NumberPieces && building?.interiorLightingAppliances?.lightingSource1Running && building?.interiorLightingAppliances?.lightingSource1Consumption && building?.interiorLightingAppliances?.lightingSource1Price
        ? (parseFloat(building.interiorLightingAppliances?.lightingSource1PowerConsumption) * parseFloat(building?.interiorLightingAppliances?.lightingSource1NumberPieces) * parseFloat(building?.interiorLightingAppliances?.lightingSource1Running) * parseFloat(building?.interiorLightingAppliances?.lightingSource1Consumption) * parseFloat(building?.interiorLightingAppliances?.lightingSource1Price)).toFixed(2) : '';
    const lightingSource2Costs = building?.interiorLightingAppliances?.lightingSource2PowerConsumption && building?.interiorLightingAppliances?.lightingSource2NumberPieces && building?.interiorLightingAppliances?.lightingSource2Running && building?.interiorLightingAppliances?.lightingSource2Consumption && building?.interiorLightingAppliances?.lightingSource2Price
        ? (parseFloat(building.interiorLightingAppliances?.lightingSource2PowerConsumption) * parseFloat(building?.interiorLightingAppliances?.lightingSource2NumberPieces) * parseFloat(building?.interiorLightingAppliances?.lightingSource2Running) * parseFloat(building?.interiorLightingAppliances?.lightingSource2Consumption) * parseFloat(building?.interiorLightingAppliances?.lightingSource2Price)).toFixed(2) : '';
    const powerConsumptionOverall = sum(parseFloat(building?.interiorLightingAppliances?.powerConsumption1), parseFloat(building?.interiorLightingAppliances?.powerConsumption2), parseFloat(building?.interiorLightingAppliances?.powerConsumption3), parseFloat(building?.interiorLightingAppliances?.powerConsumption4), parseFloat(building?.interiorLightingAppliances?.powerConsumption5), parseFloat(building?.interiorLightingAppliances?.powerConsumption6), parseFloat(building?.interiorLightingAppliances?.powerConsumption7), parseFloat(building?.interiorLightingAppliances?.powerConsumption8), parseFloat(building?.interiorLightingAppliances?.powerConsumption9), parseFloat(building?.interiorLightingAppliances?.powerConsumption10), parseFloat(building?.interiorLightingAppliances?.powerConsumption11), parseFloat(building?.interiorLightingAppliances?.powerConsumption12));
    const runningOverall = sum(parseFloat(building?.interiorLightingAppliances?.running1), parseFloat(building?.interiorLightingAppliances?.running2), parseFloat(building?.interiorLightingAppliances?.running3), parseFloat(building?.interiorLightingAppliances?.running4), parseFloat(building?.interiorLightingAppliances?.running5), parseFloat(building?.interiorLightingAppliances?.running6), parseFloat(building?.interiorLightingAppliances?.running7), parseFloat(building?.interiorLightingAppliances?.running8), parseFloat(building?.interiorLightingAppliances?.running9), parseFloat(building?.interiorLightingAppliances?.running10), parseFloat(building?.interiorLightingAppliances?.running11), parseFloat(building?.interiorLightingAppliances?.running12));
    const consumptionOverall = sum(parseFloat(building?.interiorLightingAppliances?.consumption1), parseFloat(building?.interiorLightingAppliances?.consumption2), parseFloat(building?.interiorLightingAppliances?.consumption3), parseFloat(building?.interiorLightingAppliances?.consumption4), parseFloat(building?.interiorLightingAppliances?.consumption5), parseFloat(building?.interiorLightingAppliances?.consumption6), parseFloat(building?.interiorLightingAppliances?.consumption7), parseFloat(building?.interiorLightingAppliances?.consumption8), parseFloat(building?.interiorLightingAppliances?.consumption9), parseFloat(building?.interiorLightingAppliances?.consumption10), parseFloat(building?.interiorLightingAppliances?.consumption11), parseFloat(building?.interiorLightingAppliances?.consumption12));

    const shareDistrComposition1 = building?.collectionPoint1?.distributionPrice && building?.collectionPoint1?.totalCost ? (parseFloat(building.collectionPoint1?.distributionPrice) / parseFloat(building.collectionPoint1?.totalCost)).toFixed(2) : '';
    const shareDistrComposition2 = building?.collectionPoint2?.distributionPrice && building?.collectionPoint2?.totalCost ? (parseFloat(building.collectionPoint2?.distributionPrice) / parseFloat(building.collectionPoint2?.totalCost)).toFixed(2) : '';
    const distributionPrice1 = building?.collectionPoint1?.totalCost && building?.collectionPoint1?.priceBusinessPart ? (parseFloat(building.collectionPoint1?.totalCost) - parseFloat(building.collectionPoint1?.priceBusinessPart)).toFixed(2) : '';
    const distributionPrice2 = building?.collectionPoint2?.totalCost && building?.collectionPoint2?.priceBusinessPart ? (parseFloat(building.collectionPoint2?.totalCost) - parseFloat(building.collectionPoint2?.priceBusinessPart)).toFixed(2) : '';
    const collectionPointPrice1 = building?.collectionPoint1?.totalCost && building?.collectionPoint1?.nt && building?.collectionPoint1?.vt ? (parseFloat(building.collectionPoint1?.totalCost) / (parseFloat(building.collectionPoint1?.nt) + parseFloat(building.collectionPoint1?.vt))).toFixed(2) : '';
    const collectionPointPrice2 = building?.collectionPoint2?.totalCost && building?.collectionPoint2?.nt && building?.collectionPoint2?.vt ? (parseFloat(building.collectionPoint2?.totalCost) / (parseFloat(building.collectionPoint2?.nt) + parseFloat(building.collectionPoint2?.vt))).toFixed(2) : '';

    return (
        <div onMouseDown={handleContainerClick} ref={containerRef} className='building-card-container'>
            <Dimmer.Dimmable dimmed={loading}>
                <Dimmer active={loading} inverted>
                    <Loader>Ukládám</Loader>
                </Dimmer>
                <div className='title-chevron-container'>
                    <div>
                    <Input value={props.buildingName} label='Budova' />
                        <Input value={building?.address ? [building.address.address.house_number, building.address.address.village, building.address.address.municipality] : ""} label='Adresa' />
                    </div>
                    <Icon onClick={toogleCard} name={openCard ? 'angle up' : 'angle down'} />
                </div>
                {openCard &&
                    <>
                        <Accordion>
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={() => handleClick(0)}
                            >
                                <Icon name='dropdown' />
                                Lokalizace a účel budovy
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 0}>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Obec</Table.HeaderCell>
                                            <Table.HeaderCell>Ulice</Table.HeaderCell>
                                            <Table.HeaderCell>Číslo popisné</Table.HeaderCell>
                                            <Table.HeaderCell>Parcela</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input fluid value={building?.location?.town ? building.location?.town : ""} name={"location.town"} onChange={changeBuilding} /></Table.Cell>
                                            <Table.Cell><Input fluid value={building?.location?.street ? building.location?.street : ""} name={"location.street"} onChange={changeBuilding} /></Table.Cell>
                                            <Table.Cell><Input fluid value={building?.location?.descriptiveNumber ? building.location?.descriptiveNumber : ""} name={"location.descriptiveNumber"} onChange={changeBuilding} /></Table.Cell>
                                            <Table.Cell><Input fluid value={building?.location?.parcel ? building.location?.parcel : ""} name={"location.parcel"} onChange={changeBuilding} /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Účel</Table.HeaderCell>
                                            <Table.HeaderCell colSpan='3'>
                                                <Dropdown
                                                    name={"location.purpose"}
                                                    value={building?.location?.purpose ? building.location?.purpose : ''}
                                                    onChange={changeDropdownValue}
                                                    options={purposeOptions}
                                                    fluid
                                                    selection>
                                                </Dropdown>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Rok výstavby (rámcový)</Table.HeaderCell>
                                            <Table.HeaderCell>Počet uživatelů</Table.HeaderCell>
                                            <Table.HeaderCell>Zátěž budovy (dny)</Table.HeaderCell>
                                            <Table.HeaderCell>Provozní doba (hod)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Dropdown
                                                    name={"location.constructionYear"}
                                                    value={building?.location?.constructionYear ? building.location?.constructionYear : ""}
                                                    onChange={changeDropdownValue}
                                                    options={constructionYearOptions}
                                                    fluid
                                                    selection>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown
                                                    name={"location.usersCount"}
                                                    value={building?.location?.usersCount ? building.location?.usersCount : ""}
                                                    onChange={changeDropdownValue}
                                                    options={usersCountOptions}
                                                    fluid
                                                    selection>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown
                                                    name={"location.buildingLoad"}
                                                    value={building?.location?.buildingLoad ? building.location?.buildingLoad : ""}
                                                    onChange={changeDropdownValue}
                                                    options={buildingLoadOptions}
                                                    fluid
                                                    selection>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell><Input value={building?.location?.workingHours ? building.location?.workingHours : ""} name={"location.workingHours"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeIndex === 1}
                                index={1}
                                onClick={() => handleClick(1)}
                            >
                                <Icon name='dropdown' />
                                Technický popis
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 1}>
                                <Table celled className='technical-description-table'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan="2">Konstrukce budovy</Table.HeaderCell>
                                            <Table.HeaderCell>Zateplení střechy</Table.HeaderCell>
                                            <Table.HeaderCell>Zateplení fasády</Table.HeaderCell>
                                            <Table.HeaderCell>Typ oken</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell colSpan="2">
                                                <Dropdown name={"technicalDescription.construction"}
                                                    value={building?.technicalDescription?.construction ? building.technicalDescription?.construction : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={constructionOptions}
                                                >
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"technicalDescription.roofInsulation"}
                                                    value={building?.technicalDescription?.roofInsulation ? building.technicalDescription?.roofInsulation : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={roofInsulationOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"technicalDescription.facadeInsulation"}
                                                    value={building?.technicalDescription?.facadeInsulation ? building.technicalDescription?.facadeInsulation : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={facadeInsulationOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"technicalDescription.windowType"}
                                                    value={building?.technicalDescription?.windowType ? building.technicalDescription?.windowType : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={windowTypeOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>

                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Podlahová plocha (m2)</Table.HeaderCell>
                                            <Table.HeaderCell>Prosklení budovy (%)</Table.HeaderCell>
                                            <Table.HeaderCell>Typ zateplení</Table.HeaderCell>
                                            <Table.HeaderCell>Typ zateplení</Table.HeaderCell>
                                            <Table.HeaderCell>Počet skel (uvnitř okna)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input fluid value={building?.technicalDescription?.floorArea ? building.technicalDescription.floorArea : ""} name={"technicalDescription.floorArea"} onChange={changeBuilding} /></Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"technicalDescription.glazing"}
                                                    value={building?.technicalDescription?.glazing ? building.technicalDescription?.glazing : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={glazingOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"technicalDescription.insulationType1"}
                                                    value={building?.technicalDescription?.insulationType1 ? building.technicalDescription?.insulationType1 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={insulationTypeOptions1}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"technicalDescription.insulationType2"}
                                                    value={building?.technicalDescription?.insulationType2 ? building.technicalDescription?.insulationType2 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={insulationTypeOptions2}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"technicalDescription.glassLayers"}
                                                    value={building?.technicalDescription?.glassLayers ? building.technicalDescription?.glassLayersOptions : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={glassLayersOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>

                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Výška budovy (m)</Table.HeaderCell>
                                            <Table.HeaderCell>Počet pater (vč. přízemí)</Table.HeaderCell>
                                            <Table.HeaderCell>Tloušťka zateplení (mm)</Table.HeaderCell>
                                            <Table.HeaderCell>Tloušťka zateplení (mm)</Table.HeaderCell>
                                            <Table.HeaderCell>Instalace (rok)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={building?.technicalDescription?.buildingHeight ? building.technicalDescription?.buildingHeight : ""} name={"technicalDescription.buildingHeight"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.technicalDescription?.numberFloors ? building.technicalDescription?.numberFloors : ""} name={"technicalDescription.numberFloors"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.technicalDescription?.roofInsulationThickness ? building.technicalDescription?.roofInsulationThickness : ""} name={"technicalDescription.roofInsulationThickness"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.technicalDescription?.facadesInsulationThickness ? building.technicalDescription?.facadesInsulationThickness : ""} name={"technicalDescription.facadesInsulationThickness"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.technicalDescription?.installation ? building.technicalDescription?.installation : ""} name={"technicalDescription.installation"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeIndex === 2}
                                index={2}
                                onClick={() => handleClick(2)}
                            >
                                <Icon name='dropdown' />
                                Analýza
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 2}>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Tepelná ztráta (kW/rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Energ, hodnota (kWh/m2/rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Energetická třída</Table.HeaderCell>
                                            <Table.HeaderCell>Budova má platný PENB</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={building?.analysis?.heatLoss ? building.analysis.heatLoss : ""} name={"analysis.heatLoss"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.analysis?.energyValue ? building.analysis?.energyValue : ""} name={"analysis.energyValue"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"analysis.energyClass"}
                                                    value={building?.analysis?.energyClass ? building.analysis?.energyClass : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={energyClassOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell style={{ textAlign: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name={"analysis.penb"}
                                                    onChange={changeBuilding}
                                                    checked={building?.analysis?.penb || false}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Primární konzument en.</Table.HeaderCell>
                                            <Table.HeaderCell colSpan="3"><Input value={building?.analysis?.primaryConsumerEnergy ? building.analysis?.primaryConsumerEnergy : ""} name={"analysis.primaryConsumerEnergy"} onChange={changeBuilding} fluid /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Navrhované opatření 1</Table.HeaderCell>
                                            <Table.HeaderCell colSpan="3"><Input value={building?.analysis?.proposedMeasure1 ? building.analysis?.proposedMeasure1 : ""} name={"analysis.proposedMeasure1"} onChange={changeBuilding} fluid /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Navrhované opatření 2</Table.HeaderCell>
                                            <Table.HeaderCell colSpan="3"><Input value={building?.analysis?.proposedMeasure2 ? building.analysis?.proposedMeasure2 : ""} name={"analysis.proposedMeasure2"} onChange={changeBuilding} fluid /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Navrhované opatření 3</Table.HeaderCell>
                                            <Table.HeaderCell colSpan="3"><Input value={building?.analysis?.proposedMeasure3 ? building.analysis?.proposedMeasure3 : ""} name={"analysis.proposedMeasure3"} onChange={changeBuilding} fluid /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                </Table>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeIndex === 3}
                                index={3}
                                onClick={() => handleClick(3)}
                            >
                                <Icon name='dropdown' />
                                Vytápění a ohřev vody
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 3}>
                                <Table celled className='heating-and-water-heating-table'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Zdroj vytápění (1)</Table.HeaderCell>
                                            <Table.HeaderCell>Zdroj vytápění (2)</Table.HeaderCell>
                                            <Table.HeaderCell>Zdroj ohřevu vody (1)</Table.HeaderCell>
                                            <Table.HeaderCell>Zdroj ohřevu vody (2)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Dropdown name={"heatingWaterHeating.heatingSource1"}
                                                    value={building?.heatingWaterHeating?.heatingSource1 ? building.heatingWaterHeating?.heatingSource1 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={heatingSourceOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"heatingWaterHeating.heatingSource2"}
                                                    value={building?.heatingWaterHeating?.heatingSource2 ? building.heatingWaterHeating?.heatingSource2 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={heatingSourceOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"heatingWaterHeating.waterHeating1"}
                                                    value={building?.heatingWaterHeating?.waterHeating1 ? building.heatingWaterHeating?.waterHeating1 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={waterHeatingOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"heatingWaterHeating.waterHeating2"}
                                                    value={building?.heatingWaterHeating?.waterHeating2 ? building.heatingWaterHeating?.waterHeating2 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={waterHeatingOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Rok výroby</Table.HeaderCell>
                                            <Table.HeaderCell>Rok výroby</Table.HeaderCell>
                                            <Table.HeaderCell>Rok výroby</Table.HeaderCell>
                                            <Table.HeaderCell>Rok výroby</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.heatingSourceYearManufacture1 ? building.heatingWaterHeating?.heatingSourceYearManufacture1 : ""} name={"heatingWaterHeating.heatingSourceYearManufacture1"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.heatingSourceYearManufacture2 ? building.heatingWaterHeating?.heatingSourceYearManufacture2 : ""} name={"heatingWaterHeating.heatingSourceYearManufacture2"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.waterHeatingYearManufacture1 ? building.heatingWaterHeating?.waterHeatingYearManufacture1 : ""} name={"heatingWaterHeating.waterHeatingYearManufacture1"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.waterHeatingYearManufacture2 ? building.heatingWaterHeating?.waterHeatingYearManufacture2 : ""} name={"heatingWaterHeating.waterHeatingYearManufacture2"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Výkon (kW)</Table.HeaderCell>
                                            <Table.HeaderCell>Výkon (kW)</Table.HeaderCell>
                                            <Table.HeaderCell>Výkon (kW)</Table.HeaderCell>
                                            <Table.HeaderCell>Výkon (kW)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.heatingSourcePower1 ? building.heatingWaterHeating?.heatingSourcePower1 : ""} name={"heatingWaterHeating.heatingSourcePower1"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.heatingSourcePower2 ? building.heatingWaterHeating?.heatingSourcePower2 : ""} name={"heatingWaterHeating.heatingSourcePower2"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.waterHeatingSourcePower1 ? building.heatingWaterHeating?.waterHeatingSourcePower1 : ""} name={"heatingWaterHeating.waterHeatingSourcePower1"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.waterHeatingSourcePower2 ? building.heatingWaterHeating?.waterHeatingSourcePower2 : ""} name={"heatingWaterHeating.waterHeatingSourcePower2"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Chod (hod./rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Chod (hod./rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Chod (hod./rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Chod (hod./rok)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.heatingRunning1 ? building.heatingWaterHeating?.heatingRunning1 : ""} name={"heatingWaterHeating.heatingRunning1"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.heatingRunning2 ? building.heatingWaterHeating?.heatingRunning2 : ""} name={"heatingWaterHeating.heatingRunning2"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.waterHeatingRunning1 ? building.heatingWaterHeating?.waterHeatingRunning1 : ""} name={"heatingWaterHeating.waterHeatingRunning1"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.heatingWaterHeating?.waterHeatingRunning2 ? building.heatingWaterHeating?.waterHeatingRunning2 : ""} name={"heatingWaterHeating.waterHeatingRunning2"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Spotř. (kW/rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Spotř. (kW/rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Spotř. (kW/rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Spotř. (kW/rok)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={heatingConsumption1} name={"heatingWaterHeating.heatingConsumption1"} fluid /></Table.Cell>
                                            <Table.Cell><Input value={heatingConsumption2} name={"heatingWaterHeating.heatingConsumption2"} fluid /></Table.Cell>
                                            <Table.Cell><Input value={waterHeatingConsumption1} name={"heatingWaterHeating.waterHeatingConsumption1"} fluid /></Table.Cell>
                                            <Table.Cell><Input value={waterHeatingConsumption2} name={"heatingWaterHeating.waterHeatingConsumption2"} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Cena (Kč / kW)</Table.HeaderCell>
                                            <Table.HeaderCell>Cena (Kč / kW)</Table.HeaderCell>
                                            <Table.HeaderCell>Cena (Kč / kW)</Table.HeaderCell>
                                            <Table.HeaderCell>Cena (Kč / kW)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={building?.heatingWaterHeating?.heatingPrice1 ? building.heatingWaterHeating?.heatingPrice1 : ""} name={"heatingWaterHeating.heatingPrice1"} onChange={changeBuilding} /></Table.Cell>
                                            <Table.Cell><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={building?.heatingWaterHeating?.heatingPrice2 ? building.heatingWaterHeating?.heatingPrice2 : ""} name={"heatingWaterHeating.heatingPrice2"} onChange={changeBuilding} /></Table.Cell>
                                            <Table.Cell><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={building?.heatingWaterHeating?.waterHeatingPrice1 ? building.heatingWaterHeating?.waterHeatingPrice1 : ""} name={"heatingWaterHeating.waterHeatingPrice1"} onChange={changeBuilding} /></Table.Cell>
                                            <Table.Cell><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={building?.heatingWaterHeating?.waterHeatingPrice2 ? building.heatingWaterHeating?.waterHeatingPrice2 : ""} name={"heatingWaterHeating.waterHeatingPrice2"} onChange={changeBuilding} /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Náklady (rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Náklady (rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Náklady (rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Náklady (rok)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={heatingExpenses1} name={"heatingWaterHeating.heatingExpenses1"} fluid /></Table.Cell>
                                            <Table.Cell><Input value={heatingExpenses2} name={"heatingWaterHeating.heatingExpenses2"} fluid /></Table.Cell>
                                            <Table.Cell><Input value={waterHeatingExpenses1} name={"heatingWaterHeating.waterHeatingExpenses1"} fluid /></Table.Cell>
                                            <Table.Cell><Input value={waterHeatingExpenses2} name={"heatingWaterHeating.waterHeatingExpenses2"} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeIndex === 4}
                                index={4}
                                onClick={() => handleClick(4)}
                            >
                                <Icon name='dropdown' />
                                Vnitřní osvětlení a spotřebiče
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 4}>
                                <Table celled className='interior-lighting-appliances-table'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Zdroj osvětlení (1)</Table.HeaderCell>
                                            <Table.HeaderCell>Zdroj osvětlení (2)</Table.HeaderCell>
                                            <Table.HeaderCell>Soupis spotřebičů</Table.HeaderCell>
                                            <Table.HeaderCell>Příkon (W)</Table.HeaderCell>
                                            <Table.HeaderCell>Chod (hod.)</Table.HeaderCell>
                                            <Table.HeaderCell>Spotřeba (kW/rok)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Dropdown name={"interiorLightingAppliances.lightingSource1"}
                                                    value={building?.interiorLightingAppliances?.lightingSource1 ? building.interiorLightingAppliances?.lightingSource1 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={lightingSourceOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"interiorLightingAppliances.lightingSource2"}
                                                    value={building?.interiorLightingAppliances?.lightingSource2 ? building.interiorLightingAppliances?.lightingSource2 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={lightingSourceOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"interiorLightingAppliances.appliance1"}
                                                    value={building?.interiorLightingAppliances?.appliance1 ? building.interiorLightingAppliances?.appliance1 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption1 ? building.interiorLightingAppliances?.powerConsumption1 : ""} name={"interiorLightingAppliances.powerConsumption1"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running1 ? building.interiorLightingAppliances?.running1 : ""} name={"interiorLightingAppliances.running1"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption1 ? building.interiorLightingAppliances?.consumption1 : ""} name={"interiorLightingAppliances.consumption1"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Příkon (W)</Table.HeaderCell>
                                            <Table.HeaderCell>Příkon (W)</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                <Dropdown name={"interiorLightingAppliances.appliance2"}
                                                    value={building?.interiorLightingAppliances?.appliance2 ? building.interiorLightingAppliances?.appliance2 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.HeaderCell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption2 ? building.interiorLightingAppliances?.powerConsumption2 : ""} name={"interiorLightingAppliances.powerConsumption2"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running2 ? building.interiorLightingAppliances?.running2 : ""} name={"interiorLightingAppliances.running2"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption2 ? building.interiorLightingAppliances?.consumption2 : ""} name={"interiorLightingAppliances.consumption2"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.lightingSource1PowerConsumption ? building.interiorLightingAppliances?.lightingSource1PowerConsumption : ""} name={"interiorLightingAppliances.lightingSource1PowerConsumption"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.lightingSource2PowerConsumption ? building.interiorLightingAppliances?.lightingSource2PowerConsumption : ""} name={"interiorLightingAppliances.lightingSource2PowerConsumption"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"interiorLightingAppliances.appliance3"}
                                                    value={building?.interiorLightingAppliances?.appliance3 ? building.interiorLightingAppliances?.appliance3 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption3 ? building.interiorLightingAppliances?.powerConsumption3 : ""} name={"interiorLightingAppliances.powerConsumption3"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running3 ? building.interiorLightingAppliances?.running3 : ""} name={"interiorLightingAppliances.running3"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption3 ? building.interiorLightingAppliances?.consumption3 : ""} name={"interiorLightingAppliances.consumption3"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Počet ks</Table.HeaderCell>
                                            <Table.HeaderCell>Počet ks</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                <Dropdown name={"interiorLightingAppliances.appliance4"}
                                                    value={building?.interiorLightingAppliances?.appliance4 ? building.interiorLightingAppliances?.appliance4 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.HeaderCell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption4 ? building.interiorLightingAppliances?.powerConsumption4 : ""} name={"interiorLightingAppliances.powerConsumption4"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running4 ? building.interiorLightingAppliances?.running4 : ""} name={"interiorLightingAppliances.running4"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption4 ? building.interiorLightingAppliances?.consumption4 : ""} name={"interiorLightingAppliances.consumption4"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.lightingSource1NumberPieces ? building.interiorLightingAppliances?.lightingSource1NumberPieces : ""} name={"interiorLightingAppliances.lightingSource1NumberPieces"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.lightingSource2NumberPieces ? building.interiorLightingAppliances?.lightingSource2NumberPieces : ""} name={"interiorLightingAppliances.lightingSource2NumberPieces"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"interiorLightingAppliances.appliance5"}
                                                    value={building?.interiorLightingAppliances?.appliance5 ? building.interiorLightingAppliances?.appliance5 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption5 ? building.interiorLightingAppliances?.powerConsumption5 : ""} name={"interiorLightingAppliances.powerConsumption5"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running5 ? building.interiorLightingAppliances?.running5 : ""} name={"interiorLightingAppliances.running5"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption5 ? building.interiorLightingAppliances?.consumption5 : ""} name={"interiorLightingAppliances.consumption5"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Chod (hod./rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Chod (hod./rok)</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                <Dropdown name={"interiorLightingAppliances.appliance6"}
                                                    value={building?.interiorLightingAppliances?.appliance6 ? building.interiorLightingAppliances?.appliance6 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.HeaderCell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption6 ? building.interiorLightingAppliances?.powerConsumption6 : ""} name={"interiorLightingAppliances.powerConsumption6"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running6 ? building.interiorLightingAppliances?.running6 : ""} name={"interiorLightingAppliances.running6"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption6 ? building.interiorLightingAppliances?.consumption6 : ""} name={"interiorLightingAppliances.consumption6"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.lightingSource1Running ? building.interiorLightingAppliances?.lightingSource1Running : ""} name={"interiorLightingAppliances.lightingSource1Running"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.lightingSource2Running ? building.interiorLightingAppliances?.lightingSource2Running : ""} name={"interiorLightingAppliances.lightingSource2Running"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"interiorLightingAppliances.appliance7"}
                                                    value={building?.interiorLightingAppliances?.appliance7 ? building.interiorLightingAppliances?.appliance7 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption7 ? building.interiorLightingAppliances?.powerConsumption7 : ""} name={"interiorLightingAppliances.powerConsumption7"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running7 ? building.interiorLightingAppliances?.running7 : ""} name={"interiorLightingAppliances.running7"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption7 ? building.interiorLightingAppliances?.consumption7 : ""} name={"interiorLightingAppliances.consumption7"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Spotř. (kW/rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Spotř. (kW/rok)</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                <Dropdown name={"interiorLightingAppliances.appliance8"}
                                                    value={building?.interiorLightingAppliances?.appliance8 ? building.interiorLightingAppliances?.appliance8 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.HeaderCell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption8 ? building.interiorLightingAppliances?.powerConsumption8 : ""} name={"interiorLightingAppliances.powerConsumption8"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running8 ? building.interiorLightingAppliances?.running8 : ""} name={"interiorLightingAppliances.running8"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption8 ? building.interiorLightingAppliances?.consumption8 : ""} name={"interiorLightingAppliances.consumption8"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.lightingSource1Consumption ? building.interiorLightingAppliances?.lightingSource1Consumption : ""} name={"interiorLightingAppliances.lightingSource1Consumption"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.lightingSource2Consumption ? building.interiorLightingAppliances?.lightingSource2Consumption : ""} name={"interiorLightingAppliances.lightingSource2Consumption"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"interiorLightingAppliances.appliance9"}
                                                    value={building?.interiorLightingAppliances?.appliance9 ? building.interiorLightingAppliances?.appliance9 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption9 ? building.interiorLightingAppliances?.powerConsumption9 : ""} name={"interiorLightingAppliances.powerConsumption9"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running9 ? building.interiorLightingAppliances?.running9 : ""} name={"interiorLightingAppliances.running9"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption9 ? building.interiorLightingAppliances?.consumption9 : ""} name={"interiorLightingAppliances.consumption9"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Cena (Kč / kW)</Table.HeaderCell>
                                            <Table.HeaderCell>Cena (Kč / kW)</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                <Dropdown name={"interiorLightingAppliances.appliance10"}
                                                    value={building?.interiorLightingAppliances?.appliance10 ? building.interiorLightingAppliances?.appliance10 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.HeaderCell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption10 ? building.interiorLightingAppliances?.powerConsumption10 : ""} name={"interiorLightingAppliances.powerConsumption10"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running10 ? building.interiorLightingAppliances?.running10 : ""} name={"interiorLightingAppliances.running10"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption10 ? building.interiorLightingAppliances?.consumption10 : ""} name={"interiorLightingAppliances.consumption10"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.lightingSource1Price ? building.interiorLightingAppliances?.lightingSource1Price : ""} name={"interiorLightingAppliances.lightingSource1Price"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.lightingSource2Price ? building.interiorLightingAppliances?.lightingSource2Price : ""} name={"interiorLightingAppliances.lightingSource2Price"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell>
                                                <Dropdown name={"interiorLightingAppliances.appliance11"}
                                                    value={building?.interiorLightingAppliances?.appliance11 ? building.interiorLightingAppliances?.appliance11 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption11 ? building.interiorLightingAppliances?.powerConsumption11 : ""} name={"interiorLightingAppliances.powerConsumption11"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running11 ? building.interiorLightingAppliances?.running11 : ""} name={"interiorLightingAppliances.running11"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption11 ? building.interiorLightingAppliances?.consumption11 : ""} name={"interiorLightingAppliances.consumption11"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Náklady (rok)</Table.HeaderCell>
                                            <Table.HeaderCell>Náklady (rok)</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                <Dropdown name={"interiorLightingAppliances.appliance14"}
                                                    value={building?.interiorLightingAppliances?.appliance14 ? building.interiorLightingAppliances?.appliance14 : ''}
                                                    onChange={changeDropdownValue}
                                                    fluid
                                                    selection
                                                    options={applianceOptions}>
                                                </Dropdown>
                                            </Table.HeaderCell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.powerConsumption12 ? building.interiorLightingAppliances?.powerConsumption12 : ""} name={"interiorLightingAppliances.powerConsumption12"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.running12 ? building.interiorLightingAppliances?.running12 : ""} name={"interiorLightingAppliances.running12"} onChange={changeBuilding} fluid /></Table.Cell>
                                            <Table.Cell><Input value={building?.interiorLightingAppliances?.consumption12 ? building.interiorLightingAppliances?.consumption12 : ""} name={"interiorLightingAppliances.consumption12"} onChange={changeBuilding} fluid /></Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={lightingSource1Costs} name={"interiorLightingAppliances.lightingSource1Costs"} /></Table.Cell>
                                            <Table.Cell><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={lightingSource2Costs} name={"interiorLightingAppliances.lightingSource2Costs"} /></Table.Cell>
                                            <Table.Cell><Input fluid value="Celkem" name={"interiorLightingAppliances.listAppliancesOverall"} /></Table.Cell>
                                            <Table.Cell><Input fluid value={powerConsumptionOverall} name={"interiorLightingAppliances.powerConsumptionOverall"} /></Table.Cell>
                                            <Table.Cell><Input fluid value={runningOverall} name={"interiorLightingAppliances.runningOverall"} /></Table.Cell>
                                            <Table.Cell><Input fluid value={consumptionOverall} name={"interiorLightingAppliances.consumptionOverall"} /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeIndex === 5}
                                index={5}
                                onClick={() => handleClick(5)}
                            >
                                <Icon name='dropdown' />
                                Potenciál umístění FVE
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 5}>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Dostatek prostoru na střeše</Table.HeaderCell>
                                            <Table.HeaderCell>Budova není zastíněna</Table.HeaderCell>
                                            <Table.HeaderCell>Prostor uvnitř pro technické zázemí</Table.HeaderCell>
                                            <Table.HeaderCell>Budova není památkově chráněna</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell style={{ textAlign: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name={"fve.plentyRoofSpace"}
                                                    onChange={changeBuilding}
                                                    checked={building?.fve?.plentyRoofSpace || false}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{ textAlign: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name={"fve.notShadedBuilding"}
                                                    onChange={changeBuilding}
                                                    checked={building?.fve?.notShadedBuilding || false}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{ textAlign: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name={"fve.spaceInsideForTechnicalFacilities"}
                                                    onChange={changeBuilding}
                                                    checked={building?.fve?.spaceInsideForTechnicalFacilities || false}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{ textAlign: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name={"fve.buildingIsNotListed"}
                                                    onChange={changeBuilding}
                                                    checked={building?.fve?.buildingIsNotListed || false}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Technický stav umožňuje instalaci</Table.HeaderCell>
                                            <Table.HeaderCell>Vyžadována rekonstrukce střechy</Table.HeaderCell>
                                            <Table.HeaderCell>Vhodná orientace budovy</Table.HeaderCell>
                                            <Table.HeaderCell>Možná rezervace výkonu</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell style={{ textAlign: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name={"fve.technicalConditionAllowsInstallation"}
                                                    onChange={changeBuilding}
                                                    checked={building?.fve?.technicalConditionAllowsInstallation || false}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{ textAlign: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name={"fve.roofReconstructionRequired"}
                                                    onChange={changeBuilding}
                                                    checked={building?.fve?.roofReconstructionRequired || false}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{ textAlign: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name={"fve.appropriateBuildingOrientation"}
                                                    onChange={changeBuilding}
                                                    checked={building?.fve?.appropriateBuildingOrientation || false}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{ textAlign: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name={"fve.possiblePerformanceReservation"}
                                                    onChange={changeBuilding}
                                                    checked={building?.fve?.possiblePerformanceReservation || false}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeIndex === 6}
                                index={6}
                                onClick={() => handleClick(6)}
                            >
                                <Icon name='dropdown' />
                                Odběrné místo č. 1
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 6} className='building-card-places'>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>EAN/EIC kód</Table.HeaderCell>
                                            <Table.HeaderCell colSpan="3"><Input fluid value={building?.collectionPoint1?.eanCode ? building.collectionPoint1?.eanCode : ""} name={"collectionPoint1.eanCode"} onChange={changeBuilding} /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell className='label'>Produktové číslo</Table.Cell>
                                            <Table.Cell><Input fluid value={building?.collectionPoint1?.productNumber ? building.collectionPoint1?.productNumber : ""} name={"collectionPoint1.productNumber"} onChange={changeBuilding} /></Table.Cell>
                                            <Table.Cell className='label'>Distribuční sazba</Table.Cell>
                                            <Table.Cell><Input fluid value={building?.collectionPoint1?.distributionRate ? building.collectionPoint1?.distributionRate : ""} name={"collectionPoint1.distributionRate"} onChange={changeBuilding} /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Podíl distr. slož.</Table.HeaderCell>
                                            <Table.HeaderCell><Input fluid value={shareDistrComposition1} name={"collectionPoint1.shareDistrComposition"} /></Table.HeaderCell>
                                            <Table.HeaderCell>Jistič</Table.HeaderCell>
                                            <Table.HeaderCell><Input fluid value={building?.collectionPoint1?.circuitBreaker ? building.collectionPoint1?.circuitBreaker : ""} name={"collectionPoint1.circuitBreaker1"} onChange={changeBuilding} /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell className='label' colSpan="2">VT (spotřeba kW/rok)</Table.Cell>
                                            <Table.Cell className='label' colSpan="2">NT (spotřeba kW/rok)</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan="2"><Input fluid value={building?.collectionPoint1?.vt ? building.collectionPoint1?.vt : ""} name={"collectionPoint1.vt"} onChange={changeBuilding} /></Table.HeaderCell>
                                            <Table.HeaderCell colSpan="2"><Input fluid value={building?.collectionPoint1?.nt ? building.collectionPoint1?.nt : ""} name={"collectionPoint1.nt"} onChange={changeBuilding} /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell className='label' colSpan="2">Celkové náklady (Kč/rok)</Table.Cell>
                                            <Table.Cell className='label' colSpan="2">Cena za obchodní část</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan="2"><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={building?.collectionPoint1?.totalCost ? building.collectionPoint1?.totalCost : ""} name={"collectionPoint1.totalCost"} onChange={changeBuilding} /></Table.HeaderCell>
                                            <Table.HeaderCell colSpan="2"><Input fluid value={building?.collectionPoint1?.priceBusinessPart ? building.collectionPoint1?.priceBusinessPart : ""} name={"collectionPoint1.priceBusinessPart"} onChange={changeBuilding} /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell className='label' colSpan="2">Cena za distribuci</Table.Cell>
                                            <Table.Cell className='label' colSpan="2">Cena (Kč/kW)</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell colSpan="2"><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={distributionPrice1} name={"collectionPoint1.distributionPrice"} /></Table.Cell>
                                            <Table.Cell colSpan="2"><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={collectionPointPrice1} name={"collectionPoint1.collectionPointPrice"} onChange={changeBuilding} /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeIndex === 7}
                                index={7}
                                onClick={() => handleClick(7)}
                            >
                                <Icon name='dropdown' />
                                Odběrné místo č. 2
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 7} className='building-card-places'>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>EAN/EIC kód</Table.HeaderCell>
                                            <Table.HeaderCell colSpan="3"><Input fluid value={building?.collectionPoint2?.eanCode ? building.collectionPoint2?.eanCode : ""} name={"collectionPoint2.eanCode"} onChange={changeBuilding} /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell className='label'>Produktové číslo</Table.Cell>
                                            <Table.Cell><Input fluid value={building?.collectionPoint2?.productNumber ? building.collectionPoint2?.productNumber : ""} name={"collectionPoint2.productNumber"} onChange={changeBuilding} /></Table.Cell>
                                            <Table.Cell className='label'>Distribuční sazba</Table.Cell>
                                            <Table.Cell><Input fluid value={building?.collectionPoint2?.distributionRate ? building.collectionPoint2?.distributionRate : ""} name={"collectionPoint2.distributionRate"} onChange={changeBuilding} /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Podíl distr. slož.</Table.HeaderCell>
                                            <Table.HeaderCell><Input fluid value={shareDistrComposition2} name={"collectionPoint2.shareDistrComposition"} /></Table.HeaderCell>
                                            <Table.HeaderCell>Jistič</Table.HeaderCell>
                                            <Table.HeaderCell><Input fluid value={building?.collectionPoint2?.circuitBreaker ? building.collectionPoint2?.circuitBreaker : ""} name={"collectionPoint2.circuitBreaker"} onChange={changeBuilding} /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell className='label' colSpan="2">VT (spotřeba kW/rok)</Table.Cell>
                                            <Table.Cell className='label' colSpan="2">NT (spotřeba kW/rok)</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan="2"><Input fluid value={building?.collectionPoint2?.vt ? building.collectionPoint2?.vt : ""} name={"collectionPoint2.vt"} onChange={changeBuilding} /></Table.HeaderCell>
                                            <Table.HeaderCell colSpan="2"><Input fluid value={building?.collectionPoint2?.nt ? building.collectionPoint2?.nt : ""} name={"collectionPoint2.nt"} onChange={changeBuilding} /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell className='label' colSpan="2">Celkové náklady (Kč/rok)</Table.Cell>
                                            <Table.Cell className='label' colSpan="2">Cena za obchodní část</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan="2"><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={building?.collectionPoint2?.totalCost ? building.collectionPoint2?.totalCost : ""} name={"collectionPoint2.totalCost"} onChange={changeBuilding} /></Table.HeaderCell>
                                            <Table.HeaderCell colSpan="2"><Input fluid value={building?.collectionPoint2?.priceBusinessPart ? building.collectionPoint2?.priceBusinessPart : ""} name={"collectionPoint2.priceBusinessPart2"} onChange={changeBuilding} /></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell className='label' colSpan="2">Cena za distribuci</Table.Cell>
                                            <Table.Cell className='label' colSpan="2">Cena (Kč/kW)</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell colSpan="2"><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={distributionPrice2} name={"collectionPoint2.distributionPrice"} onChange={changeBuilding} /></Table.Cell>
                                            <Table.Cell colSpan="2"><Input fluid label={{ basic: true, content: 'Kč' }} labelPosition='right' value={collectionPointPrice2} name={"collectionPoint2.collectionPointPrice"} onChange={changeBuilding} /></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeIndex === 8}
                                index={8}
                                onClick={() => handleClick(8)}
                            >
                                <Icon name='dropdown' />
                                Poznámky
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 8}>
                                <Form>
                                    <TextArea fluid value={building?.notes ? building.notes : ""} name="notes" onChange={changeBuilding} />
                                </Form>
                            </Accordion.Content>
                        </Accordion>
                    </>
                }
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Autor karty</Table.HeaderCell>
                            <Table.HeaderCell>Datum vyplnění</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><Input fluid value={building?.author ? building.author : ""} name="author" onChange={changeBuilding} /></Table.Cell>
                            <Table.Cell>
                                <ConfigProvider locale={sk_SK}>
                                    <DatePicker
                                        value={building?.completionDate ? moment(building.completionDate, "DD.MM.YYYY") : ''}
                                        onChange={changeBuilding}
                                        format={"DD.MM.YYYY"}
                                        name='completionDate'
                                    />
                                </ConfigProvider>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Dimmer.Dimmable>

        </div >
    );
};

export default {
    Mutator: BuildingCardMutator
};