import L from 'leaflet';
import { convertCoordinates2Krovak } from './GeometryUtils';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export const setupLeafletDefaults = () => {
  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });
};

export const getImageUrl = (geoUrl, layerId, bbox) => {
  if (bbox) {
    return `${geoUrl}/export?dpi=96&transparent=true&format=png32&layers=show%3A${layerId}&bbox=${bbox.join('%2C')}&bboxSR=4326&imageSR=4326&size=1024%2C1024&f=image`;
  }
  return null;
};

export const getRestepPodkladTileUrl = (geoUrl, bbox, containerSize) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fpodklad_osm.map&layers=podklad_osm&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=${containerSize.width}+${containerSize.height}&imgx=${Math.round(containerSize.width / 2)}&imgy=${Math.round(containerSize.height / 2)}&imgxy=${containerSize.width}+${containerSize.height}`;
  }
  return null;
};

export const getRestepImagePotencialPlochyStriechUrl = (geoUrl, bbox) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fslunce_strechy.map&layers=slunce_strechy&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
  }
  return null;
};

export const getRestepImagePotencialVeternejEnergieUrl = (geoUrl, bbox, containerSize) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fvitr_potencial.map&layers=vitr_potencial&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=${containerSize.width * 2}+${containerSize.height * 2}&imgx=${containerSize.width}&imgy=${containerSize.height}&imgxy=${containerSize.width * 2}+${containerSize.height * 2}`;
  }
  return null;
};

export const getRestepImagePotencialVynosovTpp0kgUrl = (geoUrl, bbox) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_ttp0.map&layers=biomasa_ttp0&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
  }
  return null;
};

export const getRestepImagePotencialVynosovTpp120kgUrl = (geoUrl, bbox) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_ttp120.map&layers=biomasa_ttp120&&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
  }
  return null;
};

export const getRestepImagePotencialLesneTazobneZvyskyUrl = (geoUrl, bbox) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_ltz_vyhrevnost.map&layers=biomasa_ltz_vyhrevnost&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
  }
  return null;
};

export const getRestepImagePotencialKukuricaSilazUrl = (geoUrl, bbox) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_kukurice_s.map&layers=biomasa_kukurice_s&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
  }
  return null;
};
/// TODO USE EFFECTS TILL HERE
export const getRestepImagePotencialSlnecnicaTopinamburUrl = (geoUrl, bbox) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_topinambur.map&layers=biomasa_topinambur&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
  }
  return null;
};

export const getRestepImagePotencialRepkaOzimnaUrl = (geoUrl, bbox) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_repka_o.map&layers=biomasa_repka_o&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
  }
  return null;
};

export const getRestepImagePotencialKonopeTechnickeUrl = (geoUrl, bbox) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_konopi.map&layers=biomasa_konopi&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
  }
  return null;
};

export const getRestepImagePotencialRychloRastuceDrevinyUrl = (geoUrl, bbox) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fbiomasa_rrd.map&layers=biomasa_rrd&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
  }
  return null;
};

export const getRestepImageSpotrebaElektrickejEnergieUrl = (geoUrl, bbox) => {
  if (bbox) {
    return `${geoUrl}/mapserv.fcgi?map=%2Fmapfiles%2Frestep%2Flokalizace%2Fspotreba_elektrina.map&layers=spotreba_elektrina&mode=map&map_imagetype=png&mapext=${bbox.join('+')}&imgext=${bbox.join('+')}&map_size=512+512&imgx=256&imgy=256&imgxy=512+512`;
  }
  return null;
};