import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { getAllUrlParams } from '../../utils/url';

import DocumentView from '../editor/components/views/DocumentView';
import {
  editorSlice,
  selectArtifactsForDocumentPreview,
  selectArtifactsForFormBuilder,
} from '../editor/editorSlice';


import axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { SetAllowInvoiceOtherCompany } from '../../app/ActionsImpl';
import { COMPANY_LOOKUP_HOST, DATE_FORMAT } from '../../constants';
import { dots } from '../../utils/constants';
import { ParseDefaultValues } from '../../utils/DefaultArtifactValuesParser';
import { calculateBoundingBox, calculateGeographicalCenter, fetchGeoJsonFromOverpass, processOverpassData } from '../../utils/GeometryUtils';
import { ARTIFACT_CONDITIONS_FUCS } from '../editor/components/artifact/ARTIFACT_CONDITIONS_FUCS';
import { FormDataContext } from './contexts/form-data.ctx';
import FrontOfficeSidebarBase from './FrontOfficeSidebar';
import proj4 from 'proj4';

export const getObjectValue = (value, path, defaultValue) => {
  if (typeof value === 'string') {
    return value;
  }
  return String(path)
    .split('.')
    .reduce((acc, v) => {
      try {
        acc = acc[v] ?? defaultValue;
      } catch (e) {
        return defaultValue;
      }
      return acc;
    }, value);
};

const DocumentFormView = ({ userProfileData, user, lessons, initFieldValues, overrideDocuments, setAllDocuments, setHideMap, changeOpenedDocument, selectedSteps, activeDocumentKey, hideMap, dateOfMeeting, setRepresentation, setClearDocuments, returnFieldValues, setAllFoundArtifacts, template, orderToEdit, setAllowInvoiceOtherCompany, userPersons }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const documents = useSelector(
    overrideDocuments ? () => null : selectArtifactsForDocumentPreview,
  );
  const fields = useSelector(selectArtifactsForFormBuilder);
  const [fieldValues, setFieldValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    setRepresentation(fieldValues.representation_custom)
    returnFieldValues(fieldValues)
  }, [fieldValues])

  useEffect(() => {
    handleFieldChange({ target: { name: "lessons", value: lessons } })
  }, [lessons])

  const evaluateCondition = (condition) => {
    let first = fieldValues[condition.first]
    let second = condition.second === 'value' ? condition.customValue : fieldValues[condition.second]
    if (condition.first === 'REPRESENTATION-DATA-OBJECT') {
      if (fieldValues['representation_custom']) {
        return true
      }
    }
    return compareConditionValues(first, second, condition.type)
  }

  const compareConditionValues = (first, second, type) => {
    switch (type) {
      case '<': {
        return first < second
      }
      case '<=': {
        return first <= second
      }
      case '>=': {
        return first >= second
      }
      case '>': {
        return first > second
      }
      default: {
        return first === second
      }
    }
  }
  const search = useLocation().search;


  useEffect(() => {
    const urlParams = getAllUrlParams();
    let name = new URLSearchParams(search).get("name");
    let tmpDateOfMeeting = moment(dateOfMeeting, "YYYY-MM-DD")

    setFieldValues((oldValues) => {
      const additional = {
        ...fields
      };
      additional.dateOfMeeting = tmpDateOfMeeting ? tmpDateOfMeeting.format(DATE_FORMAT) : dots;
      additional.userPersons = userPersons
      let defaultValues = ParseDefaultValues(overrideDocuments)
      if (user?.attributes && user?.attributes["custom:customer_role"]) {
        defaultValues.representation_custom = true
      }
      for (let [key, value] of Object.entries(defaultValues)) {
        additional[key] = value
      }
      dispatch(editorSlice.actions.setFormStatePayload(JSON.stringify(additional)));
      return additional
    })
    setIsLoading(false);
  }, [userPersons])

  useEffect(async () => {
    setIsLoading(true)
    let idString = new URLSearchParams(search).get("id");
    if (idString) {
      const ids = idString.split(";")
      if (ids) {
        const municipalities = await axios.post(COMPANY_LOOKUP_HOST + "/municipality/list-by-ids", { municipalities: ids });
        let index = 0
        for (let municipality of municipalities.data) {
          const geoJson = await fetchGeoJsonFromOverpass(municipality?.municipality, municipality?.districtID?.district)
          const convertedGeoJson = processOverpassData(geoJson);
          const geoCenter = calculateGeographicalCenter(convertedGeoJson)
          const geoBoundingBox = calculateBoundingBox(convertedGeoJson)

          municipalities.data[index].geoJson = convertedGeoJson
          municipalities.data[index].geoCenter = geoCenter
          municipalities.data[index].geoCenterKrovak = proj4('EPSG:4326', 'EPSG:5514',[geoCenter[1],geoCenter[0]])
          municipalities.data[index].geoBoundingBox = geoBoundingBox
          index += 1
        }

        setFieldValues((oldValues) => {
          const additional = {
            ...fields,
            municipalities: municipalities.data
          };
          dispatch(editorSlice.actions.setFormStatePayload(JSON.stringify(additional)));
          return additional
        })
      }
    }
    setIsLoading(false);
  }, [])


  useEffect(() => {
    if (template) {
      setFieldValues(JSON.parse(JSON.stringify(template.template.fieldValues)))
      initFieldValues(JSON.parse(JSON.stringify(template.template.fieldValues)))
      dispatch(editorSlice.actions.setFormStatePayload(JSON.stringify(template.template.fieldValues)));
      setIsLoading(false);
    }
  }, [template])

  useEffect(() => {
    if (orderToEdit) {
      let formState = JSON.parse(orderToEdit.formState)
      setFieldValues(formState)
      initFieldValues(formState)
      dispatch(editorSlice.actions.setFormStatePayload(JSON.stringify(formState)));
      setIsLoading(false);
    }
  }, [orderToEdit])

  const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  const documentsMemo = useMemo(() => {
    const docs = documents || overrideDocuments;
    let values = JSON.parse(JSON.stringify(fieldValues))
    values.votingOrder = [];
    let docMap = {}
    for (let doc of docs) {
      if (values.votingOrder.indexOf(doc.id) === -1) {
        values.votingOrder.push(doc.id);
        docMap[doc.id] = doc
      }
    }
    if (fieldValues.votingOrder === undefined || fieldValues.votingOrder.length !== values.votingOrder.length || !equals(fieldValues.votingOrder, values.votingOrder)) {
      setFieldValues(values)
    }
    const rules = docs.map((item) => item.value.rules);
    let finalRules = [];
    for (let ruleIndex in rules) {
      const arrayRules = rules[ruleIndex];
      if (arrayRules && arrayRules.length > 0) {
        finalRules = [...finalRules, ...arrayRules];
      }
    }

    let excludeDocs = [];
    finalRules.map((ruleItem) => {
      if (!ruleItem.condition || ruleItem.condition === '') {
        return;
      }
      const shouldShow = ARTIFACT_CONDITIONS_FUCS[ruleItem.condition](
        null,
        fieldValues,
      );

      if (!shouldShow) {
        excludeDocs.push(ruleItem.key);
      }
    });

    for (let doc of docs) {
      if (doc.value.isConditional && doc.value.artifactConditions && doc.value.artifactConditions.length > 0) {
        for (let condition of doc.value.artifactConditions) {
          if (!evaluateCondition(condition)) {
            excludeDocs.push(doc.id)
            doc.value.rules?.forEach(({ key }) => {
              excludeDocs.push(key);
            });
            doc.value.rules2?.forEach(({ key }) => {
              excludeDocs.push(key);
            });
          }
        }
      }
      if (doc.value.ruleCondition) {
        doc.value.rules?.forEach(({ key }) => {
          excludeDocs.push(key + "-0");
          excludeDocs.push(key);
        });
      }
    }

    setHideMap(excludeDocs);

    let finalDocs = docs.filter((doc) => {
      const showDoc = !excludeDocs.includes(doc.id);
      return showDoc;
    });

    let indexToPush = 0
    let documentsToPush = []
    for (let doc of finalDocs) {
      if (doc.value.isRepeatable) {
        if (fieldValues[doc.id + "-count"]) {
          doc.name = doc.name.split(' č.')[0] + ' č.1'
          if (doc.value.ruleCondition) {
            doc.value.rules?.forEach(({ key }) => {
              docMap[key + "-0"].name = docMap[key + "-0"].name.split(' č.')[0] + ' č.1'
            });
          } else {
            doc.value.rules?.forEach(({ key }) => {
              docMap[key].name = docMap[key].name.split(' č.')[0] + ' č.1'
            });
          }
          if (!isNaN(fieldValues[doc.id + "-count"])) {
            let docToPush = JSON.parse(JSON.stringify(doc))
            if (docToPush.value.ruleCondition && fieldValues[docToPush.value.ruleCondition] && fieldValues[docToPush.value.ruleCondition][docToPush.id] && fieldValues[docToPush.value.ruleCondition][docToPush.id].amount) {
              docToPush.value.rules?.forEach(({ key }) => {
                for (let i = 0; i < Number(fieldValues[docToPush.value.ruleCondition][docToPush.id].amount); i++) {
                  let ruleDoc = JSON.parse(JSON.stringify(docMap[key + "-0"]))
                  ruleDoc.id = ruleDoc.id + "." + i
                  ruleDoc.parentStepId = docToPush.id
                  ruleDoc.value.isRepeatable = true
                  ruleDoc.name = (ruleDoc.name.split(' č.')[0] + " č.") + `${i + 1} ` + (fieldValues[docToPush.value.ruleCondition][docToPush.id].newOwners[i].name ? fieldValues[docToPush.value.ruleCondition][docToPush.id].newOwners[i].name : "")
                  if (!fieldValues[docToPush.value.ruleCondition][docToPush.id].newOwners[i].keep) {
                    documentsToPush.push({ doc: ruleDoc, index: (indexToPush) })
                    indexToPush += 1
                  }
                }
              })
            }
            let to = parseInt(fieldValues[docToPush.id + "-count"])
            if (!isNaN(fieldValues[docToPush.id + "-count"])) {
              for (let step = 1; step < to; step++) {
                if (step === 1 && !docToPush.value.ruleCondition) {
                  indexToPush += docToPush.value.rules.length
                }
                docToPush = JSON.parse(JSON.stringify(doc))
                docToPush.id = docToPush.id.split('-')[0] + "-" + step
                docToPush.name = docToPush.name.split(' č.')[0] + ' č.' + (step + 1)
                documentsToPush.push({ doc: docToPush, index: (indexToPush) })
                indexToPush += 1
                if (fieldValues.votings && !fieldValues.votings[docToPush.id]) {
                  fieldValues.votings[docToPush.id] = JSON.parse(JSON.stringify(fieldValues.votings[doc.id]))
                }
                docToPush.value.rules?.forEach(({ key }) => {
                  if (docToPush.value.ruleCondition) {
                    if (fieldValues[docToPush.value.ruleCondition] && fieldValues[docToPush.value.ruleCondition][docToPush.id] && fieldValues[docToPush.value.ruleCondition][docToPush.id].amount) {
                      for (let i = 0; i < Number(fieldValues[docToPush.value.ruleCondition][docToPush.id].amount); i++) {
                        let ruleDoc = JSON.parse(JSON.stringify(docMap[key + "-0"]))
                        ruleDoc.id = ruleDoc.id.split('-')[0] + "-" + step + "." + i
                        ruleDoc.parentStepId = docToPush.id
                        ruleDoc.value.isRepeatable = true
                        ruleDoc.name = (ruleDoc.name.split(' č.')[0] + ' č.' + (step + 1) + ".") + `${i + 1} ` + (fieldValues[docToPush.value.ruleCondition][docToPush.id].newOwners[i].name ? fieldValues[docToPush.value.ruleCondition][docToPush.id].newOwners[i].name : "")
                        if (!fieldValues[docToPush.value.ruleCondition][docToPush.id].newOwners[i].keep) {
                          documentsToPush.push({ doc: ruleDoc, index: (indexToPush) })
                          indexToPush += 1
                        }
                      }
                    }
                  } else {
                    let ruleDoc = JSON.parse(JSON.stringify(docMap[key]))
                    ruleDoc.id = ruleDoc.id + "-" + step
                    ruleDoc.parentStepId = docToPush.id
                    ruleDoc.value.isRepeatable = true
                    ruleDoc.name = ruleDoc.name.split(' č.')[0] + ' č.' + (step + 1)
                    documentsToPush.push({ doc: ruleDoc, index: (indexToPush) })
                    indexToPush += 1
                  }
                });
                if (step === (to - 1) && !docToPush.value.ruleCondition) {
                  indexToPush -= docToPush.value.rules.length
                }
              }
            }
          }
        } else {
          fieldValues[doc.id + "-count"] = "1"
        }
      } else {
        let docToPush = JSON.parse(JSON.stringify(doc))
        docToPush.value.rules?.forEach(({ key }) => {
          if (docToPush.value.ruleCondition) {
            if (fieldValues[docToPush.value.ruleCondition] && ((fieldValues[docToPush.value.ruleCondition][docToPush.id] && fieldValues[docToPush.value.ruleCondition][docToPush.id].amount) || fieldValues[docToPush.value.ruleCondition].amount)) {
              let amount = fieldValues[docToPush.value.ruleCondition].amount || fieldValues[docToPush.value.ruleCondition][docToPush.id].amount
              for (let i = 0; i < Number(amount); i++) {
                let ruleDoc = JSON.parse(JSON.stringify(docMap[key]))
                ruleDoc.id = ruleDoc.id + "-" + (i + 1)
                ruleDoc.parentStepId = docToPush.id
                ruleDoc.value.isRepeatable = true
                ruleDoc.name = ruleDoc.name.split(' č.')[0] + " č." + (i + 1)
                documentsToPush.push({ doc: ruleDoc, index: (indexToPush) })
                indexToPush += 1

              }
            }
          }
        })
      }
      indexToPush += 1
    }
    for (let docToPush of documentsToPush) {
      finalDocs.splice(docToPush.index + 1, 0, docToPush.doc);
    }
    setClearDocuments(finalDocs)
    setAllDocuments(finalDocs)
    return finalDocs
  }, [documents, overrideDocuments, fieldValues, setHideMap]);

  useEffect(() => {
    let isRepresentAble = undefined
    let requiresVerifiedSignature = undefined
    let allowInvoiceOtherCompany = undefined
    for (let doc of documentsMemo) {
      if (doc.value.requiresVerifiedSignature) {
        requiresVerifiedSignature = true;
      }
      if (doc.value.isRepresentAble) {
        isRepresentAble = true
      }
      if (doc.value.allowInvoiceOtherCompany) {
        allowInvoiceOtherCompany = true
      }
    }
    if (fieldValues) {
      let fieldValuesCopy = fieldValues;
      if (isRepresentAble !== fieldValues.isRepresentAble) {
        fieldValuesCopy['isRepresentAble'] = isRepresentAble;
      }
      if (allowInvoiceOtherCompany !== fieldValues.allowInvoiceOtherCompany) {
        fieldValuesCopy['allowInvoiceOtherCompany'] = allowInvoiceOtherCompany;
        setAllowInvoiceOtherCompany(allowInvoiceOtherCompany)
      }
      if (requiresVerifiedSignature !== fieldValues.requiresVerifiedSignature) {
        fieldValuesCopy['requiresVerifiedSignature'] = requiresVerifiedSignature;
      }
      if (JSON.stringify(fieldValuesCopy) !== JSON.stringify(fieldValues)) {
        setFieldValues(fieldValuesCopy);
      }
    }
  }, [documentsMemo]);



  const handleFieldChange = useCallback(
    (e) => {
      let newPayload = { ...fieldValues }
      if (e.target && e.target.name) {
        newPayload[e.target.name] = e.target.value;
      }
      if (e.target && e.target.name === "representation_custom") {
        setRepresentation(e.target.value)
      }
      if (e.company && e.company.name) {
        newPayload['company'][e.company.name] = (e.company.value)
      }
      if (e.votings) {
        newPayload['votings'] = e.votings
      }
      if (e.votingSettings) {
        newPayload['votingSettings'] = e.votingSettings
      }
      if (e.baseInvestment) {
        newPayload['zakladne_imanie'] = e.baseInvestment
      }
      if (e.allVoteFor !== undefined) {
        newPayload['allVoteFor'] = e.allVoteFor
      }
      if (e.foreign_owners_amount !== undefined) {
        newPayload['foreign_owners_amount'] = e.foreign_owners_amount
      }
      if (e.konatelia !== undefined) {
        newPayload['konatelia_establishment_custom'] = e.konatelia
      }
      if (e.userPersons) {
        newPayload.userPersons = e.userPersons
      }
      setFieldValues(JSON.parse(JSON.stringify(newPayload)));
      dispatch(editorSlice.actions.setFormStatePayload(JSON.stringify(newPayload)));
      forceUpdate()
    }, [fieldValues, dispatch],
  );

  if (isLoading) {
    return <h4>Načítavam..</h4>;
  }

  let currentDocument
  let currentDocumentIndex
  let index = 0
  for (let doc of documentsMemo) {
    if (doc.id === activeDocumentKey) {
      currentDocument = doc
      currentDocumentIndex = index
    }
    index += 1
  }

  return (
    fieldValues &&
    <>
      <FrontOfficeSidebarBase
        changeOpenedDocument={changeOpenedDocument}
        activeDocumentKey={activeDocumentKey}
        documents={documentsMemo}
        hideMap={hideMap}
        selectedSteps={selectedSteps}
        values={fieldValues}
      />
      <div className="mainFrontOfficeScreen">

        <FormDataContext.Provider
          value={{ ...{ data: fieldValues, setField: handleFieldChange } }}>
          <DocumentView
            user={user}
            userProfileData={userProfileData}
            currentDocumentIndex={currentDocumentIndex}
            currentDocument={currentDocument}
            activeDocumentKey={activeDocumentKey}
            changeOpenedDocument={changeOpenedDocument}
            values={fieldValues}
            handleFieldChange={handleFieldChange}
            documents={documentsMemo}
            fields={fields}
            setAllFoundArtifacts={setAllFoundArtifacts}
          />
        </FormDataContext.Provider>
      </div>
    </>

  );
};

const mapStateToProps = ({ appState }) => ({
  template: appState.template,
  orderToEdit: appState.orderToEdit,
  userPersons: appState.userPersons,
  user: appState.cognitoUser,
  userProfileData: appState.userProfileData
});

const mapDispatchToProps = {
  setAllowInvoiceOtherCompany: SetAllowInvoiceOtherCompany
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentFormView);