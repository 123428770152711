import axios from 'axios';
import { useRef, useState } from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend, ResponsiveContainer,
    Tooltip,
    XAxis, YAxis
} from 'recharts';
import { ADMIN_API_HOST } from '../../../../../constants';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import { Button } from 'antd';
import { captureMapImage } from '../../../../../utils/MapaUtils';
import { v4 as uuidv4 } from 'uuid';

const COLORS = ['#00b04e', '#c6e0b5', '#c65a10', '#aeabaa', '#4373c4', '#6f31a0', '#71ad47'];

const RainData = ({ name, rest }) => {
    const uniqueId = useRef(uuidv4());

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { position: [49.94297310, 17.03212700], zoom: 13 },
        municipality: data.municipalities[0],
        data,
        setField
    }));
    const [fieldValues, setFieldValues] = useState(values)
    const containerRef = useRef();

    const filtered = Object.entries(values.data).filter(item => item[1].id === name)
    const graphName = filtered[0][1].name.split('_')[1]

    const handleCaptureMapImage = async () => {
        const mapContainer = document.getElementById(uniqueId.current);

        if (mapContainer) {
            mapContainer.classList.add('full-screen');
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));

        await captureMapImage(containerRef, setFieldValues, fieldValues, values, name, true);

        if (mapContainer) {
            setTimeout(() => {
                mapContainer.classList.remove('full-screen');
            }, 1000);
        }
    };

    const renderChart = () => {
        return (
            <>
                <ResponsiveContainer>
                    <BarChart data={fieldValues?.value?.weatherData?.monthlyData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis domain={['auto', 'auto']} label={{ value: 'v mm', angle: -90, position: 'insideLeft', fontWeight: 800 }} />
                        <Tooltip />
                        <Legend verticalAlign="bottom" align="center" layout="horizontal" />
                        <Bar dataKey="Srážky" name="Úhrn srážek" fill="#4373c4" isAnimationActive={false} />
                    </BarChart>
                </ResponsiveContainer>
            </>
        );
    }

    const fetchData = async () => {
        try {
            let result = await axios.post(ADMIN_API_HOST + "/chmi/get-rain", { lon: values.municipality.geoCenter[0], lat: values.municipality.geoCenter[1] })
            let tmpFieldValues = JSON.parse(JSON.stringify(fieldValues))
            tmpFieldValues.value = {
                weatherData: {
                    year: rest.subType === "highest" ? result.data.wettestYear : result.data.driestYear,
                    monthlyData: Object.entries(rest.subType === "highest" ? result.data.wettestYearData.SUM : result.data.driestYearData.SUM).map(([key, value]) => {
                        console.log(key, value); // Log to see the structure of `value`
                        return { name: key, Srážky: value.value }; // Ensure that you're accessing the correct property of `value`
                    })
                }, ...fieldValues.value
            }
            setFieldValues(tmpFieldValues)
            console.log(tmpFieldValues)
            values.setField({
                target: {
                    name,
                    value: tmpFieldValues.value
                },
            });
            console.log(result)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <ResponsiveContainer className="recharts-container">
            <div id={uniqueId.current} className='chart-capture' ref={containerRef}>
                <div className='map-title-buttons-container'>
                    <div>
                        <h1>{graphName}</h1>
                        {fieldValues?.value?.weatherData?.year &&
                            <h3>Rok {fieldValues?.value?.weatherData?.year}</h3>
                        }
                    </div>
                    <div className='recharts-buttons-container'>
                        <Button className='addButton' onClick={fetchData}>Načítať dáta</Button>
                        <Button className='addButton' onClick={handleCaptureMapImage}>Vložiť do náhľadu</Button>
                    </div>
                </div>
                {renderChart()}
                <p style={{ textAlign: 'right', padding: '20px 0' }}>Zdroj: ČHMÚ-vlastní zpracování</p>
            </div>
        </ResponsiveContainer>
    );
};

function ChartsRenderer(values, key) {
    if (values[key] && values[key].image) {
        let returnValue = ""
        if (values[key].weatherData?.year) {
            returnValue = `<h3>Rok ${values[key].weatherData?.year}</h3>`
        }
        returnValue += `<img src="${values[key].image}" alt="Chart view" style="width: 100%; height: auto; object-fit: cover;"/>`
        return returnValue;
    }
    return ""
}

function ChartsValidator() {
    return true;
}

export default {
    Mutator: RainData,
    HTMLRenderer: ChartsRenderer,
    Validator: ChartsValidator
};
