// MapaPotencialnyVyskytDruhuVegetace.js
import { Button, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Map, Polygon, TileLayer, ImageOverlay } from 'react-leaflet';
import { captureMapImage, fetchMapLayer, getPolygonFromBounds, setupLeafletDefaults } from '../../../../utils/MapaUtils';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import Legend from '../Legend';
import { prepareLayerGeoTeplota400m } from '../../../../utils/GeometryUtils';
import LegendCustom from '../LegendCustom';
import { getMapBounds } from '../../../../utils/MapaUtils';

setupLeafletDefaults()

const legendHeatmapColors = {
    // Interval 10 - 15 (Green)
    "10": "rgb(0, 128, 0)",     // Green
    "11": "rgb(0, 128, 0)",
    "12": "rgb(0, 128, 0)",
    "13": "rgb(0, 128, 0)",
    "14": "rgb(0, 128, 0)",
    "15": "rgb(0, 128, 0)",

    // Interval 16 - 20 (Yellow-Green to Yellow)
    "16": "rgb(173, 255, 47)",  // Yellow-Green
    "17": "rgb(173, 255, 47)",
    "18": "rgb(173, 255, 47)",
    "19": "rgb(255, 255, 0)",   // Yellow
    "20": "rgb(255, 255, 0)",

    // Interval 21 - 25 (Orange)
    "21": "rgb(255, 165, 0)",   // Orange
    "22": "rgb(255, 165, 0)",
    "23": "rgb(255, 165, 0)",
    "24": "rgb(255, 165, 0)",
    "25": "rgb(255, 165, 0)",

    // Interval 26 - 30 (Light Red)
    "26": "rgb(255, 69, 0)",    // Light Red (closer to orange)
    "27": "rgb(255, 69, 0)",
    "28": "rgb(255, 69, 0)",
    "29": "rgb(255, 69, 0)",
    "30": "rgb(255, 69, 0)",

    // Interval 31 - 35 (Red)
    "31": "rgb(255, 0, 0)",     // Red
    "32": "rgb(255, 0, 0)",
    "33": "rgb(255, 0, 0)",
    "34": "rgb(255, 0, 0)",
    "35": "rgb(255, 0, 0)"
};

const legendData = {
    "layers": [
        {
            "label": "0 - 15",
            "color": "#008000"  // Green (rgb(0, 128, 0))
        },
        {
            "label": "16 - 20",
            "color": "#ADFF2F"  // Yellow-Green (rgb(173, 255, 47))
        },
        {
            "label": "21 - 25",
            "color": "#FFFF00"  // Yellow (rgb(255, 255, 0))
        },
        {
            "label": "26 - 30",
            "color": "#FFA500"  // Orange (rgb(255, 165, 0))
        },
        {
            "label": "31 - 35",
            "color": "#FF0000"  // Red (rgb(255, 0, 0))
        }
    ]
};

const MapaGeoTeplota400mMutator = ({ name }) => {
    const mapUniqueId = "mapaGeoTeplota400m" + name ? name : "";

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { position: [49.94297310, 17.03212700], zoom: 13 },
        municipality: data.municipalities[0],
        setField
    }));
    const [fieldValues, setFieldValues] = useState(values);
    const [isActiveMap, setIsActiveMap] = useState(false);
    const [opacity, setOpacity] = useState(0.75);
    const [loading, setLoading] = useState(false);
    const mapRef = useRef();
    const containerRef = useRef();
    const [legendColorMap, setLegendColorMap] = useState(legendHeatmapColors);

    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    };

    const onMoveEnd = () => {
        const map = mapRef.current.leafletElement;
        const newCenter = map.getCenter();
        let tmpFieldValues = { ...fieldValues };
        tmpFieldValues.value.position = [newCenter.lat, newCenter.lng];
        tmpFieldValues.value.zoom = map.getZoom();
        captureMapImage(containerRef, setFieldValues, fieldValues, values, name, false);
    };

    useEffect(() => {
        if (values.municipality.geoBoundingBox && mapRef.current) {
            const map = mapRef.current.leafletElement;
            map.fitBounds(values.municipality.geoBoundingBox);
        }
    }, []);

    useEffect(() => {
        const mapInstance = mapRef.current.leafletElement;
        if (mapInstance && !isActiveMap) {
            mapInstance.dragging.disable();
            mapInstance.touchZoom.disable();
            mapInstance.doubleClickZoom.disable();
            mapInstance.scrollWheelZoom.disable();
            mapInstance.boxZoom.disable();
            mapInstance.keyboard.disable();
        }
    }, [isActiveMap]);

    const enableMapInteractions = () => {
        const mapInstance = mapRef.current.leafletElement;
        if (!isActiveMap && mapInstance) {
            setIsActiveMap(true);
            mapInstance.dragging.enable();
            mapInstance.touchZoom.enable();
            mapInstance.doubleClickZoom.enable();
            mapInstance.scrollWheelZoom.enable();
            mapInstance.boxZoom.enable();
            mapInstance.keyboard.enable();
        }
    };

    const disableMapInteractions = (event) => {
        const mapElement = document.getElementById(mapUniqueId);
        if (!mapElement.contains(event.target)) {
            const mapInstance = mapRef.current.leafletElement;
            if (isActiveMap && mapInstance) {
                setIsActiveMap(false);
                mapInstance.dragging.disable();
                mapInstance.touchZoom.disable();
                mapInstance.doubleClickZoom.disable();
                mapInstance.scrollWheelZoom.disable();
                mapInstance.boxZoom.disable();
                mapInstance.keyboard.disable();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', disableMapInteractions);
        return () => {
            document.removeEventListener('click', disableMapInteractions);
        };
    }, [isActiveMap]);

    const handleOpacityChange = (event) => {
        setOpacity(event.target.value);
    };

    const handleCaptureMapImage = async () => {
        try {
            const mapContainer = document.getElementById(mapUniqueId);
            if (!mapContainer) {
                console.error("Map container not found");
                return;
            }
    
            mapContainer.classList.add('full-screen');
    
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const mapInstance = mapRef.current?.leafletElement;
            
            if (!mapInstance) {
                console.error("Map instance not found");
                mapContainer.classList.remove('full-screen');
                return;
            }
    
            mapInstance.invalidateSize();
    
            await new Promise((resolve) => setTimeout(resolve, 4000));
            captureMapImage(containerRef, setFieldValues, fieldValues, values, name, true);
    
            await new Promise((resolve) => setTimeout(resolve, 2000));
            mapContainer.classList.remove('full-screen');
        } catch (error) {
            console.error("Error during map capture:", error);
        }
    };

    // Funkcia na získanie dominantnej farby z base64 obrázka
    // function getDominantColorFromBase64(base64Image, callback) {
    //     const img = new Image();
    //     img.src = 'data:image/png;base64,' + base64Image;
    //     console.log("img.src", img.src);
    //     img.crossOrigin = "Anonymous"; // Ak máš obrázky z externých zdrojov
    //     img.onload = () => {
    //         const canvas = document.createElement('canvas');
    //         const context = canvas.getContext('2d');
    //         canvas.width = img.width;
    //         canvas.height = img.height;
    //         context.drawImage(img, 0, 0, img.width, img.height);

    //         const imageData = context.getImageData(0, 0, img.width, img.height).data;

    //         // Získame priemernú farbu
    //         let r = 0, g = 0, b = 0;
    //         const totalPixels = img.width * img.height;

    //         for (let i = 0; i < imageData.length; i += 4) {
    //             r += imageData[i];
    //             g += imageData[i + 1];
    //             b += imageData[i + 2];
    //         }

    //         // Priemer RGB hodnôt
    //         r = Math.floor(r / totalPixels);
    //         g = Math.floor(g / totalPixels);
    //         b = Math.floor(b / totalPixels);

    //         callback(`rgb(${r}, ${g}, ${b})`); // Vrátime farbu v tvare rgb
    //     };
    // }

    // // Hlavná funkcia na vytvorenie mapy
    // async function createValuesColorMap(layers) {
    //     const valuesColorMap = {};

    //     for (const item of layers.legend) {
    //         await new Promise((resolve) => {
    //             getDominantColorFromBase64(item.imageData, (color) => {
    //                 // Pre každú hodnotu v "values" priradíme extrahovanú farbu
    //                 item.values.forEach(value => {
    //                     valuesColorMap[value] = color;
    //                 });
    //                 console.log("VALUES COLOR MAP", valuesColorMap);
    //                 resolve(); // Po extrakcii pokračujeme
    //             });
    //         });
    //     }

    //     return valuesColorMap;
    // }

    // useEffect(() => {
    //     async function fetchLegendColorMap() {
    //         if (fieldValues?.value?.legend) {
    //             const filteredLegend = fieldValues?.value?.legend.filter(
    //                 (l) => l.layerId === fieldValues?.value?.geoServer?.layerId
    //             )[0];

    //             if (filteredLegend) {
    //                 const legendColorMap = await createValuesColorMap(filteredLegend);
    //                 console.log('VALUES COLOR MAP ALL', legendColorMap);
    //                 setLegendColorMap(legendColorMap); // Set the state with the resulting map
    //             }
    //         }
    //     }
    //     fetchLegendColorMap(); // Call the async function inside useEffect
    // }, [fieldValues?.value?.legend]);

    return (
        <div className='open-street-map'>
            <div ref={containerRef} className={isActiveMap ? 'open-street-map is-active-map' : 'open-street-map'}>
                <div className='map-title-buttons-container'>
                    <h3>Mapa Teplota v hloubce 400m</h3>
                    <div>
                        <Button
                            className='addButton'
                            style={{ marginRight: 20 }}
                            onClick={async () => {
                                setLoading(true);
                                try {
                                    await fetchMapLayer(prepareLayerGeoTeplota400m, getPolygonFromBounds(getMapBounds(mapRef)), setFieldValues, fieldValues, containerRef, values, name);
                                } catch (error) {
                                    console.error('Error fetching map layer:', error);
                                } finally {
                                    setLoading(false);
                                }
                            }}
                        >
                            {loading ? 'Načítavam údaje...' : 'Stiahnúť údaje'}
                        </Button>
                        <Button className='addButton' onClick={handleCaptureMapImage}>Vložiť do náhľadu</Button>
                        {loading && <Spin className='spin-loading-map' />}
                    </div>
                </div>
                <Map
                    onblur={handleChange}
                    center={values?.municipality?.geoCenter}
                    zoom={fieldValues.value.zoom}
                    ref={mapRef}
                    onMoveEnd={onMoveEnd}
                    onZoomEnd={onMoveEnd}
                    id={mapUniqueId}
                    onClick={enableMapInteractions}
                >
                    <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Polygon key={mapUniqueId + "poly"} positions={values?.municipality?.geoJson} opacity={opacity} />
                    {fieldValues?.value?.geoLayer && legendColorMap && (
                        fieldValues?.value?.geoLayer.map(layer => {
                            return (
                                <Polygon
                                    key={"1305874272696"}
                                    color={legendColorMap[String(layer.attributes.teplota)]}
                                    positions={[[layer.geometry]]}
                                    stroke={false} // Disable the border (stroke)
                                    fillOpacity={opacity} // Optional: control fill opacity if needed
                                />
                            )
                        })
                    )}
                </Map>
                <LegendCustom
                    legendData={legendData}
                    opacity={opacity}
                />
            <div className='opacity-slider-map'>
                <span className='opacity-slider-controller'>
                    <label>Viditeľnosť vrstiev</label>
                    <input
                        type="range"
                        id="opacity"
                        name="opacity"
                        min="0"
                        max="1"
                        step="0.02"
                        value={opacity}
                        onChange={handleOpacityChange}
                    />
                </span>
                <span><strong>Zdroj:</strong> AOPK-vlastní zpracování</span>
            </div>
            </div>
        </div>
    );
};

function MapaGeoTeplota400mRenderer(values, key) {
    let returnValue = ""
    if (values[key]) {
        returnValue += `<img src="${values[key].image}" alt="Map view" style="width: 100%; height: auto; object-fit: cover;" />`;
    }
    return returnValue
}

function MapaGeoTeplota400mValidator() {
    return true;
}

export default {
    Mutator: MapaGeoTeplota400mMutator,
    HTMLRenderer: MapaGeoTeplota400mRenderer,
    Validator: MapaGeoTeplota400mValidator
};