// MapaPotencialnyVyskytDruhuVegetace.js
import { Button, Spin } from 'antd';
import L from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { Map, Marker, Polygon, Popup, TileLayer } from 'react-leaflet';
import { prepareLayerBiogasPlants, prepareLayerGeoTeplota400m } from '../../../../utils/GeometryUtils';
import { captureMapImage, fetchMapPoints, getMapBounds, getPolygonFromBounds, getPolygonFromBoundsLatLng, setupLeafletDefaults } from '../../../../utils/MapaUtils';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import LegendCustom from '../LegendCustom';
import { czechRepublicBoundingBox } from './MapaGeoTeplota400mCela';
import point from '../../../../images/bio-point.svg'

setupLeafletDefaults()

const legendHeatmapColors = {
    // Interval 10 - 15 (Green)
    "10": "rgb(0, 128, 0)",     // Green
    "11": "rgb(0, 128, 0)",
    "12": "rgb(0, 128, 0)",
    "13": "rgb(0, 128, 0)",
    "14": "rgb(0, 128, 0)",
    "15": "rgb(0, 128, 0)",

    // Interval 16 - 20 (Yellow-Green to Yellow)
    "16": "rgb(173, 255, 47)",  // Yellow-Green
    "17": "rgb(173, 255, 47)",
    "18": "rgb(173, 255, 47)",
    "19": "rgb(255, 255, 0)",   // Yellow
    "20": "rgb(255, 255, 0)",

    // Interval 21 - 25 (Orange)
    "21": "rgb(255, 165, 0)",   // Orange
    "22": "rgb(255, 165, 0)",
    "23": "rgb(255, 165, 0)",
    "24": "rgb(255, 165, 0)",
    "25": "rgb(255, 165, 0)",

    // Interval 26 - 30 (Light Red)
    "26": "rgb(255, 69, 0)",    // Light Red (closer to orange)
    "27": "rgb(255, 69, 0)",
    "28": "rgb(255, 69, 0)",
    "29": "rgb(255, 69, 0)",
    "30": "rgb(255, 69, 0)",

    // Interval 31 - 35 (Red)
    "31": "rgb(255, 0, 0)",     // Red
    "32": "rgb(255, 0, 0)",
    "33": "rgb(255, 0, 0)",
    "34": "rgb(255, 0, 0)",
    "35": "rgb(255, 0, 0)"
};

const customIcon = new L.Icon({
    iconUrl: point,
    iconSize: [40, 40], // size of the icon
    iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -40], // point from which the popup should open relative to the iconAnchor
});

const legendData = {
    "layers": [
        {
            "label": "0 - 15",
            "color": "#008000"  // Green (rgb(0, 128, 0))
        },
        {
            "label": "16 - 20",
            "color": "#ADFF2F"  // Yellow-Green (rgb(173, 255, 47))
        },
        {
            "label": "21 - 25",
            "color": "#FFFF00"  // Yellow (rgb(255, 255, 0))
        },
        {
            "label": "26 - 30",
            "color": "#FFA500"  // Orange (rgb(255, 165, 0))
        },
        {
            "label": "31 - 35",
            "color": "#FF0000"  // Red (rgb(255, 0, 0))
        }
    ]
};

const MapaBioplynoveStaniceMutator = ({ name }) => {
    const mapUniqueId = "mapaBioplynoveStanice" + name ? name : "";

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { position: [49.94297310, 17.03212700], zoom: 13 },
        municipality: data.municipalities[0],
        setField
    }));
    const [fieldValues, setFieldValues] = useState(values);
    const [isActiveMap, setIsActiveMap] = useState(false);
    const [opacity, setOpacity] = useState(0.75);
    const [loading, setLoading] = useState(false);
    const mapRef = useRef();
    const containerRef = useRef();
    const [legendColorMap, setLegendColorMap] = useState(legendHeatmapColors);

    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    };

    const onMoveEnd = () => {
        const map = mapRef.current.leafletElement;
        const newCenter = map.getCenter();
        let tmpFieldValues = { ...fieldValues };
        tmpFieldValues.value.position = [newCenter.lat, newCenter.lng];
        tmpFieldValues.value.zoom = map.getZoom();
        captureMapImage(containerRef, setFieldValues, fieldValues, values, name, false);
    };

    useEffect(() => {
        if (values.municipality.geoBoundingBox && mapRef.current) {
            const map = mapRef.current.leafletElement;
            map.fitBounds(values.municipality.geoBoundingBox);
        }
    }, []);

    useEffect(() => {
        const mapInstance = mapRef.current.leafletElement;
        if (mapInstance && !isActiveMap) {
            mapInstance.dragging.disable();
            mapInstance.touchZoom.disable();
            mapInstance.doubleClickZoom.disable();
            mapInstance.scrollWheelZoom.disable();
            mapInstance.boxZoom.disable();
            mapInstance.keyboard.disable();
        }
    }, [isActiveMap]);

    const enableMapInteractions = () => {
        const mapInstance = mapRef.current.leafletElement;
        if (!isActiveMap && mapInstance) {
            setIsActiveMap(true);
            mapInstance.dragging.enable();
            mapInstance.touchZoom.enable();
            mapInstance.doubleClickZoom.enable();
            mapInstance.scrollWheelZoom.enable();
            mapInstance.boxZoom.enable();
            mapInstance.keyboard.enable();
        }
    };

    const disableMapInteractions = (event) => {
        const mapElement = document.getElementById(mapUniqueId);
        if (!mapElement.contains(event.target)) {
            const mapInstance = mapRef.current.leafletElement;
            if (isActiveMap && mapInstance) {
                setIsActiveMap(false);
                mapInstance.dragging.disable();
                mapInstance.touchZoom.disable();
                mapInstance.doubleClickZoom.disable();
                mapInstance.scrollWheelZoom.disable();
                mapInstance.boxZoom.disable();
                mapInstance.keyboard.disable();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', disableMapInteractions);
        return () => {
            document.removeEventListener('click', disableMapInteractions);
        };
    }, [isActiveMap]);

    const handleOpacityChange = (event) => {
        setOpacity(event.target.value);
    };

    const handleCaptureMapImage = async () => {
        try {
            const mapContainer = document.getElementById(mapUniqueId);
            if (!mapContainer) {
                console.error("Map container not found");
                return;
            }
    
            mapContainer.classList.add('full-screen');
    
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const mapInstance = mapRef.current?.leafletElement;
            
            if (!mapInstance) {
                console.error("Map instance not found");
                mapContainer.classList.remove('full-screen');
                return;
            }
    
            mapInstance.invalidateSize();
    
            await new Promise((resolve) => setTimeout(resolve, 4000));
            captureMapImage(containerRef, setFieldValues, fieldValues, values, name, true);
    
            await new Promise((resolve) => setTimeout(resolve, 2000));
            mapContainer.classList.remove('full-screen');
        } catch (error) {
            console.error("Error during map capture:", error);
        }
    };

    return (
        <div className='open-street-map'>
            <div ref={containerRef} className={isActiveMap ? 'open-street-map is-active-map' : 'open-street-map'}>
                <div className='map-title-buttons-container'>
                    <h3>Mapa bioplynové stanice</h3>
                    <div>
                        <Button
                            className='addButton'
                            style={{ marginRight: 20 }}
                            onClick={async () => {
                                setLoading(true);
                                try {
                                    const tmpBox = getMapBounds(mapRef);
                                    if (tmpBox) {
                                        await fetchMapPoints(prepareLayerBiogasPlants, { polygon: [[tmpBox[1], tmpBox[0]], [tmpBox[3], tmpBox[2]]] }, setFieldValues, fieldValues, containerRef, values, name);
                                    }
                                } catch (error) {
                                    console.error('Error fetching map layer:', error);
                                } finally {
                                    setLoading(false);
                                }
                            }}>
                            {loading ? 'Načítavam údaje...' : 'Stiahnúť údaje'}
                        </Button>
                        <Button className='addButton' onClick={handleCaptureMapImage}>Vložiť do náhľadu</Button>
                        {loading && <Spin className='spin-loading-map' />}
                    </div>
                </div>
                <Map
                    onblur={handleChange}
                    center={values?.municipality?.geoCenter}
                    zoom={fieldValues.value.zoom}
                    ref={mapRef}
                    onMoveEnd={onMoveEnd}
                    onZoomEnd={onMoveEnd}
                    id={mapUniqueId}
                    onClick={enableMapInteractions}
                >
                    <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Polygon key={"524862865862466984"} positions={values?.municipality?.geoJson} opacity={opacity} />
                    {fieldValues?.value?.bioGasPlants && legendColorMap && (
                        fieldValues?.value?.bioGasPlants.map(point => {
                            return (
                                <Marker key={point.id} position={{ lng: point.coordinates[0], lat: point.coordinates[1] }} icon={customIcon}>
                                    <Popup>
                                        <div>
                                            <ul>
                                                <li>{point.name}</li>
                                                <li>{point.address}</li>
                                            </ul>
                                        </div>
                                        <ul>
                                            <li>Instalovaný elektrický výkon: {point.electricPower} kW</li>
                                            <li>Instalovaný tepelný výkon: {point.thermalPower} kW</li>
                                            <li>Udělení licence: {point.year}</li>
                                        </ul>
                                    </Popup>
                                </Marker>
                            )
                        })
                    )}
                </Map>
                <div className='bioplyn-legend'><img width={20} src={point} alt='point icon' /> <h5>Bioplynová stanice</h5></div>
                <LegendCustom
                    legendData={legendData}
                    opacity={opacity}
                />
                <div className='opacity-slider-map'>
                    <span className='opacity-slider-controller'>
                        <label>Viditeľnosť vrstiev</label>
                        <input
                            type="range"
                            id="opacity"
                            name="opacity"
                            min="0"
                            max="1"
                            step="0.02"
                            value={opacity}
                            onChange={handleOpacityChange}
                        />
                    </span>
                    <span><strong>Zdroj:</strong> AOPK-vlastní zpracování</span>
                </div>
            </div>
        </div>
    );
};

function MapaBioplynoveStaniceRenderer(values, key) {
    let returnValue = ""
    if (values[key]) {
        returnValue += `<img src="${values[key].image}" alt="Map view" style="height: 100%; width: 100%; object-fit: cover;" />`;
    }
    return returnValue
}

function MapaBioplynoveStaniceValidator() {
    return true;
}

export default {
    Mutator: MapaBioplynoveStaniceMutator,
    HTMLRenderer: MapaBioplynoveStaniceRenderer,
    Validator: MapaBioplynoveStaniceValidator
};